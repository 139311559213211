import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { ModalService } from '../../modal.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	email:any;
  remail:any;
	password:any;
	emsg:any;
	userid:any;
	token:any;
  itemdata:any;
  reset:any = 0;
  	constructor(private myModal: ModalService, private apiService: ApiService,private router: Router,private route: ActivatedRoute) { 

  		this.userid = localStorage.getItem('userid');
  		this.token = localStorage.getItem('token');
 		if(this.userid!='' && this.userid!=undefined && this.userid!=null && this.userid!=0) {
 			this.router.navigate(['/cart']);
 		}

  	}

  	ngOnInit(): void {
  	}

  	login() {
  		this.emsg='';
  		if(this.email=='' || this.password=='') {
  			this.emsg="Please fill in login details";
  		} else {
  			this.emsg='';
  			this.itemdata = {
  				"token":this.token,
  				"email":this.email,
  				"password":this.password
  			}
  			this.apiService.post('login', this.itemdata).then((res: any) => {
    		if (res.status === 200) {
          if(res.login=="-1") {
            this.emsg="Invalid Login. Please try again";
          } else {
            localStorage.setItem('userid',res.id);
            localStorage.setItem('token',res.token);
            localStorage.setItem('name',res.username);
            localStorage.setItem('email',res.email);
            this.myModal.myToken(this.token);
            this.myModal.myUserid(res.id);
            this.router.navigate(['/dashboard']);
          }
    			
    		} else {
	          const message = res.message;
	        }
	    });
  		}
  	}

    preset() {
        this.reset=1;
    }

    btlogin() {
        this.reset=0;
    }

    recover() {
        this.emsg='';
        if(this.remail=='') {
            this.emsg="Please enter your Email Id";
        } else {
            this.emsg='';
            this.itemdata = {
              "token":this.token,
              "email":this.remail
            }
            this.apiService.post('recover', this.itemdata).then((res: any) => {
              if (res.status === 200) {
                this.emsg=res.msg;
              } else {
                this.emsg="Something went wrong. Please try again";
              }
            });
        }
    }

}
