<div class="page-wraper footer-fixed"> 
      
      <!-- HEADER START -->
      <app-header2></app-header2>
      <!-- HEADER END -->
      
      <!-- CONTENT START -->
      <div class="page-content">        
          <!-- SLIDER START -->
          <app-slider2></app-slider2>
          <!-- SLIDER END -->
          
          <!-- ABOUT COMPANY -->
          <app-gallery1 [data]="gallery_section"></app-gallery1>  
          <!-- ABOUT COMPANY END -->

                                  
          <!-- WELCOME SECTION START -->
          <app-specialization2 [data]="our_specialization_section"></app-specialization2>   
          <!-- WELCOME  SECTION END -->  
          
          <!-- LATEST PRJECTS SLIDER START -->
          <app-our-work2 [data]="our_work_section"></app-our-work2> 
          <!-- LATEST PRJECTS SLIDER END --> 

          <!-- OUR TEAM START -->
          <app-our-team1 [data]="our_team"></app-our-team1>
          <!-- OUR TEAM END -->

          <!-- OUR VIDEO START -->
          <app-call-to-action1></app-call-to-action1>
          <!-- OUR VIDEO END --> 
          
          <!-- Counter -->
          <app-counter1 [data]="company_staticties_section"></app-counter1>
          <!-- Counter END -->
                      
          <!-- OUR BLOG START -->
          <app-blog1 [data]="blogs"></app-blog1> 
          <!-- OUR BLOG END --> 
          
          <!-- TESTIMONIALS SECTION START -->
          <app-testimonial1 [data]="testimonials"></app-testimonial1>
          <!-- TESTIMONIALS SECTION END --> 
                                               

          <!-- CLIENT LOGO SECTION START -->
          <app-clients [data]="clients"></app-clients>
          <!-- CLIENT LOGO  SECTION End -->
      </div>
      
      <!-- CONTENT END -->
      
      <!-- FOOTER START -->
      <app-footer1></app-footer1>
      <!-- FOOTER END -->

      <!-- BUTTON TOP START -->
  <app-scroll-to-top></app-scroll-to-top>
      
   
  </div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>