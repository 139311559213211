<footer class="site-footer footer-large  footer-light	footer-wide text-black">
  
  <!-- FOOTER BLOCKES START -->  
  <div class="footer-top overlay-wraper">
      <div class="overlay-main"></div>
      
      
     
      
      <div class="container">
          <div class="row">
              <!-- ABOUT COMPANY -->
              <div class="col-lg-5 col-md-5 col-sm-4">  
                  <div class="widget widget_about">
                      <!--<h4 class="widget-title">About Company</h4>-->
                      <div class="logo-footer clearfix p-b15">
                          <a routerLink="/index"><img src="assets/images/logo.png" alt=""></a>
                      </div>
                      <p>The Asthika Samaj was set up as a place of public worship in Matunga, Mumbai, India, in the year 1923</p>
                    <ul class="social-icons f-social-link">
                          <li><a href="https://www.facebook.com/theasthikasamajnama20/" target="_blank" class="fa fa-facebook"></a></li>
                          <li><a href="https://www.youtube.com/channel/UCO6PEIwj-YyHMbcGHiZxQRw" target="_blank" class="fa fa-youtube"></a></li>
                      </ul>                                    
                  </div>
           
              </div> 

              <!-- TAGS -->
              <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="widget widget_address_outer m-b20">
                      <h4 class="widget-title">Contact Us</h4>
                      <ul class="widget_address">
                          <li>Bhandarkar Road, Matunga (CR), Mumbai - 400 019, Maharashtra, India</li>
                          <li>theasthikasamajam@gmail.com</li>
                          <li>+91 95526 95552</li>
                          <li>+91 95526 95553</li>
                      </ul>
                 
                  </div>
              </div>
                                      
              <!-- USEFUL LINKS -->
              <div class="col-lg-3 col-md-3 col-sm-4">
                  <div class="widget widget_services inline-links">
                      <h4 class="widget-title">Useful links</h4>
                      <ul>
                          <li><a href="http://www.asthikasamajmatunga.com/" target="_blank">Temple</a></li>
                          <li><a href="http://www.asthikasamajmatunga.com/contact.php" target="_blank">Contact</a></li>
                          <li><a routerLink="/cart">Cart</a></li>
                          <li><a routerLink="/dashboard">Dashboard</a></li>
                          <li><a href="http://www.asthikasamajmatunga.com/endowment.php" target="_blank">Endowment Schemes</a></li>
                      </ul>
                  </div>                           
              </div>      

              <!-- NEWSLETTER -->

          </div>
      </div>
  </div>
  <!-- FOOTER COPYRIGHT -->
  <div class="footer-bottom overlay-wraper">
      <div class="overlay-main"></div>
      <div class="container">
          <div class="row">
              <div class="wt-footer-bot-center">
                  <span class="copyrights-text">© 2022 The Asthika Samaj. Developed By <a href="https://perutions.com/" target="_blank">Perutions Infini</a></span>
              </div>
          </div>
      </div>
  </div>
</footer>