import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { ModalService } from '../../modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import {NgxPrintModule} from 'ngx-print';

@Component({
  selector: 'app-vieworder',
  templateUrl: './vieworder.component.html',
  styleUrls: ['./vieworder.component.css']
})
export class VieworderComponent implements OnInit {

	orderid:any;
	orderdet:any;
	ordeitems:any;
	itemData:any;
	userid:any;
  	constructor(private router: Router,private route: ActivatedRoute, private myModal: ModalService, private apiService: ApiService) { 
  		window.scrollTo(0, 0);
  		this.userid = localStorage.getItem('userid');
  		if(this.userid=='' || this.userid==undefined || this.userid==null || this.userid==0 || this.userid=='0') {
	 			this.router.navigate(['/login']);
	 		}
  		this.orderid = localStorage.getItem('viewid');
  		this.getOrder();
  	}

  	ngOnInit(): void {
  	}

  	getOrder() {
  		this.itemData = {
  			"orderid":this.orderid
  		}
  		this.apiService.post('vieworder', this.itemData).then((res: any) => {
	      if (res.status === 200) {
	        this.orderdet = res.order;
	        this.ordeitems = res.items;
	      } else {
	        const message = res.message;
	      }
	    });
  	}

}
