<div class="comments-area" id="comments">
  <h2 class="comments-title">4 Comments</h2>
  <div class="p-tb30">
      <!-- COMMENT LIST START -->
      <ol class="comment-list">
          <li class="comment">
            <!-- COMMENT BLOCK -->
              <div class="comment-body bdr-1 bdr-solid bdr-gray">
                  <div class="comment-meta">
                      <a href="javascript:void(0);">March 6, 2018 at 7:15 am</a>
                  </div>                                        
                  <div class="comment-author vcard">
                      <img  class="avatar photo" src="assets/images/testimonials/pic1.jpg" alt="">
                      <cite class="fn">Diego</cite>
                      <span class="says">says:</span>
                  </div>

                  <p>Sit amet nibh vulputate cursus a sit amet mauris lorem ipsum dolor sit amet of Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio http://themeforest.net Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris vitae erat </p>
                  <div class="reply">
                      <a routerLink="/javscript:;" class="comment-reply-link  text-uppercase">Read More</a>
                  </div>
              </div>
              <!-- SUB COMMENT BLOCK -->
              <ol class="children">
                  <li class="comment odd parent">
                  
                      <div class="comment-body bdr-1 bdr-solid bdr-gray">
                          <div class="comment-meta">
                              <a href="javascript:void(0);">March 8, 2018 at 9:15 am</a>
                          </div>                                                
                          <div class="comment-author vcard">
                              <img  class="avatar photo" src="assets/images/testimonials/pic3.jpg" alt="">
                              <cite class="fn">Brayden</cite>
                              <span class="says">says:</span>
                          </div>

                          <p>Asperiores, tenetur, blanditiis, quaerat odit ex exercitationem pariatur quibusdam veritatis quisquam laboriosam esse beatae hic perferendis velit deserunt soluta iste repellendus officia in neque veniam debitis</p>
                          <div class="reply">
                              <a routerLink="/javscript:;" class="comment-reply-link  text-uppercase">Read More</a>
                          </div>
                      
                      </div>
                      
                      <ol class="children">
                          <li class="comment odd parent">
                              <div class="comment-body bdr-1 bdr-solid bdr-gray">
                                  <div class="comment-meta">
                                      <a href="javascript:void(0);">March 9, 2018 at 11:15 am</a>
                                  </div>                                                
                                  <div class="comment-author vcard">
                                      <img  class="avatar photo" src="assets/images/testimonials/pic2.jpg" alt="">
                                      <cite class="fn">Diego</cite>
                                      <span class="says">says:</span>
                                  </div>

                                  <p>Vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor Lorem ipsum dolor sit amet of Lorem Ipsum. Proin gravida nibh..</p>
                                  <div class="reply">
                                      <a routerLink="/javscript:;" class="comment-reply-link  text-uppercase">Read More</a>
                                  </div>
                              
                              </div>
                          </li>
                      </ol>
                      
                  </li>
              </ol>
          </li>
          <li class="comment">
            <!-- COMMENT BLOCK -->
              <div class="comment-body bdr-1 bdr-solid bdr-gray">
                  <div class="comment-meta">
                      <a href="javascript:void(0);">March 12, 2018 at 7:15 am</a>
                  </div>                                        
                  <div class="comment-author vcard">
                      <img  class="avatar photo" src="assets/images/testimonials/pic1.jpg" alt="">
                      <cite class="fn">Stacy poe</cite>
                      <span class="says">says:</span>
                  </div>

                  <p>Asperiores, tenetur, blanditiis, quaerat odit ex exercitationem pariatur quibusdam veritatis quisquam laboriosam esse beatae hic perferendis velit deserunt soluta iste repellendus officia in neque veniam debitis</p>
                  <div class="reply">
                      <a routerLink="/javscript:;" class="comment-reply-link  text-uppercase">Read More</a>
                  </div>
            </div>
          </li>
      </ol>
      <!-- COMMENT LIST END -->
      
      <!-- LEAVE A REPLY START -->
      <app-comment-form></app-comment-form>
      <!-- LEAVE A REPLY END -->
 </div>
</div>