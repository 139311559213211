<div class="section-full small-device p-t30  p-b80">



  <div class="container">
  
      <!-- IMAGE CAROUSEL START -->
      <div class="section-content clearfix">

        <div class="col-md-12">
            <div class="section-head text-center">
                <h2 class="text-uppercase  font-weight-900 m-b5 text-center">
                  Book by Deity
                </h2>
                <div class="wt-separator-outer text-center">
                    <div class="wt-separator bg-primary"></div>
                </div>
            </div>
            <div class="row  no-col-gap">
                <div class="col-md-3 col-sm-6 col-xs-6 col-xs-100pc bg-white"  *ngFor="let item of dietys">
                    <div class="wt-icon-box-wraper center p-lr30  p-b50 p-t50 bdr-1 bdr-solid bdr-gray-light hover-border-outer hover-border">
                        <div class="icon-lg text-primary m-b15">
                            <img src="assets/images/diety/{{item.photo}}">
                        </div>
                        <div class="icon-content text-black">
                            <h4 class="wt-tilte text-uppercase font-weight-600 m-b20">{{item.name}}</h4>
                            <a routerLink="/pooja/deity/{{item.url}}" class="site-button-secondry site-btn-effect"><span>Start Booking</span></a>
                        </div>
                    </div>
                </div>                                                                      
            </div>
        </div>
        
        <div class="col-md-12">
            <div class="section-head text-center">
                <h2 class="text-uppercase  font-weight-900 p-t50 m-b5 text-center">
                  Book by Pooja Category
                </h2>
                <div class="wt-separator-outer text-center">
                    <div class="wt-separator bg-primary"></div>
                </div>
            </div>
            <div class="row  no-col-gap">
            <div class="col-md-3 col-sm-6 col-xs-6 col-xs-100pc bg-white"  *ngFor="let pooja of poojaCategory">
                <div class="wt-icon-box-wraper center p-lr30  p-b50 p-t50 bdr-1 bdr-solid bdr-gray-light hover-border-outer hover-border">
                    <div class="icon-lg text-primary m-b15">
                        <span class="icon-cell text-primary"><i class="fab fa-free-code-camp"></i></span>
                    </div>
                    <div class="icon-content text-black">
                        <h4 class="wt-tilte text-uppercase font-weight-600 m-b20">{{pooja.typename}}</h4>
                        <a routerLink="/pooja/book/{{pooja.url}}" class="site-button-secondry site-btn-effect"><span>Start Booking</span></a>
                    </div>
                </div>
            </div>                          
        </div>
            
        
      </div>
  </div>
  
</div>