import { Component, OnInit, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from '../../api.service';
import { ModalService } from '../../modal.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {
  
  @Input() data:any;
  hideme:any;
  gothras:any;
  stars:any;
  poojaid:any;
  poojarate:any;
  devoteename:any;
  gothram:any;
  star:any;
  qty:any;
  bookdate:any;
  token:any;
  userid:any;
  viewcart:Array<number>;
  cart:any;
  cartitem:any = [];
  itemdata:any;
  emsg:any;
  date:any;
  devotees:any = '';
  itemData:any;
  mybefore:any;
    
  constructor(private myModal: ModalService, private apiService: ApiService) { 
    
    this.devoteename = localStorage.getItem('name');
    if(this.devoteename=='' || this.devoteename==undefined) {
      this.devoteename='';
    }
    this.devoteename='';

    this.emsg='';
    this.qty=1;

    this.myModal.myModal$.subscribe((data) => {
        this.hideme = data;
    });

    this.myModal.myPooja$.subscribe((data) => {
        this.poojaid = data;
    });

    this.myModal.rate$.subscribe((data) => {
        this.poojarate = data;
    });

    this.myModal.myToken$.subscribe((data) => {
        this.token = data;
    });

    this.myModal.myBefore$.subscribe((data) => {
        this.mybefore = data;        

        //this.date = this.addDays(this.mybefore);
        this.date = new Date().getTime()+(this.mybefore*24*60*60*1000);
    });

    if(this.token=='' || this.token==undefined) {
      this.token = localStorage.getItem('token');
      if(this.token=='' || this.token==undefined) {
        //this.getToken();
      }
    } 

    

    if(this.hideme=='' || this.hideme==undefined) {
      this.hideme=1;
    }

    this.userid = localStorage.getItem('userid');
    if(this.userid=='' || this.userid==undefined) {
      this.userid='0';
    }

    this.getGothram();
    this.getStars();
  }

  addName(name,stars,gothrams) {
    this.devoteename=name;
    this.gothram=gothrams;
    this.star=stars;
    this.devotees='';
  }

  /*addDays(days : number): Date{
    var futureDate = new Date().getTime()+(days*24*60*60*1000);
    //futureDate.setDate(futureDate.getDate());
    //alert(futureDate);
    return futureDate;
  }*/

  ngOnInit(): void {
    this.date = new Date().toISOString().slice(0, 10);
  }

  addOptions() {
    this.hideme=0;
  }

  closeOptions() {
    this.hideme=1;
  }

  getDevotees() {
    this.itemData = {
      "id":this.userid,
      "key":this.devoteename
    }
    this.apiService.post('m_dl_s', this.itemData).then((res: any) => {
      if (res.status === 200) {
        this.devotees = res.data;
      } else {
        const message = res.message;
      }
    });
  }

  getGothram() {
      this.apiService.post('listgothram', {}).then((res: any) => {
        if (res.status === 200) {
          this.gothras = res.data;
        } else {
          const message = res.message;
        }
      });
  }

  getStars() {
      this.apiService.post('liststars', {}).then((res: any) => {
        if (res.status === 200) {
          this.stars = res.data;
        } else {
          const message = res.message;
        }
      });
  }

  addcart() {
    this.emsg='';
    this.itemdata= {
      "poojaid":this.poojaid,
      "userid":this.userid,
      "token":this.token,
      "devoteename":this.devoteename,
      "gothram":this.gothram,
      "star":this.star,
      "qty":this.qty,
      "bookdate":this.bookdate,
      "amount":this.poojarate
    }
    if(this.devoteename=='' || this.devoteename==undefined || this.gothram=='' || this.gothram==undefined || this.star=='' || this.star==undefined || this.qty=='' || this.qty==undefined || this.bookdate=='' || this.bookdate==undefined) {
        this.emsg="Please fill out all the details";
    } else {
        if(isNaN(this.qty)) {
          this.qty='1';
          this.emsg="Please enter valid quantity";
        } else {
            if(this.qty<1) {
                this.qty=0;
                this.emsg="Quantity Invalid. Must be grater than Zero";
            } else {
                this.emsg='';
                this.apiService.post('addtocart', this.itemdata).then((res: any) => {
                  console.log(res.status);
                  if (res.status === 200) {
                      localStorage.setItem('cartid', res.data);
                      localStorage.setItem('cartitems', res.items);
                      this.poojaid='';
                      this.gothram='';
                      this.star='';
                      this.qty='1';
                      this.bookdate='';
                      this.hideme=1;
                      this.myModal.cartItems(res.items);
                      this.myModal.myModal(this.hideme);
                  } else {
                      this.emsg=res.msg;
                  }
                });
            }
        }        
    }
  }

}
