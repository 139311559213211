import { Component, OnInit, Input } from '@angular/core';
declare  var jQuery:  any;
declare  var equalheight:  any;

@Component({
  selector: 'app-our-team2',
  templateUrl: './our-team2.component.html',
  styleUrls: ['./our-team2.component.css']
})
export class OurTeam2Component implements OnInit {

  @Input() data:any;
  constructor() { }

  ngOnInit(): void {
	  (function ($) {
      setTimeout(function(){
          var currentTallest = 0, 
          currentRowStart = 0, 
          rowDivs = new Array(), 
          $el, topPosition = 0,
          currentDiv = 0;

        jQuery(".equal-wraper .equal-col").each(function() {
          $el = jQuery(this);
          jQuery($el).height('auto');
          var topPostion = $el.position().top;
          if (currentRowStart != topPostion) {
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
              rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0; // empty the array
            currentRowStart = topPostion;
            currentTallest = $el.height();
            rowDivs.push($el);
          } else {

            rowDivs.push($el);
            currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
          }

          for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
          }
        });
        }, 500);
    })(jQuery);
  
  }

}
