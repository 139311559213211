<div class="section-full small-device p-t80 p-b50 bg-white">
  <ng-template ngFor let-sectionData [ngForOf]="data">
    <div class="container">
   
        <div class="section-content">
            <div class="row">
                    
                <div class="col-md-3 col-sm-6"  *ngFor = "let item of sectionData.items">
                    <div class="m-b30 text-black wt-icon-box-wraper center">
                        <div class="counter font-40 font-weight-600 m-b5  text-primary" [CountTo]="item.count" [from]="10" [duration]="item.duration">{{item.count}}</div>
                        <h4>{{item.title}}</h4>
                    </div>
                </div>
                
                <!-- <div class="col-md-3 col-sm-6">
                    <div class="m-b30 text-black wt-icon-box-wraper center">
                        <div class="counter font-40 font-weight-600 m-b5 text-primary">300</div>
                        <h4>HAPPY CLIENTS</h4>
                    </div>
                </div>
                
                <div class="col-md-3 col-sm-6">
                    <div class="m-b30 text-black wt-icon-box-wraper center">
                        <div class="counter font-40 font-weight-600 m-b5  text-primary">50</div>
                        <h4>YEARS OF EXPERIENCE</h4>
                    </div>
                </div>
                
                <div class="col-md-3 col-sm-6">
                    <div class="m-b30 text-black wt-icon-box-wraper center">
                        <div class="counter font-40 font-weight-600 m-b5  text-primary">750</div>
                        <h4>WORKERS EMPLOYED</h4>
                    </div>
                </div>   -->                          
                
            </div>
        </div>
    </div>
  </ng-template>  
</div>