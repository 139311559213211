<div class="section-full small-device  p-tb80 bg-gray">
  <ng-template ngFor let-sectionData [ngForOf]="data">
    <div class="container">
            
                <!-- TITLE START -->
                <div class="section-head text-left">
                  <h4 class="text-primary text-uppercase wt-title-small">{{sectionData.section_title}}</h4>
                    <h2 class="text-uppercase font-45 font-weight-500 m-b5">{{sectionData.section_heading}}</h2>
                </div>
                <!-- TITLE END -->  
                               
    </div>
    <div class="container">   
        <!-- IMAGE CAROUSEL START -->
        <div class="section-content">
            <!-- <div class="owl-carousel owl-carousel-filter  owl-btn-vertical-center"> -->
                <owl-carousel-o [options]="customOptions" class="owl-carousel-1 owl-carousel-filter-1  owl-btn-vertical-center">
                  <ng-template carouselSlide *ngFor = "let item of sectionData.items">
                    <!-- COLUMNS 1 --> 
                    <div class="item fadingcol building-col">
                        <div class="line-filter-outer">

                             <img src="assets/images/gallery/portrait-2/{{item.image}}" alt="">
                            
                            <div class="hover-effect-1">
                                <div class="hover-effect-content text-white">
                                  <h4 class="m-tb0 h-category">{{item.heading}}</h4>
                                    <p>{{item.description}}</p>
                                </div>
                            </div>                                     
                           
                            <div class="line-filter">
                                <div class="filter-content  bg-white p-a30">
                                    <h4 class="m-t0 m-b20 text-uppercase"><a routerLink="/{{item.url}}">{{item.title}}</a></h4>
                                    <a routerLink="/{{item.url}}" class="v-button text-uppercase">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                  </ng-template>
                </owl-carousel-o>
                <!--
                <div class="item fadingcol  building-col">
                    <div class="line-filter-outer ">
                        <img src="assets/images/gallery/portrait-2/pic2.jpg" alt="">
                        <div class="hover-effect-1">
                            <div class="hover-effect-content text-white">
                              <h4 class="m-tb0 h-category">Renovation</h4>
                                <p>Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>                                       
                        <div class="line-filter">
                            <div class="filter-content  bg-white p-a30">
                                <h4 class="m-t0 m-b20 text-uppercase">Modern minimalist house</h4>
                                <a routerLink="/project-detail" class="v-button text-uppercase">Read More</a>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div class="item fadingcol building-col">
                    <div class="line-filter-outer">
                        <img src="assets/images/gallery/portrait-2/pic3.jpg" alt="">
                        <div class="hover-effect-1">
                            <div class="hover-effect-content text-white">
                              <h4 class="m-tb0 h-category">Interior</h4>
                                <p>Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>                                         
                        <div class="line-filter">
                            <div class="filter-content  bg-white p-a30">
                                <h4 class="m-t0 m-b20 text-uppercase">Modern minimalist house</h4>
                                <a routerLink="/project-detail" class="v-button text-uppercase">Read More</a>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div class="item fadingcol building-col">
                    <div class="line-filter-outer">
                        <img src="assets/images/gallery/portrait-2/pic4.jpg" alt="">
                        <div class="hover-effect-1">
                            <div class="hover-effect-content text-white">
                              <h4 class="m-tb0 h-category">Construction</h4>
                                <p>Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>                                      
                        <div class="line-filter">
                            <div class="filter-content  bg-white p-a30">
                                <h4 class="m-t0 m-b20 text-uppercase">Modern minimalist house</h4>
                                <a routerLink="/project-detail" class="v-button text-uppercase">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item fadingcol interior-col">
                    <div class="line-filter-outer">
                        <img src="assets/images/gallery/portrait-2/pic5.jpg" alt="">
                        <div class="hover-effect-1">
                            <div class="hover-effect-content text-white">
                              <h4 class="m-tb0 h-category">Renovation</h4>
                                <p>Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>                                      
                        <div class="line-filter">
                            <div class="filter-content  bg-white p-a30">
                                <h4 class="m-t0 m-b20 text-uppercase">Modern minimalist house</h4>
                                <a routerLink="/project-detail" class="v-button text-uppercase">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item fadingcol restaurant-col">
                    <div class="line-filter-outer">
                        <img src="assets/images/gallery/portrait-2/pic6.jpg" alt="">
                        <div class="hover-effect-1">
                            <div class="hover-effect-content text-white">
                              <h4 class="m-tb0 h-category">Interior</h4>
                                <p>Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>                                      
                        <div class="line-filter">
                            <div class="filter-content  bg-white p-a30">
                                <h4 class="m-t0 m-b20 text-uppercase">Modern minimalist house</h4>
                                <a routerLink="/project-detail" class="v-button text-uppercase">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item fadingcol building-col">
                    <div class="line-filter-outer">

                         <img src="assets/images/gallery/portrait-2/pic1.jpg" alt="">
                        
                        <div class="hover-effect-1">
                            <div class="hover-effect-content text-white">
                              <h4 class="m-tb0 h-category">Construction</h4>
                                <p>Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>                                     
                       
                        <div class="line-filter">
                            <div class="filter-content  bg-white p-a30">
                                <h4 class="m-t0 m-b20 text-uppercase">Modern minimalist house</h4>
                                <a routerLink="/project-detail" class="v-button text-uppercase">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item fadingcol  building-col">
                    <div class="line-filter-outer ">
                        <img src="assets/images/gallery/portrait-2/pic2.jpg" alt="">
                        <div class="hover-effect-1">
                            <div class="hover-effect-content text-white">
                              <h4 class="m-tb0 h-category">Renovation</h4>
                                <p>Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>                                       
                        <div class="line-filter">
                            <div class="filter-content  bg-white p-a30">
                                <h4 class="m-t0 m-b20 text-uppercase">Modern minimalist house</h4>
                                <a routerLink="/project-detail" class="v-button text-uppercase">Read More</a>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div class="item fadingcol building-col">
                    <div class="line-filter-outer">
                        <img src="assets/images/gallery/portrait-2/pic3.jpg" alt="">
                        <div class="hover-effect-1">
                            <div class="hover-effect-content text-white">
                              <h4 class="m-tb0 h-category">Interior</h4>
                                <p>Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>                                         
                        <div class="line-filter">
                            <div class="filter-content  bg-white p-a30">
                                <h4 class="m-t0 m-b20  text-uppercase">Modern minimalist house</h4>
                                <a routerLink="/project-detail" class="v-button text-uppercase">Read More</a>
                            </div>
                        </div>
                       
                    </div>
                </div>   -->                          
                

            <!-- </div> -->
        </div>
    </div>
  </ng-template>
</div>