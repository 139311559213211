<div class="page-wraper"> 
      
      	<app-header2></app-header2>
      
      	<div class="container">
	      <!-- CONTENT START -->
	      <div class="page-content">        

            <div class="section-head text-center">
                <h2 class="text-uppercase  font-weight-900 m-b5 text-center" *ngIf="reset==0">
                	Login
                </h2>
                <h2 class="text-uppercase  font-weight-900 m-b5 text-center" *ngIf="reset==1">
                	Recover Password
                </h2>
                <div class="wt-separator-outer text-center">
                    <div class="wt-separator bg-primary"></div>
                </div>
            </div>

   			<div class="section-content clearfix">
		        <div class="col-md-12">
		            <div class="row  no-col-gap"> 
			            <div style="padding:20px 0;text-align:center;background:#fff;width:90%;margin:0 auto;max-width:600px;">
			            	<div class="ramForm">

			            		<p class="formInfo" *ngIf="reset==0">Fill in these details to login</p>
			            		<p class="formInfo" *ngIf="reset==1">Please enter Email to recover your password</p>

						        <div class="form-group" *ngIf="reset==0">
						            <input name="email" type="email" required="" class="form-control" placeholder="Email Id" name="email" (input)="email=$event.target.value">
						        </div>

						        <div class="form-group" *ngIf="reset==1">
						            <input type="email" required="" class="form-control" placeholder="Email Id" name="remail" (input)="remail=$event.target.value">
						        </div>

						        <div class="form-group" *ngIf="reset==0">
						            <input name="password" type="password" required="" class="form-control" placeholder="Password" name="password" (input)="password=$event.target.value">
						        </div>

						        <p class="error" *ngIf="emsg!=''">{{emsg}}</p>

						        <a *ngIf="reset==0" href="javascript:void(0);" (click)="preset()" class="reset">Forgot Password? <span class="text-primary">Click Here</span></a>
						        <a *ngIf="reset==1" href="javascript:void(0);" (click)="btlogin()" class="reset">Back to Login? <span class="text-primary">Click Here</span></a>

						        <a *ngIf="reset==0" href="javascript:void(0)" (click)="login()" class="site-button-secondry button-lg site-btn-effect" ><span>Login</span></a>
						        <a *ngIf="reset==1" href="javascript:void(0)" (click)="recover()" class="site-button-secondry button-lg site-btn-effect" ><span>Recover Password</span></a>

						        <div class="" style="margin-top: 25px;display: block;">
                                    <a routerLink="/register" >Do not have an account? <span class="text-primary">Register Now</span></a>
                                </div>

						    </div>
			            </div>                     		                                                                
		          	</div>
		        </div>
		    </div>

	      </div>
	   	</div>

      	<app-footer1></app-footer1>

  <app-scroll-to-top></app-scroll-to-top>
      
   
  </div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>