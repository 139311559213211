<div class="page-wraper"> 
      
      	<app-header2></app-header2>
      
      	<div class="container">
	      <!-- CONTENT START -->
	      <div class="page-content">        

            <div class="section-head text-center">
                <h2 class="text-uppercase  font-weight-900 m-b5 text-left">
                	Checkout               	
                </h2>
                <a href="javascript:void(0);" (click)="next()" *ngIf="cprocess==1" class="site-button-secondry button-sm site-btn-effect" style="float:right;"><span>Next</span></a>
                <div class="wt-separator-outer text-left">
                    <div class="wt-separator bg-primary"></div>
                </div>
            </div>

   			<div class="section-content clearfix">
		        <div class="col-md-12">
		        	<div class="row  no-col-gap bg-primary" *ngIf="cprocess==2" >
			            <div class="col-md-12 col-sm-12 col-xs-12 col-xs-100pc bg-white headrow">
			                <div class="wt-icon-box-wraper center p-lr30 p-b0 p-t15 bdr-1 bdr-solid bdr-gray-light hover-border-outer hover-border">
			                    <div class="row poojas ">
			                    	<div class="col-md-3">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10 p-l10 text-left">
				                        	Devotee
				                        </h4>
			                        </div>
			                        <div class="col-md-2">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">Gothram</h4>
			                        </div>
			                        <div class="col-md-2">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">Star</h4>
			                        </div>
			                        <div class="col-md-2">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">Date</h4>
			                        </div>
			                        <div class="col-md-1">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">Qty</h4>
			                        </div>
			                        <div class="col-md-1">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">Amount</h4>
			                        </div>
			                        <div class="col-md-1">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">Total</h4>
			                        </div>
			                    </div>
			                </div>
			            </div>                        		                                                                
		          	</div>
		            <div class="row  no-col-gap bg-primary" *ngIf="cprocess==2">
		            	<ng-container *ngIf="cartlist.length>0" >
				            <div class="col-md-12 col-sm-12 col-xs-12 col-xs-100pc bg-white"  *ngFor="let item of cartlist">
				                <div class="wt-icon-box-wraper center p-lr30  p-b0 p-t10 bdr-1 bdr-solid bdr-gray-light ">
				                    <div class="row poojas">
				                    	<div class="col-md-3">
				                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10 p-l10 text-left">
					                        	{{item.name}}
					                        	<div class="subinfo">{{item.pooja}}</div>
					                        	<div class="subinfo" style="color: #fd8a14;">{{item.deity}}</div>
					                        </h4>
				                        </div>
				                        <div class="col-md-2">
				                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">{{item.gothram}}</h4>
				                        </div>
				                        <div class="col-md-2">
				                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">{{item.star}}</h4>
				                        </div>
				                        <div class="col-md-2">
				                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">{{item.bookdate}}</h4>
				                        </div>
				                        <div class="col-md-1">
				                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">{{item.qty}}</h4>
				                        </div>
				                        <div class="col-md-1">
				                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">₹ {{item.amount}}</h4>
				                        </div>
				                        <div class="col-md-1">
				                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">₹ {{item.total}}</h4>
				                        </div>
				                    </div>
				                </div>
				            </div> 
			            </ng-container>  
			            <div *ngIf="cartlist.length==0" style="padding:50px 0;text-align:center;background:#fff;">
			            	<h4 class="text-center">-- No poojas added in cart yet --</h4>
			            </div>                     		                                                                
		          	</div>
		          	<div class="row  no-col-gap bg-primary" *ngIf="cprocess==2" >
			            <div class="col-md-12 col-sm-12 col-xs-12 col-xs-100pc bg-white">
			                <div class="wt-icon-box-wraper center p-lr30 p-b0 p-t15 bdr-1 bdr-solid bdr-gray-light">
			                    <div class="row poojas ">
			                    	<div class="col-md-3">

			                        </div>
			                        <div class="col-md-2">
			                        	
			                        </div>
			                        <div class="col-md-2">
			                        	
			                        </div>
			                        <div class="col-md-2">
			                        	
			                        </div>
			                        <div class="col-md-1">
			                        	
			                        </div>
			                        <div class="col-md-1" style="font-weight: 700;color: #04858c;">
			                        	Total:
			                        </div>
			                        <div class="col-md-1">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">₹ {{totalamount}}</h4>
			                        </div>
			                    </div>
			                </div>

			                <div class="wt-icon-box-wraper center p-lr30 p-b0 p-t15 bdr-1 bdr-solid bdr-gray-light">
			                    <div class="row poojas ">
			                    	<div class="col-md-3">

			                        </div>
			                        <div class="col-md-2">
			                        	
			                        </div>
			                        <div class="col-md-2">
			                        	
			                        </div>
			                        <div class="col-md-2">
			                        	<
			                        </div>
			                        <div class="col-md-1">
			                        	
			                        </div>
			                        <div class="col-md-1" style="font-weight: 700;color: #04858c;">
			                        	Dakshina:
			                        </div>
			                        <div class="col-md-1">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">₹ {{dakshina}}</h4>
			                        </div>
			                    </div>
			                </div>

			                <div class="wt-icon-box-wraper center p-lr30 p-b0 p-t15 bdr-1 bdr-solid bdr-gray-light ">
			                    <div class="row poojas ">
			                    	<div class="col-md-3">

			                        </div>
			                        <div class="col-md-2">
			                        	
			                        </div>
			                        <div class="col-md-2">
			                        	
			                        </div>
			                        <div class="col-md-2">
			                        	
			                        </div>
			                        <div class="col-md-1">
			                        	
			                        </div>
			                        <div class="col-md-1" style="font-weight: 700;color: #04858c;">
			                        	Donation:
			                        </div>
			                        <div class="col-md-1">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">₹ {{donation}}</h4>
			                        </div>
			                    </div>
			                </div>

			                <div class="wt-icon-box-wraper center p-lr30 p-b0 p-t15 bdr-1 bdr-solid bdr-gray-light">
			                    <div class="row poojas ">
			                    	<div class="col-md-3">

			                        </div>
			                        <div class="col-md-2">
			                        	
			                        </div>
			                        <div class="col-md-2">
			                        	
			                        </div>
			                        <div class="col-md-2">
			                        	
			                        </div>
			                        <div class="col-md-1">
			                        	
			                        </div>
			                        <div class="col-md-1" style="font-weight: 700;color: #04858c;">
			                        	Grand Total:
			                        </div>
			                        <div class="col-md-1">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">₹ {{grandtotal}}</h4>
			                        </div>
			                    </div>
			                </div>
			            </div>                        		                                                                
		          	</div>
		          	<div class="row  no-col-gap" *ngIf="cprocess==1" >
			            <div class="col-md-12 col-sm-12 col-xs-12 col-xs-100pc bg-white">
			                <div class="ramForm">
			                	<h3>Billing Address <p class="error" style="display: inline;margin-left: 20px;font-size: 13px;">{{emsg}}</p></h3>
			                	<div class="col-md-6 p-t5 p-r5 p-b5 p-l5">
							        <div class="form-group">
							        	<label>Your Name</label>
							            <input name="name" type="text" required="" class="form-control" placeholder="Type here.." (input)="name=$event.target.value"  value="{{name}}">
							        </div>
							    </div>

							    <div class="col-md-6 p-t5 p-r5 p-b5 p-l5">
							        <div class="form-group">
							        	<label>Email Id</label>
							            <input name="email" type="email" required="" class="form-control" placeholder="Type here.." name="email" (input)="email=$event.target.value" value="{{email}}">
							        </div>
							    </div>


							    <div class="col-md-6 p-t5 p-r5 p-b5 p-l5">
							        <div class="form-group">
							        	<label>Phone Number</label>
							            <input name="phone" type="text" required="" class="form-control" placeholder="Type here.."  (input)="phone=$event.target.value" value="{{phone}}">
							        </div>
							    </div>

							    <div class="col-md-8 p-t5 p-r5 p-b5 p-l5">
							        <div class="form-group">
							        	<label>Your Address</label>
							            <textarea name="address" class="form-control" placeholder="Type here.."  (input)="address=$event.target.value">{{address}}</textarea>
							        </div>
							    </div>

							    <div class="col-md-8 p-t5 p-r5 p-b5 p-l5">
							        <div class="form-group">
							        	<label>Order Remarks if any</label>
							            <textarea name="oremark" class="form-control" placeholder="Type here.."  (input)="oremark=$event.target.value"></textarea>
							        </div>
							    </div>

							    <div class="col-md-12">
							    	<h3>Donation</h3>
							    </div>

							    <div class="col-md-6 p-t5 p-r5 p-b5 p-l5" style="display:none;">
							        <div class="form-group">
							        	<label>Dakshina Amount (If you want to give Dakshina)</label>
							            <input name="dakshina" type="text" class="form-control" placeholder="Enter Dakshina Amount"  (input)="dakshina=$event.target.value" (blur)="checkDakshina($event.target.value)" value="{{dakshina}}">
							        </div>
							    </div>

							    <div class="col-md-8 p-t5 p-r5 p-b5 p-l5" style="display:none;">
							        <div class="form-group">
							        	<label>Dakshina Remarks if any</label>
							            <textarea name="dremark" class="form-control" placeholder="Type here.."  (input)="dremark=$event.target.value"></textarea>
							        </div>
							    </div>


							    <div class="col-md-6 p-t5 p-r5 p-b5 p-l5">
							        <div class="form-group">
							        	<label>Donation Amount (If you want to donate to temple)</label>
							            <input name="donation" type="text" required="" class="form-control" placeholder="Enter Donation Amount"  (input)="donation=$event.target.value" (blur)="checkDonation($event.target.value)" value="{{donation}}">
							        </div>
							    </div>

							    <div class="col-md-8 p-t5 p-r5 p-b5 p-l5">
							        <div class="form-group">
							        	<label>Donation Remarks if any</label>
							            <textarea name="doremark" class="form-control" placeholder="Type here.."  (input)="doremark=$event.target.value"></textarea>
							        </div>
							    </div>

							    <div class="col-md-12">
								    <p class="error">{{emsg}}</p>
								</div>

						    </div>
			            </div>                        		                                                                
		          	</div>
		          	<div class="row" *ngIf="plmsg">
		          		<span class="plerror">{{plmsg}}</span>
		          	</div>
		          	<div class="row">
		          		<div class="col-md-12">
		          			<p class="info">You will be redirected to the Payment Gateway where you can pay ₹ {{grandtotal}} to confirm your Pooja booking.</p>
		          		</div>
		          		<div class="col-md-12 text-right m-t40">
		          			<a href="javascript:void(0);" (click)="next()" *ngIf="cprocess==1" class="site-button-secondry button-sm site-btn-effect" ><span>Next</span></a>

		          			<a href="javascript:void(0);" (click)="previous()" *ngIf="cprocess==2" class="site-button button-lg site-btn-effect" ><span>Previous</span></a>

		          			<a href="javascript:void(0);" (click)="placeorder()" *ngIf="cprocess==2" class="site-button-secondry button-lg site-btn-effect" ><span>Place Order</span></a>
		          			
		          		</div>
		          	</div>
		        </div>
		    </div>

	      </div>
	   	</div>

      	<app-footer1></app-footer1>

  <app-scroll-to-top></app-scroll-to-top>
      
   
  </div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>