import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { ModalService } from '../../modal.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-grievance',
  templateUrl: './grievance.component.html',
  styleUrls: ['./grievance.component.css']
})
export class GrievanceComponent implements OnInit {

  itemData:any;
  userid:any;
  orderlist:any = '';
  name:any;
  email:any;
  list:any = 0;
  gview:any;
  gremarks:any;
  message:any;
  title:any;
  description:any;
  itemdata:any;
  emsg:any;
  rdescription:any;
  gstatus:any;
  cgid:any;

    constructor(private router: Router,private route: ActivatedRoute, private myModal: ModalService, private apiService: ApiService) { 
      window.scrollTo(0, 0);
      this.userid = localStorage.getItem('userid');
      this.name = localStorage.getItem('name');
      this.email = localStorage.getItem('email');
    if(this.userid=='' || this.userid==undefined || this.userid==null || this.userid==0 || this.userid=='0') {
      this.router.navigate(['/login']);
    }

    this.getOrders();
    }

    ngOnInit(): void {
    }

    getOrders() {
      this.itemData = {
        "id":this.userid
      }
      this.apiService.post('m_gl', this.itemData).then((res: any) => {
        if (res.status === 200) {
          this.orderlist = res.data;
        } else {
          const message = res.message;
        }
      });
    }

    orderdetail(id) {
      localStorage.setItem('viewid',id);
      this.router.navigate(['/order-details']);
    }

    add() {
      this.list=1;
    }

    cancel() {
      this.list=0;
    }

    change() {
      this.emsg='';
      if(this.title=='' || this.description=='') {
        this.message="Please fill in Grievance Title and details";
      } else {
          this.emsg='';
          this.itemdata = {
            "id":this.userid,
            "title":this.title,
            "description":this.description,
          }
          this.apiService.post('m_ga', this.itemdata).then((res: any) => {
              if (res.status === 200) {
                this.orderlist = res.data;
                this.title='';
                this.description='';
                this.list=0;
              } else {
                this.message = res.msg;
              }
          });
      }
    }

    reply() {
      this.emsg='';
      if(this.rdescription=='') {
        this.message="Please fill in your reply";
      } else {
          this.emsg='';
          this.itemdata = {
            "id":this.userid,
            "gid":this.cgid,
            "remark":this.rdescription,
            "status":this.gstatus
          }
          this.apiService.post('m_greply', this.itemdata).then((res: any) => {
              if (res.status === 200) {
                this.viewgrievance(this.cgid)
                this.rdescription='';
                this.emsg=res.msg;
              } else {
                this.emsg = res.msg;
              }
          });
      }
    }

    viewgrievance(id) {
      this.list=2;
      this.cgid=id;
      this.itemData = {
        "id":this.userid,
        "gid":id
      }
      this.apiService.post('m_glv', this.itemData).then((res: any) => {
        if (res.status === 200) {
          this.gview = res.data;
          this.gstatus=res.data[0].status;
          this.gremarks=res.remarks;
        } else {
          const message = res.message;
        }
      });
    }
}
