<div class="section-full small-device  p-tb80 bg-gray">
  <div class="container ">
      <!-- IMAGE CAROUSEL START -->
      <div class="row">
        <div class="col-md-6 m-b30">
              <!-- TITLE START -->
              <div class="text-left">
                  <h2 class="text-uppercase font-45 font-weight-500 m-b5">The Asthika Samaj</h2>
                  <p style="text-align:justify;">was set up as a place of public worship in Matunga, Mumbai, India, in the year 1923 by adorning the sacred hall with a portrait of Lord Sree Ramachandra. Eventually, in 1953 with the blessings of His Holiness Jagadguru Sree Sankaracharya Swamigal of Kanchi Kamakoti Peetam, the Temple attained full-fledged status by installation of idols of Lord Sree Ramachandra, Sitadevi, Lakshmanan and Hanuman in a specially created Garbhagriha.</p> 
                  <p style="text-align:justify;">The historical importance of the place can be traced from that the place, now  known as Matunga, was previously the abode of Mathangarishi who, after performing the Mahayagnas on the banks of Pumpa acquired the coveted status of Maharishi. As a vestige of the ancient past, the banyan tree exists even to this date fulfilling the desires of thousands of devotees. The spiritual growth of the Samaj over the years, coupled with the full support of the devotees enabled the installation of other deities - Lord Karthikeyan (1965) Navagraha Idols (1967) Lord Kochu Guruvayurappan (1974) and Swamy Ayyappan (1978).   The religious festivals are held throughout the year.  The daily poojas are conducted strictly by Vedic, Tantric and Agama scriptures.</p>
                  <a href="http://www.asthikasamajmatunga.com/" target="_blank" class="site-button site-btn-effect">Read More</a>
              </div>
              <!-- TITLE END -->                        
          </div>
          
          <div class="col-md-6">
            <div class="video-section-outer">
                <div class="video-section">
                      <img src="assets/images/sita.jpg" alt="">
                                                                  
                  </div>
              </div>
          </div>
  
              
       </div>
  </div>
</div>   