import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { ModalService } from '../../modal.service';

@Component({
  selector: 'app-pooja',
  templateUrl: './pooja.component.html',
  styleUrls: ['./pooja.component.css']
})
export class PoojaComponent implements OnInit {

	url: any;
  	poojaList: any = [];
  	cartid:any;
  	cart:any;
  	hideme:any;
  	cartlist: any = [];
  	urltype:any;
  	dietyname:any;
  	search:any;
  	origlist:any;
  	userid:any;
  	token:any;
  	itemData:any;
  	categories:any;
  	poojaCategory:any;
  	deity:any;
  	constructor(private myModal: ModalService, private apiService: ApiService, private router: Router, private route: ActivatedRoute) { 
  		window.scrollTo(0, 0);
  		this.url = this.route.snapshot.params['id'];
  		this.urltype = this.route.snapshot.params['type'];
  		if(this.url=='') {
  			this.categories=0;
  			this.deity='';
  		} else {
  			if(this.urltype=='deity') {
  				this.categories=0;
  				this.deity=this.url;
  			} else {
  				this.categories=this.url;
  				this.deity='';
  			}
  			
  		}
  		if(this.urltype=='book') {
  			this.getCategories();
  		} else {
  			this.getDietyName();
  			this.getCategoriesDeity();
  		}

  		this.userid = localStorage.getItem('userid');
	    if(this.userid=='' || this.userid==undefined) {
	      this.userid='0';
	    }

	    this.token = localStorage.getItem('token');

  		this.cartid = localStorage.getItem('cartid');
	    if(this.cart==undefined) {
	    	this.createCart();
	    }
	    this.getCart();

  		this.myModal.myModal$.subscribe((data) => {
	        this.hideme = data;
	        this.getCart();
	    });

	    this.getAllCategories();

	    console.log(this.cart);
  	}

  	ngOnInit(): void {
  	}

  	createCart() {
  		this.itemData = {
  			customerid:this.userid,
  			token:this.token
  		}
	    this.apiService.post('createcart', this.itemData).then((res: any) => {
	      if (res.status === 200) {
	        this.cartid = res.cart;
	        localStorage.setItem('cartid', res.cart);
	      } else {
	        const message = res.message;
	      }
	    });
	}

	getAllCategories() {
	    this.apiService.post('listpooja_type', {}).then((res: any) => {
	      if (res.status === 200) {
	        this.poojaCategory = res.data;
	        console.log(this.poojaCategory, 'Category-List');
	      } else {
	        const message = res.message;
	      }
	    });
	}

  	getCategories() {
	    this.apiService.post('listpooja', { url: this.url }).then((res: any) => {
	      if (res.status === 200) {
	        this.poojaList = res.data;
	        this.origlist = res.data;
	      } else {
	        const message = res.message;
	      }
	    });
	}

  	getCategoriesDeity() {
	    this.apiService.post('listpoojadeity', { url: this.url }).then((res: any) => {
	      if (res.status === 200) {
	        this.poojaList = res.data;
	        this.origlist = res.data;
	      } else {
	        const message = res.message;
	      }
	    });
	}

  	getDietyName() {
	    this.apiService.post('listdeitysingle', { url: this.url }).then((res: any) => {
	      if (res.status === 200) {
	        this.dietyname = res.data;
	      } else {
	        const message = res.message;
	      }
	    });
	}



	getCart() {
		this.cartid = localStorage.getItem('cartid');
		if(this.cartid=='' || this.cartid==undefined) {
			this.cartlist=[];
		} else {
		    this.apiService.post('getCart', { cart: this.cartid }).then((res: any) => {
		      if (res.status === 200) {
		        this.cartlist = res.data;
		        console.log(this.cartlist);
		      } else {
		        const message = res.message;
		      }
		    });
		}
	}

	goToPoojaDetails(id: any) {
	    this.router.navigate(['/home/pooja-details', id]);
	}

	addOptions(id,rate,maxqty,mindate,maxdate) {
	    this.hideme=0;
	    this.myModal.myModal(this.hideme);
	    this.myModal.myPooja(id);
	    this.myModal.rate(rate);
	    this.myModal.mbefore(+mindate+1);
	}

	closeOptions() {
	    this.hideme=1;
	    this.myModal.myModal(this.hideme);
	}

	searchlist(val) {
		console.log(val);
		//this.poojaList=this.origlist.match(e => e.name === val);
		if(val=='' || val==undefined) {
			this.poojaList=this.origlist;
		} else {
			//this.poojaList=this.origlist.filter(obj=>obj.name===val);
			this.poojaList=this.origlist.filter(obj=>obj.name.toUpperCase().includes(val.toUpperCase( )));
			
		}
		
	}

	searchfn() {
		this.itemData = {
			s:this.search,
			t:this.categories,
			d:this.deity
		}
		this.apiService.post('searchpooja', this.itemData).then((res: any) => {
	      if (res.status === 200) {
	        this.poojaList = res.data;
	      } else {
	        const message = res.message;
	      }
	    });
	}

}
