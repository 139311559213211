<div class="page-wraper"> 
      
      	<app-header2></app-header2>
      
      	<div class="container">
	      <!-- CONTENT START -->
	      <div class="page-content">        

            <div class="section-head text-center">
                <h2 class="text-uppercase  font-weight-900 m-b5 text-left">
                	My Upcoming Poojas
                </h2>
                <a routerLink="/pooja-history" class="site-button-secondry button-sm site-btn-effect" style="float: right;margin-right:10px;"><span>Poojas History</span></a>
                <div class="wt-separator-outer text-left">
                    <div class="wt-separator bg-primary"></div>
                </div>
            </div>

   			<div class="section-content clearfix">
		        <div class="row  no-col-gap bg-primary">
	            	<ng-container *ngIf="orderlist.length>0">
	            		<div class="col-md-12 col-sm-12 col-xs-12 col-xs-100pc bg-white headrow">
			                <div class="wt-icon-box-wraper center p-lr30  p-b0 p-t10 bdr-1 bdr-solid bdr-gray-light ">
			                    <div class="row poojas">
			                    	<div class="col-md-3">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10 p-l10 text-left">Pooja</h4>
			                        </div>
			                        <div class="col-md-2">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">Date</h4>
			                        </div>
			                        <div class="col-md-2">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">Name</h4>
			                        </div>
			                        <div class="col-md-2">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">Star</h4>
			                        </div>
			                        <div class="col-md-2">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">Gothram</h4>
			                        </div>
			                        <div class="col-md-1">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">Qty</h4>
			                        </div>
			                    </div>
			                </div>
			            </div> 
			            <div class="col-md-12 col-sm-12 col-xs-12 col-xs-100pc bg-white"  *ngFor="let item of orderlist">
			                <div class="wt-icon-box-wraper center p-lr30  p-b0 p-t10 bdr-1 bdr-solid bdr-gray-light ">
			                    <div class="row poojas" >
			                    	<div class="col-md-3">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10 p-l10 text-left">
				                        	{{item.pooja}} <br> ({{item.deity}})
				                        </h4>
			                        </div>
			                        <div class="col-md-2">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">{{ item.bookdate | date }}</h4>
			                        </div>
			                        <div class="col-md-2">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">{{item.name}}</h4>
			                        </div>
			                        <div class="col-md-2">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">{{item.star}}</h4>
			                        </div>
			                        <div class="col-md-2">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">{{item.gothram}}</h4>
			                        </div>
			                        <div class="col-md-1">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">{{item.qty}}</h4>
			                        </div>
			                    </div>
			                </div>
			            </div> 
		            </ng-container>  
		            <div *ngIf="orderlist.length==0" style="padding:50px 0;text-align:center;background:#fff;">
		            	<h4 class="text-center">-- No poojas booked yet --</h4>
		            </div>                     		                                                                
	          	</div>		        
		    </div>

	      </div>
	   	</div>

      	<app-footer1></app-footer1>

  <app-scroll-to-top></app-scroll-to-top>
      
   
  </div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>