<ng-template ngFor let-sectionData [ngForOf]="data">
  <div class="widget bg-white  recent-posts-entry">
    <h4 class="widget-title  text-uppercase font-weight-600">{{sectionData.section_title}}</h4>
    <div class="section-content">
      <div class="widget-post-bx">
            <div class="widget-post clearfix" *ngFor = "let item of sectionData.items">
                <div class="wt-post-media">
                    <img src="assets/images/blog/recent-blog/{{item.blog_image}}" alt="">
                </div>
                <div class="wt-post-info">
                    <div class="wt-post-meta">
                        <ul>
                            <li class="post-author">{{item.post_date}}</li>
                            <li class="post-comment"> {{item.comments}} Comment</li>
                        </ul>
                    </div>                                            
                    <div class="wt-post-header">
                        <h6 class="post-title"> <a routerLink="/post-gallery">{{item.title}}</a></h6>
                    </div>
                </div>
            </div>
            <!-- <div class="widget-post clearfix">
                <div class="wt-post-media">
                    <img src="assets/images/blog/recent-blog/pic2.jpg" alt="">
                </div>
                <div class="wt-post-info">
                    <div class="wt-post-meta">
                        <ul>
                            <li class="post-author">28 Dec</li>
                            <li class="post-comment"> 15 Comment</li>
                        </ul>
                    </div>                                            
                    <div class="wt-post-header">
                        <h6 class="post-title"> All fine architectural values are human values, else not valuable.</h6>
                    </div>
                </div>
            </div>
            <div class="widget-post clearfix">
                <div class="wt-post-media">
                    <img src="assets/images/blog/recent-blog/pic3.jpg" alt="">
                </div>
                <div class="wt-post-info">
                    <div class="wt-post-meta">
                        <ul>
                            <li class="post-author">30 Dec</li>
                            <li class="post-comment"> 11 Comment</li>
                        </ul>
                    </div>                                            
                    <div class="wt-post-header">
                        <h6 class="post-title"> All fine architectural values are human values, else not valuable.</h6>
                    </div>
                </div>
            </div>  --> 
        </div>
    </div>
  </div>
</ng-template>