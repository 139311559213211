<div class="widget bg-white  widget_services">
  <h4 class="widget-title text-uppercase font-weight-600">Categories</h4>
    <ul>
        <li><a href="javascript:void(0);">Architecture</a><span class="badge">28</span></li>
        <li><a href="javascript:void(0);">Awards</a><span class="badge">05</span></li>
        <li><a href="javascript:void(0);">Reseller</a><span class="badge">24</span></li>
        <li><a href="javascript:void(0);">Uncategorized</a><span class="badge">15</span></li>
        <li><a href="javascript:void(0);">Interviews</a><span class="badge">20</span></li>
        <li><a href="javascript:void(0);">Event</a><span class="badge">90</span></li>
    </ul>
</div>  