import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../modal.service';
import { ApiService } from '../../api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-nav-links',
  templateUrl: './nav-links.component.html',
  styleUrls: ['./nav-links.component.css']
})
export class NavLinksComponent implements OnInit {

	userid:any = 0;
  curl:any;
  token:any;
  itemdata:any;
  	constructor(private myModal: ModalService, private apiService: ApiService,private router: Router,private route: ActivatedRoute) { 
  		this.userid = localStorage.getItem('userid');
  		this.token = localStorage.getItem('token');
  		if(this.userid=='' || this.userid==undefined || this.userid==null) { 
  			this.userid=0;
  			console.log(this.userid);
  		}

	    this.myModal.myUserid$.subscribe((data) => {
	        this.userid = data;
	    });

	    this.myModal.myToken$.subscribe((data) => {
	        this.token = data;
	    });

      this.curl=this.router.url;
  	}

  	ngOnInit(): void {
  	}

  	logout() {
  		this.itemdata = {
				"token":this.token,
				"id":this.userid,
			}
			this.apiService.post('slogout', this.itemdata).then((res: any) => {
	  		if (res.status === 200) {
	          localStorage.setItem('userid','');
						localStorage.setItem('name','');
						localStorage.setItem('email','');
						localStorage.setItem('cartid','0');
						localStorage.setItem('cartid', '0');
						this.myModal.cartItems(0);                                            
						this.myModal.myUserid(0);
	          this.myModal.myToken(res.token);
						this.router.navigate(['/cart']);	
	  		} else {
	          const message = res.message;
	      }
  		});
  	}

}
