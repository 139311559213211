<div class="section-full small-device  p-tb80 bg-white bg-bottom-right bg-no-repeat" style="background-image:url(assets/images/background/bg-4.jpg);">
  <div class="container">
  
      <div class="section-content">

        <div class="row">
            <div class="col-md-5 col-sm-12">
                <div class="why-choose-half-section">
                    <div class="wt-media">
                    <img src="assets/images/temple.png" alt="">
                      </div>
                      
                 </div>
              </div>                        
          <div class="col-md-7 col-sm-12">
                  <!-- TITLE START -->
                  <div class="section-head text-left">
                      <h4 class="text-primary text-uppercase wt-title-small">Our specialization</h4>
                      <h2 class="text-uppercase font-45 font-weight-500 m-b5">About our company</h2>
                  </div>
                  <!-- TITLE END -->                              
  <div class="wt-box">
                          <h2>Industry is older than modern humans. Over time, the methods used have varied considerably.</h2>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non libero consectetur, blandit mauris eget, imperdiet nisl. Etiam commodo ex nec erat tempor varius. Nunc rutrum pretium nunc in malesuada. Curabitur mollis urna ac sapien vulputate, ut congue sapien vehicula. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam et suscipit dui. Maecenas velit quam, mollis id erat eget, scelerisque elementum odio. Maecenas ac sagittis erat. Quisque hendrerit dapibus diam, a venenatis dui efficitur nec. Aenean tincidunt ullamcorper fermentum. </p>
                          <div class="text-left">
                            <img src="assets/images/sign.jpg" alt="">
                          </div>                                                                    
                  </div>
              </div>
          </div>

      </div>
  </div>
</div>  