import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { ModalService } from '../../modal.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-devotee',
  templateUrl: './devotee.component.html',
  styleUrls: ['./devotee.component.css']
})
export class DevoteeComponent implements OnInit {

  name:any;
  dname:any;
  lstar:any;
  dstar:any;
  stars:any;
  lgothram:any;
  dgothram:any;
  gothras:any;
  userid:any;
  message:any;
  emsg:any;
  itemdata:any;
  data:any;
  devotees:any;
  list:any=0;
  editid:any;
  itemData:any;

    constructor(private myModal: ModalService, private apiService: ApiService,private router: Router,private route: ActivatedRoute) { 
      this.userid = localStorage.getItem('userid');
      if(this.userid=='' || this.userid==undefined || this.userid==null || this.userid==0) {
        this.router.navigate(['/login']);
      }
      this.getDevotees();
      this.getGothram();
      this.getStars();

    }

    ngOnInit(): void {
    }

    getDevotees() {
      this.itemData = {
        "id":this.userid
      }
      this.apiService.post('m_dl', this.itemData).then((res: any) => {
        if (res.status === 200) {
          this.devotees = res.data;
        } else {
          const message = res.message;
        }
      });
    }

    change() {
      this.emsg='';
      if(this.name=='' || this.lstar=='' || this.lgothram=='') {
        this.message="Please fill in name, star and gothram";
      } else {
          this.emsg='';
          this.itemdata = {
            "id":this.userid,
            "name":this.name,
            "star":this.lstar,
            "gothram":this.lgothram,
          }
          this.apiService.post('m_du', this.itemdata).then((res: any) => {
              if (res.status === 200) {
                this.devotees = res.data;
                this.name='';
                this.lstar='';
                this.lgothram='';
                this.list=0;
              } else {
                this.message = res.msg;
              }
          });
      }
    }

    add() {
      this.list=1;
    }

    showlist() {
      this.list=0;
    }

    editDevotee(id) {
      this.list=2;
      this.editid=id;
      this.itemData = {
        "id":id
      }
      this.apiService.post('m_edl', this.itemData).then((res: any) => {
        if (res.status === 200) {
          this.dname = res.data[0].name;
          this.dgothram = res.data[0].gothram;
          this.dstar = res.data[0].star;
        } else {
          const message = res.message;
        }
      });
    }

    update() {
      this.emsg='';
      if(this.dname=='' || this.dstar=='' || this.dgothram=='') {
        this.message="Please fill in name, star and gothram";
      } else {
          this.emsg='';
          this.itemdata = {
            "id":this.editid,
            "name":this.dname,
            "star":this.dstar,
            "gothram":this.dgothram,
          }
          this.apiService.post('m_edu', this.itemdata).then((res: any) => {
              if (res.status === 200) {
                this.devotees = res.data;
                this.dname='';
                this.dstar='';
                this.dgothram='';
                this.list=0;
              } else {
                this.message = res.msg;
              }
          });
      }
    }

    canceledit() {
      this.dname='';
      this.dstar='';
      this.dgothram='';
      this.list=0;
    }

    deleteDevotee(id) {
      if(confirm("Are you sure you want to delete the devotee?")) {
        this.list=0;
        this.itemData = {
          "id":id,
          "cid":this.userid
        }
        this.apiService.post('m_ddl', this.itemData).then((res: any) => {
          if (res.status === 200) {
            this.devotees = res.data;
          } else {
            const message = res.message;
          }
        });
      } 
    }

    getGothram() {
        this.apiService.post('listgothram', {}).then((res: any) => {
          if (res.status === 200) {
            this.gothras = res.data;
          } else {
            const message = res.message;
          }
        });
    }

    getStars() {
        this.apiService.post('liststars', {}).then((res: any) => {
          if (res.status === 200) {
            this.stars = res.data;
          } else {
            const message = res.message;
          }
        });
    }

}
