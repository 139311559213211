<header class="site-header header-style-1  nav-wide">
  
  <div class="sticky-header main-bar-wraper">
      <div class="main-bar bg-white p-t5">
          <div class="container clearfix">
            <div class="header-center">
                  <div class="wt-header-left">
                      <!-- Header left -->
                          <ul class="list-unstyled e-p-bx">
                              <li><i class="fa fa-envelope m-r10 text-primary"></i>construx@gmail.com</li>
                              <li><i class="fa fa-phone m-r10 text-primary"></i>(0123) 321 654 1234</li>
                          </ul>
                      </div>
                  <div class="wt-header-center">
                      <div class="logo-header">
                          <div class="logo-header-inner logo-header-one">
                              <a routerLink="/index">
                                  <img src="assets/images/logo-dark.png" width="171" height="49" alt="" />
                              </a>
                          </div>
                      </div>
                  </div>                                          
                  <div class="wt-header-right">

                      <!-- ETRA Nav -->
                      <div class="extra-nav">
                          <div class="extra-cell">
                              <a href="#search" class="site-search-btn">Seach<i class="fa fa-search text-primary m-l10"></i></a>
                          </div>
                       </div>
                      <!-- ETRA Nav -->
                      <div class="extra-nav">
                          <div class="extra-cell">
                              <a href="#social-top-icon" class="site-menu-share">Share<i class="fa fa-share-alt text-primary m-l10"></i></a>
                          </div>
                       </div>
                       
                      <!-- ETRA Nav -->
                      <div class="extra-nav">
                        <div class="extra-cell">
                              <div class="menu-btn">
                                  <button type="button" class="btn-open"></button>
                              </div>
                          </div>
                       </div>                         
                       <!-- ETRA Nav -->                                  
                                                                               
                  </div>
              </div>
               <!-- Search popup -->
              <div id="search"> 
                  <span class="close"></span>
                  <form role="search" id="searchform" action="/search" method="get" class="radius-xl">
                      <div class="input-group">
                          <input value="" name="q" type="search" placeholder="Type to search"/>
                          <span class="input-group-btn"><button type="button" class="search-btn"><i class="fa fa-search"></i></button></span>
                      </div>   
                  </form>
              </div>
          
               <!-- Social popup -->
              <div id="social-top-icon"> 
                  <span class="close"></span>
                  <div class="full-social-bg">
                        <ul>
                            <li><a href="#" class="facebook"><i class="fa fa-facebook"></i></a></li>
                              <li><a href="#" class="google"><i class="fa fa-google"></i></a></li>
                              <li><a href="#" class="instagram"><i class="fa fa-instagram"></i></a></li>
                              <li><a href="#" class="tumblr"><i class="fa fa-tumblr"></i></a></li>
                              <li><a href="#" class="twitter"><i class="fa fa-twitter"></i></a></li>
                              <li><a href="#" class="youtube"><i class="fa fa-youtube"></i></a></li>
                        </ul>
                  </div>
              </div>                          
              
              <div class="full-menu">
                  <button type="button" class="full-menu-close"></button>
                  <!-- ETRA Nav   ======= --> 
                      <div class="vertical-content-wrap">
                          <div class="vertical-centered">
                              <!-- MAIN Vav -->
                              <div class="vertical-nav">
                                  <app-nav-links></app-nav-links>
                              </div>
                          </div> 
                      </div>
                  <!-- ETRA Nav  END ==== -->                               
              </div> 

          </div>
      </div>
  </div>
  
</header>