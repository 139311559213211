<div class="page-wraper"> 
    
    <!-- HEADER START -->
    <app-header1></app-header1>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">
        <!-- INNER PAGE BANNER -->
        <app-banner1 [data]="banner"></app-banner1>
        <!-- INNER PAGE BANNER END -->
        
  <!-- SECTION CONTENT START -->
        <div class="section-full small-device  p-tb80">
          <div class="container">
                <div class="project-detail-outer">
        <div class="row">
                      <div class="col-md-12">
                          <div class="project-detail-pic m-b30">
                                <div class="wt-media">
                                    <img src="assets/images/gallery/pic1.jpg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>                    

                    <div class="project-detail-containt">
                        <div class="bg-white text-black">
                          <div class="row">
                                <div class="col-md-5 col-sm-6">
                                    <div class="product-block">
                                        <div class="row">
                                          <div class="col-md-6 col-sm-6 m-b30">
                                            <h3 class="text-uppercase font-weight-600 m-b10">Date</h3>
                                            <p>Dec 20, 2018</p>
                                          </div>
                                          <div class="col-md-6 col-sm-6 m-b30">
                                            <h3 class="text-uppercase font-weight-600 m-b10">Client</h3>
                                            <p>Branding NthPsd Company</p>
                                           </div>
                                          <div class="col-md-6 col-sm-6 m-b30">
                                            <h3 class="text-uppercase font-weight-600 m-b10">Project type</h3>
                                            <p>Contruction, Brading</p>
                                          </div>
                                          <div class="col-md-6 col-sm-6 m-b30">
                                            <h3 class="text-uppercase font-weight-600 m-b10">Creative Director</h3>
                                            <p>Lorem Ipsum doler</p>
                                          </div>
                                      </div>
                                    </div>
                                    <div class="p-t0">
                                        <ul class="social-icons social-square social-lg social-darkest m-b0">
                                            <li><a href="javascript:void(0);" class="fa fa-facebook"></a></li>
                                            <li><a href="javascript:void(0);" class="fa fa-twitter"></a></li>
                                            <li><a href="javascript:void(0);" class="fa fa-linkedin"></a></li>
                                            <li><a href="javascript:void(0);" class="fa fa-rss"></a></li>
                                            <li><a href="javascript:void(0);" class="fa fa-youtube"></a></li>
                                            <li><a href="javascript:void(0);" class="fa fa-instagram"></a></li>
                                        </ul>
                                    </div>
                                </div>                                
                              <div class="col-md-7 col-sm-6">
                                    <h2>To provide meaningful architecture is not to parody history, but to articulate it.</h2>
                                    <p><strong>Typefaces and layouts, and in appeara nce of different general the content of dummy text is nonsensical.typefaces of dummy text is nonsensical.</strong></p>
                                    <p> Designers think everything done by someone else is awful, and that they could do it better themselves, which explains why I designed my own living room carpet, I suppose. the architect represents neither a Dionysian nor an Apollinian condition: here it is the mighty act of will, the will which moves mountains, the intoxication of the strong will, which demands artistic expression. The most powerful men have always inspired the architects; the architect has always been influenced by power.</p>
                              </div>
                            </div>

                        </div>
                    </div>
                    
        <div class="row">
                      <div class="col-md-6"  *ngFor = "let item of project_images_section">
                          <div class="project-detail-pic m-b30">
                                <div class="wt-media">
                                    <img src="assets/images/gallery/{{item.blog_image}}" alt="">
                                </div>
                            </div>
                        </div>
                        
                      <!-- <div class="col-md-6">
                          <div class="project-detail-pic m-b30">
                                <div class="wt-media">
                                    <img src="assets/images/gallery/pic2.jpg" alt="">
                                </div>
                            </div>
                        </div>
                        
                      <div class="col-md-6">
                          <div class="project-detail-pic m-b30">
                                <div class="wt-media">
                                    <img src="assets/images/gallery/pic3.jpg" alt="">
                                </div>
                            </div>
                        </div>
                        
                        
                      <div class="col-md-6">
                          <div class="project-detail-pic m-b30">
                                <div class="wt-media">
                                    <img src="assets/images/gallery/pic4.jpg" alt="">
                                </div>
                            </div>
                        </div>                                                                                    
                      
                    </div>    -->                       
                  
                </div>
            </div>
        </div>
        <!-- SECTION CONTENT END  -->

    </div>
    <!-- CONTENT END -->
    
    <!-- FOOTER START -->
    <app-footer1></app-footer1>
    <!-- FOOTER END -->

    <!-- BUTTON TOP START -->
<app-scroll-to-top></app-scroll-to-top>
    
</div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>