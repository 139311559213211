<div class="page-wraper"> 
      
      	<app-header2></app-header2>
      
      	<div class="container">
	      <!-- CONTENT START -->
	      <div class="page-content">        

            <div class="section-head text-center">
                <h2 class="text-uppercase  font-weight-900 m-b5 text-center">
                	Edit Profile
                	<p class="formInfo">Update your profile details</p>
                </h2>
                <div class="wt-separator-outer text-center">
                    <div class="wt-separator bg-primary"></div>
                </div>
            </div>

   			<div class="section-content clearfix">
		        <div class="col-md-12">

			            <div style="padding:20px 0;text-align:center;background:#fff;width:90%;margin:0 auto;">
			            	<div class="ramForm">

				            	<div class="row">			            		

							        <div class="col-md-6 p-t5 p-r5 p-b5 p-l5">
								        <div class="form-group">
								        	<label>Name</label>
								            <input name="name" type="text" required="" class="form-control"  (input)="name=$event.target.value" value="{{name}}">
								        </div>
								    </div>

								    <div class="col-md-6 p-t5 p-r5 p-b5 p-l5">
								        <div class="form-group">
								        	<label>Email ID</label>
								            <input name="email" type="email" required="" class="form-control"  (input)="email=$event.target.value" value="{{email}}" readonly disabled>
								        </div>
								    </div>

								    <div class="col-md-6 p-t5 p-r5 p-b5 p-l5">
								        <div class="form-group">
								        	<label>Phone No</label>
								            <input name="phone" type="text" required="" class="form-control"  (input)="phone=$event.target.value" value="{{phone}}">
								        </div>
								    </div>

								    <div class="col-md-6 p-t5 p-r5 p-b5 p-l5">
								        <div class="form-group">
								        	<label>Address</label>
								            <textarea name="address" type="text" required="" class="form-control"  (input)="address=$event.target.value">{{address}}</textarea>
								        </div>
								    </div>

								    <div class="col-md-6 p-t5 p-r5 p-b5 p-l5">
								        <div class="form-group">
								        	<label>City</label>
								            <input name="city" type="text" required="" class="form-control"  (input)="city=$event.target.value" value="{{city}}">
								        </div>
								    </div>

								    <div class="col-md-6 p-t5 p-r5 p-b5 p-l5">
								        <div class="form-group">
								        	<label>State</label>
								            <input name="state" type="text" required="" class="form-control"  (input)="state=$event.target.value" value="{{state}}">
								        </div>
								    </div>

								    <div class="col-md-6 p-t5 p-r5 p-b5 p-l5">
								        <div class="form-group">
								        	<label>Country</label>
								            <input name="country" type="text" required="" class="form-control"  (input)="country=$event.target.value" value="{{country}}">
								        </div>
								    </div>

								</div>

						        <p class="error" *ngIf="emsg!=''">{{emsg}}</p>
						        <p class="error" *ngIf="message!=''">{{message}}</p>

						        <a href="javascript:void(0)" (click)="change()" class="site-button-secondry button-lg site-btn-effect" ><span>Update Profile</span></a>

						    </div>
			            </div>                     		                                                                
		        </div>
		    </div>

	      </div>
	   	</div>

      	<app-footer1></app-footer1>

  <app-scroll-to-top></app-scroll-to-top>
      
   
  </div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>