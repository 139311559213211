import { Injectable } from '@angular/core';
import { Observable, of, Subscription, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ModalService {

    myModal$: Observable<any>;
    myPooja$: Observable<any>;
    myToken$: Observable<any>;
    cartItems$: Observable<any>;
    cart$: Observable<any>;
    rate$: Observable<any>;
    myUserid$: Observable<any>;
    myBefore$: Observable<any>;

    private myModalstatus = new Subject<any>();
    private myPoojaId = new Subject<any>();
    private myTokenString = new Subject<any>();
    private myCartItems = new Subject<any>();
    private myCart = new Subject<any>();
    private myRate = new Subject<any>();
    private myUseridDet = new Subject<any>();
    private myBeforeDate = new Subject<any>();

    constructor() {
        this.myModal$ = this.myModalstatus.asObservable();
        this.myPooja$ = this.myPoojaId.asObservable();
        this.myToken$ = this.myTokenString.asObservable();
        this.cartItems$ = this.myCartItems.asObservable();
        this.cart$ = this.myCart.asObservable();
        this.rate$ = this.myRate.asObservable();
        this.myUserid$ = this.myUseridDet.asObservable();
        this.myBefore$ = this.myBeforeDate.asObservable();
    }

    myModal(data) {
        this.myModalstatus.next(data);
    }

    myPooja(data) {
    	this.myPoojaId.next(data);
    }

    /*myRate(data) {
    	this.myPoojaRate.next(data);
    }*/

    myToken(data) {
    	this.myTokenString.next(data);
    }

    cartItems(data) {
    	this.myCartItems.next(data);
    }

    cart(data) {
    	this.myCart.next(data);
    }

    rate(data) {
    	this.myRate.next(data);
    }

    mbefore(data) {
        this.myBeforeDate.next(data);
    }

    myUserid(data) {
    	console.log(data);
    	localStorage.setItem('userid',data);
    	this.myUseridDet.next(data);
    }
}