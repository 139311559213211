<div class="section-full small-device  p-t80 p-b80 bg-white">
  <ng-template ngFor let-sectionData [ngForOf]="data">
      <div class="container">
              

                  <!-- TITLE START -->
        <div class="section-head text-left">
            <h4 class="text-primary text-uppercase wt-title-small">{{sectionData.section_title}}</h4>
            <h2 class="text-uppercase font-45 font-weight-500 m-b5">{{sectionData.section_heading}}</h2>
        </div>
        <!-- TITLE END --> 
       
        <!-- IMAGE CAROUSEL START -->
        <div class="our-team-reverse p-lr15">
            <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-6 col-xs-100pc">
                  <div class="clearfix  our-team-reverse-space bg-gray wt-team-arc no-col-gap equal-wraper">
                      <div class="col-md-6 col-sm-12 equal-col">
                            <div class="wt-media">
                                <img src="assets/images/our-team5/{{sectionData.team[0].image}}" alt="">
                            </div>                                   
                        </div>
                        <div class="col-md-6 col-sm-12 equal-col">
                            <div class="wt-info">
                                <div class="team-detail text-center">
                                    <h3 class="font-weight-600 m-t0 m-b5 text-uppercase">{{sectionData.team[0].name}}</h3>
                                    <p>{{sectionData.team[0].role}}</p>
                                </div>
          <ul class="social-icons  wt-social-links">
                                    <li *ngFor="let social of sectionData.team[0].social_link "><a title="{{social.title}}" href="{{social.link}}"  class="fa {{social.icon_class}}"></a></li>
                                </ul>                                            
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-6 col-xs-100pc">
                  <div class="clearfix  our-team-reverse-space  bg-gray wt-team-arc no-col-gap equal-wraper">
                      <div class="col-md-6 col-sm-12 equal-col">
                            <div class="wt-media">
                                <img src="assets/images/our-team5/{{sectionData.team[1].image}}" alt="">
                            </div>                                   
                        </div>
                        <div class="col-md-6 col-sm-12 equal-col">
                            <div class="wt-info">
                                <div class="team-detail text-center">
                                    <h3 class="font-weight-600 m-t0 m-b5 text-uppercase">{{sectionData.team[1].name}}</h3>
                                    <p>{{sectionData.team[1].role}}</p>
                                </div>
          <ul class="social-icons  wt-social-links">
                                   <li *ngFor="let social of sectionData.team[1].social_link "><a title="{{social.title}}" href="{{social.link}}"  class="fa {{social.icon_class}}"></a></li>
                                </ul>                                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-6 col-xs-100pc ">
                  <div class="clearfix   our-team-reverse-space bg-gray wt-team-arc no-col-gap equal-wraper">
                      <div class="col-md-6 col-sm-12 equal-col">
                            <div class="wt-media">
                                <img src="assets/images/our-team5/{{sectionData.team[2].image}}" alt="">
                            </div>                                   
                        </div>                                
                        <div class="col-md-6 col-sm-12 equal-col">
                            <div class="wt-info">
                                <div class="team-detail text-center">
                                    <h3 class="font-weight-600 m-t0 m-b5 text-uppercase">{{sectionData.team[2].name}}</h3>
                                    <p>{{sectionData.team[2].role}}</p>
                                </div>
          <ul class="social-icons  wt-social-links">
                                    <li *ngFor="let social of sectionData.team[2].social_link "><a title="{{social.title}}" href="{{social.link}}"  class="fa {{social.icon_class}}"></a></li>
                                </ul>                                            
                            </div>
                        </div>                                
                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-6 col-xs-100pc ">
                  <div class="clearfix  our-team-reverse-space bg-gray wt-team-arc no-col-gap equal-wraper">
                      <div class="col-md-6 col-sm-12 equal-col">
                            <div class="wt-media">
                                <img src="assets/images/our-team5/{{sectionData.team[3].image}}" alt="">
                            </div>                                   
                        </div>                                
                        <div class="col-md-6 col-sm-12 equal-col">
                            <div class="wt-info">
                                <div class="team-detail text-center">
                                    <h3 class="font-weight-600 m-t0 m-b5 text-uppercase">{{sectionData.team[3].name}}</h3>
                                    <p>{{sectionData.team[3].role}}</p>
                                </div>
          <ul class="social-icons  wt-social-links">
                                    <li *ngFor="let social of sectionData.team[3].social_link "><a title="{{social.title}}" href="{{social.link}}"  class="fa {{social.icon_class}}"></a></li>
                                </ul>                                            
                            </div>
                        </div>                                
                    </div>
                </div>                        
            </div>
            
        </div>                    
      </div>
  </ng-template>
</div>