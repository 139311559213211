import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { ModalService } from '../../modal.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

	cartlist:any = [];
	cartid:any;
	token:any;
	userid:any;
  	constructor(private myModal: ModalService, private apiService: ApiService) { 

  		this.token = localStorage.getItem('token');
  		this.userid = localStorage.getItem('userid');
  		this.cartid = localStorage.getItem('cartid');
  		if(this.cartid=='' || this.cartid==undefined || this.cartid==0) {
  			this.generateCart();
  		} else {
  			this.getCart();
  		}

  	}

  	ngOnInit(): void {
  	}

  	getCart() {
	    this.apiService.post('viewCart', { cart: this.cartid }).then((res: any) => {
	      if (res.status === 200) {
	        this.cartlist = res.data;
	        this.myModal.cartItems(res.count);
	      } else {
	        const message = res.message;
	      }
	    });
	}

  	generateCart() {
		if(this.userid=='' || this.userid==undefined) {
			this.userid=0;
		}
	    this.apiService.post('generateCart', { userid: this.userid, token:this.token }).then((res: any) => {
	      if (res.status === 200) {
	        this.cartid = res.data;
	        this.cartlist = res.cart;	        
          localStorage.setItem('cartid',res.data);
	        this.getCart();
	      } else {
	        const message = res.message;
	      }
	    });
	}

	removeCart(id) {
		if(confirm("Are you sure you want to remove this from cart?")) {
			this.apiService.post('removeFromCart', { id: id }).then((res: any) => {
		      if (res.status === 200) {
		        this.getCart();

		      } else {
		        const message = res.message;
		      }
		    });
		}
	}

	emptyCart() {
		if(confirm("Are you sure you want to empty this from cart?")) {
			this.apiService.post('emptyCart', { cartid: this.cartid }).then((res: any) => {
		      if (res.status === 200) {
		        this.getCart();
		        this.myModal.cartItems(0);
		      } else {
		        const message = res.message;
		      }
		    });
		}
	}

}
