<header class="site-header header-style-1  nav-wide">
  
  <div class="sticky-header main-bar-wraper">
      <div class="main-bar bg-white p-t10">
          <div class="container">
              <div class="logo-header">
                <div class="logo-header-inner logo-header-one">
                    <a routerLink="/index">
                      <img src="assets/images/logo.png" width="auto" style="height:60px;width:auto;" alt="" />
                  </a>
                  </div>
              </div>
              <!-- NAV Toggle Button -->
              <button data-target=".header-nav" data-toggle="collapse" type="button" class="navbar-toggle collapsed">
                  <span class="sr-only">Toggle navigation</span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
              </button>

                   <!-- Search popup -->
                  <div id="search"> 
                      <span class="close"></span>
                      <form role="search" id="searchform" action="/search" method="get" class="radius-xl">
                          <div class="input-group">
                              <input value="" name="q" type="search" placeholder="Type to search"/>
                              <span class="input-group-btn"><button type="button" class="search-btn"><i class="fa fa-search"></i></button></span>
                          </div>   
                      </form>
                  </div>
              
                   <!-- Social popup -->
                  <div id="social-top-icon"> 
                      <span class="close"></span>
                      <div class="full-social-bg">
                            <ul>
                                  <li><a href="https://www.facebook.com/theasthikasamajnama20/" class="facebook" target="_blank"><i class="fa fa-facebook"></i></a></li>
                                  <li><a href="https://www.youtube.com/channel/UCO6PEIwj-YyHMbcGHiZxQRw" class="youtube" target="_blank"><i class="fa fa-youtube"></i></a></li>
                            </ul>
                      </div>
                  </div> 
                  
                  
                      <!-- ETRA Nav -->

                      <div class="extra-nav header-2-nav">
                          <div class="extra-cell">
                              <a href="http://www.asthikasamajmatunga.com/contact.php" target="_blank" class="site-search-btn">Contact Us<i class="fa fa-phone text-primary m-l10"></i></a>
                          </div>
                       </div>
                      <!-- ETRA Nav -->
                      <div class="extra-nav header-2-nav">
                          <div class="extra-cell" style="padding-left:0;">
                              <a href="javascript:void(0)" routerLink="/cart" class="site-menu-share" style="position:relative;">
                                <span class="cartcount" *ngIf="cartitems>0">{{cartitems}}</span>
                                <i class="fas fa-shopping-cart text-primary"></i></a>
                          </div>
                       </div>
                      
                                                
                
              <!-- MAIN Vav -->
              <div class="header-nav nav-animation navbar-collapse collapse">
          
                  <app-nav-links></app-nav-links>

              </div>
          </div>
      </div>
  </div>
  
</header>