<header class="site-header header-style-1  nav-wide nav-full-banner">
  
  <div class="sticky-header main-bar-wraper">
      <div class="main-bar bg-white p-t10">
          <div class="container">
              <div class="logo-header">
                <div class="logo-header-inner logo-header-one">
                    <a routerLink="/index">
                      <img src="assets/images/logo-dark.png" width="171" height="49" alt="" />
                  </a>
                  </div>
              </div>
              <!-- NAV Toggle Button -->
              <button data-target=".header-nav" data-toggle="collapse" type="button" class="navbar-toggle collapsed">
                  <span class="sr-only">Toggle navigation</span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
              </button>

                   <!-- Search popup -->
                  <div id="search"> 
                      <span class="close"></span>
                      <form role="search" id="searchform" action="/search" method="get" class="radius-xl">
                          <div class="input-group">
                              <input value="" name="q" type="search" placeholder="Type to search"/>
                              <span class="input-group-btn"><button type="button" class="search-btn"><i class="fa fa-search"></i></button></span>
                          </div>   
                      </form>
                  </div>
              
                   <!-- Social popup -->
                  <div id="social-top-icon"> 
                      <span class="close"></span>
                      <div class="full-social-bg">
                            <ul>
                                  <li><a href="#" class="facebook"><i class="fa fa-facebook"></i></a></li>
                                  <li><a href="#" class="google"><i class="fa fa-google"></i></a></li>
                                  <li><a href="#" class="instagram"><i class="fa fa-instagram"></i></a></li>
                                  <li><a href="#" class="tumblr"><i class="fa fa-tumblr"></i></a></li>
                                  <li><a href="#" class="twitter"><i class="fa fa-twitter"></i></a></li>
                                  <li><a href="#" class="youtube"><i class="fa fa-youtube"></i></a></li>
                            </ul>
                      </div>
                  </div> 
                  
                  
                      <!-- ETRA Nav -->
                      <div class="extra-nav header-2-nav">
                          <div class="extra-cell">
                              <a href="#search" class="site-search-btn">Seach<i class="fa fa-search text-primary m-l10"></i></a>
                          </div>
                       </div>
                      <!-- ETRA Nav -->
                      <div class="extra-nav header-2-nav">
                          <div class="extra-cell">
                              <a href="#social-top-icon" class="site-menu-share">Share<i class="fa fa-share-alt text-primary m-l10"></i></a>
                          </div>
                       </div>
                      
                                                
                
              <!-- MAIN Vav -->
              <div class="header-nav nav-animation navbar-collapse collapse">
                  <app-nav-links></app-nav-links>
              </div>
          </div>
      </div>
  </div>
  
</header>