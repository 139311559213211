import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { ModalService } from '../../modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import {NgxPrintModule} from 'ngx-print';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {

	status:any;
	orderid:any;
	itemData:any;
	userid:any;
	orderlist:any;
	name:any;
	email:any;

	orderdet:any;
	ordeitems:any;
  	constructor(private router: Router,private route: ActivatedRoute, private myModal: ModalService, private apiService: ApiService) { 
  		window.scrollTo(0, 0);
  		this.status = localStorage.getItem('orderStatus');
  		this.orderid = localStorage.getItem('orderId');
  		this.getOrders();
  		this.viewOrder();
  		this.emailOrder();
  	}

  	ngOnInit(): void {
  	}

  	getOrders() {
  		this.itemData = {
  			"orderid":this.orderid
  		}
  		this.apiService.post('getorderStatus', this.itemData).then((res: any) => {
	      if (res.status === 200) {
	        this.orderlist = res.data;
	      } else {
	        const message = res.message;
	      }
	    });
  	}

  	viewOrder() {
  		this.itemData = {
  			"orderid":this.orderid
  		}
  		this.apiService.post('vieworder', this.itemData).then((res: any) => {
	      if (res.status === 200) {
	        this.orderdet = res.order;
	        this.ordeitems = res.items;
	      } else {
	        const message = res.message;
	      }
	    });
  	}

  	emailOrder() {
  		this.itemData = {
  			"orderid":this.orderid
  		}
  		this.apiService.post('sendorderemail', this.itemData).then((res: any) => {
	      if (res.status === 200) {

	      } else {
	        const message = res.message;
	      }
	    });
  	}

}
