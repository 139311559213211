<div class="page-wraper"> 
      
      	<app-header2></app-header2>
      
      	<div class="container">
	      <!-- CONTENT START -->
	      <div class="page-content">        

            <div class="section-head text-center">
                <h2 class="text-uppercase  font-weight-900 m-b5 text-left">
                	My Cart
                	<a routerLink="/checkout" *ngIf="cartlist" class="site-button-secondry button-sm site-btn-effect" style="float: right;"><span>Proceed to Checkout</span></a>
                	<a href="javascript:void(0);" *ngIf="cartlist"  (click)="emptyCart()" class="site-button button-sm site-btn-effect " style="float: right;margin-right:10px;"><span>Empty Cart</span></a>
                </h2>
                <div class="wt-separator-outer text-left">
                    <div class="wt-separator bg-primary"></div>
                </div>
            </div>

            <div class="section-content clearfix">
		        <div class="col-md-12">
		        	<div class="row  no-col-gap bg-primary" *ngIf="cartlist.length>0" >
			            <div class="col-md-12 col-sm-12 col-xs-12 col-xs-100pc bg-white headrow">
			                <div class="wt-icon-box-wraper center p-lr30  p-b0 p-t15 bdr-1 bdr-solid bdr-gray-light hover-border-outer hover-border">
			                    <div class="row poojas ">
			                    	<div class="col-md-2">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b20 p-l10 text-left">
				                        	Devotee
				                        </h4>
			                        </div>
			                        <div class="col-md-2">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b20">Gothram</h4>
			                        </div>
			                        <div class="col-md-2">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b20">Star</h4>
			                        </div>
			                        <div class="col-md-2">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b20">Date</h4>
			                        </div>
			                        <div class="col-md-1">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b20">Qty</h4>
			                        </div>
			                        <div class="col-md-1">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b20">Amount</h4>
			                        </div>
			                        <div class="col-md-1">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b20">Total</h4>
			                        </div>
			                        <div class="col-md-1">
			                        	
			                        </div>
			                    </div>
			                </div>
			            </div>                        		                                                                
		          	</div>
		            <div class="row  no-col-gap bg-primary">
		            	<ng-container *ngIf="cartlist.length>0" >
				            <div class="col-md-12 col-sm-12 col-xs-12 col-xs-100pc bg-white"  *ngFor="let item of cartlist">
				                <div class="wt-icon-box-wraper center p-lr30  p-b0 p-t15 bdr-1 bdr-solid bdr-gray-light ">
				                    <div class="row poojas">
				                    	<div class="col-md-2">
				                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b20 p-l10 text-left">
					                        	{{item.name}}
					                        	<div class="subinfo">{{item.pooja}}</div>
					                        	<div class="subinfo" style="color: #fd8a14;">{{item.deity}}</div>
					                        </h4>
				                        </div>
				                        <div class="col-md-2">
				                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b20">{{item.gothram}}</h4>
				                        </div>
				                        <div class="col-md-2">
				                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b20">{{item.star}}</h4>
				                        </div>
				                        <div class="col-md-2">
				                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b20">{{item.bookdate}}</h4>
				                        </div>
				                        <div class="col-md-1">
				                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b20">{{item.qty}}</h4>
				                        </div>
				                        <div class="col-md-1">
				                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b20">₹ {{item.amount}}</h4>
				                        </div>
				                        <div class="col-md-1">
				                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b20">₹ {{item.total}}</h4>
				                        </div>
				                        <div class="col-md-1">
				                        	<a href="javascript:void(0)" (click)="removeCart(item.id)" title="Remove from Cart"><span><i class="fas fa-trash text-primary"></i></span></a>
				                        </div>
				                    </div>
				                </div>
				            </div> 
			            </ng-container>  
			            <div *ngIf="cartlist.length==0" style="padding:50px 0;text-align:center;background:#fff;">
			            	<h4 class="text-center">-- No poojas added in cart yet --</h4>
			            </div>                     		                                                                
		          	</div>
		          	<div class="row">
		          		<div class="col-md-12 text-center m-t40">
		          			<a href="javascript:void(0);" *ngIf="cartlist.length>0" (click)="emptyCart()" class="site-button button-lg site-btn-effect " style="margin-right:15px;"><span>Empty Cart</span></a>
		          			<a routerLink="/checkout" *ngIf="cartlist.length>0" class="site-button-secondry button-lg site-btn-effect" ><span>Proceed to Checkout</span></a>
		          			
		          		</div>
		          	</div>
		        </div>
		    </div>

	      </div>
	   	</div>

      	<app-footer1></app-footer1>

  <app-scroll-to-top></app-scroll-to-top>
      
   
  </div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>