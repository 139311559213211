<div class="page-wraper"> 
  
  <!-- HEADER START -->
  <app-header1></app-header1>
  <!-- HEADER END -->
  
  <!-- CONTENT START -->
  <div class="page-content ">
      <!-- INNER PAGE BANNER -->
      <app-banner1 [data]="banner"></app-banner1>
      <!-- INNER PAGE BANNER END -->
      
      <!-- SECTION CONTENT START -->
      <div class="section-full small-device  p-tb80">
          <div class="container">
              <div class="row">
                  <div class="col-md-8 col-sm-12">
                      <!-- BLOG START -->
                      <div class="blog-post date-style-1 blog-detail text-black">
                          <div class="wt-post-media">
                              <!--Fade slider-->
                              <div class="owl-carousel owl-fade-slider-one owl-btn-vertical-center owl-dots-bottom-right m-b30">
                              
                                  <div class="item">
                                      <div class="aon-thum-bx">
                                          <img src="assets/images/blog/default/thum1.jpg" alt="">
                                      </div>
                                  </div>
                                  
                                  <div class="item">
                                      <div class="aon-thum-bx">
                                          <img src="assets/images/blog/default/thum2.jpg" alt="">
                                      </div>
                                  </div>
                                  
                                  <div class="item">
                                      <div class="aon-thum-bx">
                                          <img src="assets/images/blog/default/thum3.jpg" alt="">
                                      </div>
                                  </div>                                    
                              
                              </div>
                              <!--fade slider END-->
                          </div>
                          
                          <div class="wt-post-meta ">
                              <ul>
                                  <li class="post-author"><i class="fa fa-user"></i><strong>By Admin</strong></li>                                                
                                  <li class="post-date"><i class="fa fa-calendar"></i><strong>20 Sep 2018 </strong></li>
                              </ul>
                          </div>
                                                        
                          <div class="wt-post-title ">
                              <h2 class="post-title">When we build, let us think that we build forever.</h2>
                          </div>
                          
                          <div class="wt-post-text">
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ornare sem sed quam tempus aliquet vitae eget dolor. Proin eu ultrices libero. Curabitur vulputate vestibulum elementum. Suspendisse id neque a nibh mollis blandit. Quisque varius eros ac purus dignissim. Proin eu ultrices libero. Curabitur vulputate vestibulum elementum. Suspendisse id neque a nibh mollis blandit. Quisque varius eros ac purus dignissim.
  Quisque varius eros ac purus dignissim. Proin eu ultrices libero. Curabitur vulputate vestibulum elementum. Suspendisse id neque a nibh mollis blandit. Quisque varius eros ac purus dignissim. Curabitur blandit tempus porttitor. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Vestibulum id ligula porta felis euismod semper. Donec id elit non mi porta gravida at eget metus. Vestibulum id ligula porta felis euismod semper.
  Donec posuere bibendum metus. Quisque gravida luctus volutpat. Mauris interdum, lectus in dapibus molestie, quam felis sollicitudin mauris, sit amet tempus velit lectus nec lorem. Nullam vel mollis neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vel enim dui. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Sed tincidunt accumsan massa id viverra. Sed sagittis, nisl sit amet imperdiet convallis, nunc tortor consequat tellus, vel molestie neque nulla non ligula.</p>
                              <blockquote class="font-weight-500">
                                  <i class="fa fa-quote-left font-20"></i>
                                  <p>There is a danger when every building has to look spectacular; to look like it is changing the world. I don't care how a building looks if it means something, not to architects, but to the people who use it. </p>
                                  <div class="p-t15 text-uppercase">
                                      <strong>David Chipperfield</strong>
                                      <span>Envato Author</span>
                                  </div>
                                
                              </blockquote>
                                      <p>Duis vestibulum quis quam vel accumsan. Nunc a vulputate lectus. Vestibulum eleifend nisl sed massa sagittis vestibulum. Vestibulum pretium blandit tellus, sodales volutpat sapien varius vel. Phasellus tristique cursus erat, a placerat tellus laoreet eget. Fusce vitae dui sit amet lacus rutrum convallis. Vivamus sit amet lectus venenatis est rhoncus interdum a vitae velit.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae ipsa quae ab illo inventore dicta sunt explicabo.
                              </p>
                                                  
                          
                          </div>
                      </div>
                      
                      <!-- OUR BLOG START -->
                      <div>
                          <!-- TITLE START -->
                          <ng-template ngFor let-sectionData [ngForOf]="related_posts">
                          <div class="section-head text-left">
                              <h2 class="text-uppercase font-45 font-weight-500 m-b5">{{sectionData.section_title}}</h2>
                          </div>
                          <!-- TITLE END -->  
           
                          <!-- IMAGE CAROUSEL START -->
                          <div class="section-content">
                              <div class="row">
                                  <div class="col-md-6 col-sm-6" *ngFor = "let item of sectionData.items">
                                      <div class="blog-post latest-blog-1 date-style-1">
                                          <div class="wt-post-media wt-img-effect zoom-slow">
                                              <a routerLink="/{{item.read_more_link}}" ><img src="assets/images/blog/latest-blog/{{item.blog_image}}" alt=""></a>
                                          </div>
                                          <div class="wt-post-info  bg-white">
                                          
                                                  <div class="wt-post-meta ">
                                                      <ul>
                                                          <li class="post-author"><i class="fa fa-user"></i><strong>By {{item.post_author}}</strong></li>                                                
                                                          <li class="post-date"><i class="fa fa-calendar"></i><strong>{{item.post_date}} </strong></li>
                                                      </ul>
                                                  </div>                                    
                                                  <div class="wt-post-title ">
                                                      <h4 class="post-title"><a routerLink="/{{item.read_more_link}}" >{{item.post_title}}</a></h4>
                                                  </div>
                                                  <div class="wt-post-readmore">
                                                      <a routerLink="/{{item.read_more_link}}"  class="text-primary">{{item.read_more_text}}</a>
                                                  </div>
                                             
                                          </div>
                                      </div>
                                  </div>
                                  
                              </div>
                          </div>
                          </ng-template>
                      </div>
                      <!-- OUR BLOG END -->   
                      
                      <div class="clear" id="comment-list">
                          <app-comments></app-comments>
                      </div>
                      <!-- BLOG END -->
                  </div>
                  <!-- SIDE BAR START -->
                  <div class="col-md-4 col-sm-12">
                  
                      <aside  class="side-bar">
                          
                              <!-- SEARCH -->
                              <app-search></app-search>
                              
                             <!-- RECENT POSTS -->
                              <app-recent-post [data]="recent_post_section"></app-recent-post>
                                                                  
                              <app-categories></app-categories>                                  
                              <!-- OUR CLIENT -->
                              <app-our-gallery [data]="gallery"></app-our-gallery>                                  

                              <!-- TAGS -->
                              <app-tag></app-tag>
                          
                         </aside>

                  </div>
                  <!-- SIDE BAR END -->                            
              </div>
                                

                  
          </div>
      </div>
      <!-- SECTION CONTENT END -->

  </div>
  <!-- CONTENT END -->
  
  <!-- FOOTER START -->
  <app-footer1></app-footer1>
  <!-- FOOTER END -->
  
  <!-- BUTTON TOP START -->
<app-scroll-to-top></app-scroll-to-top>
  
</div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>