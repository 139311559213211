import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { ModalService } from '../../modal.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

	email:any;
	password:any;
	emsg:any;
	userid:any;
	token:any;
	name:any;
	phone:any;
	cpassword:any;
	itemdata:any;
  	constructor(private myModal: ModalService, private apiService: ApiService,private router: Router,private route: ActivatedRoute) { 

  		this.userid = localStorage.getItem('userid');
  		this.token = localStorage.getItem('token');
  		console.log(this.token);
 		if(this.userid!='' && this.userid!=undefined && this.userid!=null && this.userid!=0) {
 			this.router.navigate(['/cart']);
 		}

  	}

  	ngOnInit(): void {
  	}

  	register() {
  		this.emsg='';
  		if(this.email=='' || this.password=='' || this.name=='') {
  			this.emsg="Please fill in login details";
  		} else {
  			if(this.password!=this.cpassword) {
  				this.emsg="Password and confirm password does not match";
  			} else {
	  			this.emsg='';
	  			this.itemdata = {
	  				"token":this.token,
	  				"email":this.email,
	  				"name":this.name,
	  				"phone":this.phone,
	  				"password":this.password
	  			}
	  			this.apiService.post('register', this.itemdata).then((res: any) => {
		    		if (res.status === 200) {
		    			if(res.reg==1) {
				    			localStorage.setItem('userid',res.id);
				    			localStorage.setItem('token',res.token);
				    			localStorage.setItem('name',res.username);
				    			localStorage.setItem('email',res.email);
				    			this.myModal.myToken(this.token);
				    			this.myModal.myUserid(res.id);
				    			this.router.navigate(['/cart']);
				    		} else {
				    			this.emsg=res.msg;
				    		}
		    		} else {
			          this.emsg='Something went wrong. Please try again.';
			        }
			    });
			}
  		}
  	}

}
