<div class="page-wraper">
    <!-- HEADER START -->
    <app-header1></app-header1>
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">
        <!-- INNER PAGE BANNER -->
        <app-banner1 [data]="banner"></app-banner1>
        <!-- INNER PAGE BANNER END -->
        
  <!-- SECTION CONTENT START -->
        <div class="section-full small-device p-tb80 bg-gray">

            <!-- GALLERY CONTENT START -->
            <div class="container-fluid">
                <!-- GALLERY CONTENT START -->
                 <div class="portfolio-wrap mfp-gallery work-grid row clearfix">
                    <!-- COLUMNS 1 -->
                    <div class="masonry-item col-lg-3  col-md-4 col-sm-6 m-b30"  *ngFor = "let item of news_grid_section">
                        <div class="wt-box work-hover-content">
                            <div class="wt-thum-bx  img-center-icon  wt-img-overlay2">
                                <img src="{{item.blog_image}}" alt="">
                            </div>
                          <div class="wt-info bg-white p-a20">
                                <h4 class="wt-tilte text-uppercase font-weight-600 m-b10 m-t0"><a routerLink="/{{item.read_more_link}}">{{item.post_title}}</a></h4>
                                <p class="m-b0">{{item.title}}</p>      
                            </div>                                 
                        </div>
                                   
                    </div>
                    <!--
                    <div class="masonry-item col-lg-3  col-md-4 col-sm-6 m-b30">
                        <div class="wt-box work-hover-content">
                            <div class="wt-thum-bx img-center-icon  wt-img-overlay2">
                                <img src="assets/images/gallery/pic6.jpg" alt="">
                            </div>
                          <div class="wt-info bg-white p-a20">
                                <h4 class="wt-tilte text-uppercase font-weight-600 m-b10 m-t0"><a href="#">To-Do Dashboard</a></h4>
                                <p class="m-b0">Web Design / Graphic</p>
                            </div>                                   
                        </div>

                    </div>
                    <div class="masonry-item col-lg-3  col-md-4 col-sm-6 m-b30">
                        <div class="wt-box work-hover-content">
                            <div class="wt-thum-bx img-center-icon  wt-img-overlay2">
                                <img src="assets/images/gallery/portrait/pic2.jpg" alt="">
                            </div>
                          <div class="wt-info bg-white p-a20">
                                <h4 class="wt-tilte text-uppercase font-weight-600 m-b10 m-t0"><a href="#">WhereTO App</a></h4>
                                <p class="m-b0">Web Design / Graphic</p>      
                            </div>                                  
                        </div>

                    </div>
                    <div class="masonry-item col-lg-3  col-md-4 col-sm-6 m-b30">
                        <div class="wt-box work-hover-content">
                            <div class="wt-thum-bx img-center-icon  wt-img-overlay2">
                                <img src="assets/images/gallery/pic7.jpg" alt="">
                            </div>
                          <div class="wt-info bg-white p-a20">
                                <h4 class="wt-tilte text-uppercase font-weight-600 m-b10 m-t0"><a href="#">Rooms & Halls</a></h4>
                                <p class="m-b0">Web Design / Graphic</p>      
                            </div>                                
                        </div>

                    </div>
                    <div class="masonry-item col-lg-3  col-md-4 col-sm-6 m-b30">
                      <div class="wt-box work-hover-content">
                            <div class="wt-thum-bx img-center-icon  wt-img-overlay2">
                                <img src="assets/images/gallery/portrait/pic3.jpg" alt="">
                            </div>
                          <div class="wt-info bg-white p-a20">
                                <h4 class="wt-tilte text-uppercase font-weight-600 m-b10 m-t0"><a href="#">Events and More</a></h4>
                                <p class="m-b0">Web Design / Graphic</p>      
                            </div>                                 
                        </div>

                    </div>
                    <div class="masonry-item col-lg-3  col-md-4 col-sm-6 m-b30">
                        <div class="wt-box work-hover-content">
                            <div class="wt-thum-bx  img-center-icon wt-img-overlay2">
                                <img src="assets/images/gallery/pic8.jpg" alt="">
                            </div>
                          <div class="wt-info bg-white p-a20">
                                <h4 class="wt-tilte text-uppercase font-weight-600 m-b10 m-t0"><a href="#">Remind~Me More</a></h4>
                                <p class="m-b0">Web Design / Graphic</p>      
                            </div>                                 
                        </div>

                    </div>
                    <div class="masonry-item col-lg-3  col-md-4 col-sm-6 m-b30">
                        <div class="wt-box work-hover-content">
                            <div class="wt-thum-bx  img-center-icon wt-img-overlay2">
                                <img src="assets/images/gallery/portrait/pic4.jpg" alt="">
                            </div>
                          <div class="wt-info bg-white p-a20">
                                <h4 class="wt-tilte text-uppercase font-weight-600 m-b10 m-t0"><a href="#">Workout Buddy</a></h4>
                                <p class="m-b0">Web Design / Graphic</p>      
                            </div>                                  
                        </div>
                    </div>
                    <div class="masonry-item col-lg-3  col-md-4 col-sm-6 m-b30">
                        <div class="wt-box work-hover-content">
                            <div class="wt-thum-bx img-center-icon  wt-img-overlay2">
                                <img src="assets/images/gallery/pic9.jpg" alt="">
                            </div>
                          <div class="wt-info bg-white p-a20">
                                <h4 class="wt-tilte text-uppercase font-weight-600 m-b10 m-t0"><a href="#">Speed Detector</a></h4>
                                <p class="m-b0">Web Design / Graphic</p>      
                            </div>                                 
                        </div>

                    </div>
                    <div class="masonry-item col-lg-3  col-md-4 col-sm-6 m-b30">
                        <div class="wt-box work-hover-content">
                            <div class="wt-thum-bx img-center-icon  wt-img-overlay2">
                                <img src="assets/images/gallery/portrait/pic5.jpg" alt="">
                            </div>
                          <div class="wt-info bg-white p-a20">
                                <h4 class="wt-tilte text-uppercase font-weight-600 m-b10 m-t0"><a href="#">Generic Apps</a></h4>
                                <p class="m-b0">Web Design / Graphic</p>      
                            </div>                                 
                        </div>

                    </div> 
                    
                    <div class="masonry-item col-lg-3  col-md-4 col-sm-6 m-b30">
                        <div class="wt-box work-hover-content">
                            <div class="wt-thum-bx img-center-icon  wt-img-overlay2">
                                <img src="assets/images/gallery/pic1.jpg" alt="">
                            </div>
                          <div class="wt-info bg-white p-a20">
                                <h4 class="wt-tilte text-uppercase font-weight-600 m-b10 m-t0"><a href="#">Generic Apps</a></h4>
                                <p class="m-b0">Web Design / Graphic</p>      
                            </div>                                 
                        </div>

                    </div>       
                    <div class="masonry-item col-lg-3  col-md-4 col-sm-6 m-b30">
                        <div class="wt-box work-hover-content">
                            <div class="wt-thum-bx img-center-icon  wt-img-overlay2">
                                <img src="assets/images/gallery/pic2.jpg" alt="">
                            </div>
                          <div class="wt-info bg-white p-a20">
                                <h4 class="wt-tilte text-uppercase font-weight-600 m-b10 m-t0"><a href="#">Speed Detector</a></h4>
                                <p class="m-b0">Web Design / Graphic</p>      
                            </div>                                 
                        </div>

                    </div> -->
                                                         
                 </div>
                <!-- GALLERY CONTENT END -->  
            </div>
            <!-- GALLERY CONTENT END -->
        
        </div>
        <!-- SECTION CONTENT END  -->

    </div>
    <!-- CONTENT END -->        	

    <!-- FOOTER START -->
    <app-footer1></app-footer1>
    <!-- FOOTER END -->
    
    <!-- BUTTON TOP START -->
<app-scroll-to-top></app-scroll-to-top>

</div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>