<div class="page-wraper"> 
      
      	<app-header2></app-header2>
      
      	<div class="container">
	      <!-- CONTENT START -->
	      <div class="page-content">        

            <div class="section-head text-center">
                <h2 class="text-uppercase  font-weight-900 m-b5 text-left">
                	Grievance
                </h2>
                <a href="javascript:void(0)" *ngIf="list==0" (click)="add()" class="site-button-secondry button-sm site-btn-effect" style="float: right;margin-right:10px;"><span>Register Grievance </span></a>
                <a href="javascript:void(0)" *ngIf="list==1" (click)="cancel()" class="site-button-secondry button-sm site-btn-effect" style="float: right;margin-right:10px;"><span>Show All </span></a>
                <div class="wt-separator-outer text-left">
                    <div class="wt-separator bg-primary"></div>
                </div>
            </div>

   			<div class="section-content clearfix">
		        <div class="row  no-col-gap bg-primary" *ngIf="list==0">
	            	<ng-container *ngIf="orderlist.length>0">
	            		<div class="col-md-12 col-sm-12 col-xs-12 col-xs-100pc bg-white headrow">
			                <div class="wt-icon-box-wraper center p-lr30  p-b0 p-t10 bdr-1 bdr-solid bdr-gray-light ">
			                    <div class="row poojas">
			                    	<div class="col-md-7">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10 p-l10 text-left">Title</h4>
			                        </div>
			                        <div class="col-md-2">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">Date</h4>
			                        </div>
			                        <div class="col-md-2">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">Status</h4>
			                        </div>
			                        <div class="col-md-1">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10"></h4>
			                        </div>
			                    </div>
			                </div>
			            </div> 
			            <div class="col-md-12 col-sm-12 col-xs-12 col-xs-100pc bg-white"  *ngFor="let item of orderlist">
			                <div class="wt-icon-box-wraper center p-lr30  p-b0 p-t10 bdr-1 bdr-solid bdr-gray-light ">
			                    <div class="row poojas" >
			                    	<div class="col-md-7">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10 p-l10 text-left">
				                        	{{item.title}}
				                        </h4>
			                        </div>
			                        <div class="col-md-2">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">{{ item.entry_date | date }}</h4>
			                        </div>
			                        <div class="col-md-2">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">
			                        		<span *ngIf="item.status==0">Pending</span>
			                        		<span *ngIf="item.status==1">Processing</span>
			                        		<span *ngIf="item.status==2">Resolved</span>
			                        	</h4>
			                        </div>
			                        <div class="col-md-1">
			                        	<a href="javascript:void(0)" (click)="viewgrievance(item.id)" class="site-button-secondry button-sm site-btn-effect" ><span>View</span></a>
			                        </div>
			                    </div>
			                </div>
			            </div> 
		            </ng-container>  
		            <div *ngIf="orderlist.length==0" style="padding:50px 0;text-align:center;background:#fff;">
		            	<h4 class="text-center">-- No Grievance registered yet --</h4>
		            </div>                     		                                                                
	          	</div>	

	          	<div class="row  no-col-gap" *ngIf="list==1">
	          		<div class="col-md-12 col-sm-12 col-xs-12 col-xs-100pc bg-white">
		          		
	          			<div style="padding:20px 0;text-align:center;background:#fff;width:90%;margin:0 auto;">
			            	<div class="ramForm">

			            		<h3 class="text-center p-b15">Add new Grievance</h3>
				            	<div class="row">			            		

							        <div class="col-md-12 p-t5 p-r5 p-b5 p-l5">
								        <div class="form-group">
								            <input name="title" type="text" required="" class="form-control"  (input)="title=$event.target.value" placeholder="Grievance Title">
								        </div>
								    </div>

								    <div class="col-md-12 p-t5 p-r5 p-b5 p-l5">
								        <div class="form-group">
								            <textarea name="description" type="text" required="" class="form-control"  (input)="description=$event.target.value" placeholder="Type in Grievance Details" style="    min-height: 150px;"></textarea>
								        </div>
								    </div>
								    

								</div>
						        <p class="error" *ngIf="message!=''">{{message}}</p>

						        <a href="javascript:void(0)" (click)="change()" class="site-button-secondry button-sm site-btn-effect" ><span>Register Grievance</span></a>

						        <a href="javascript:void(0)" (click)="cancel()" class="site-button-secondry button-sm site-btn-effect" style="margin-left: 10px;"><span>Cancel</span></a>

						    </div>
			            </div>

		          	</div>
	          	</div>

	          	<div class="row  no-col-gap" *ngIf="list==2">
	          		<div class="col-md-12 col-sm-12 col-xs-12 col-xs-100pc bg-white">
		          		
	          			<div style="padding:20px 0;text-align:center;background:#fff;width:90%;margin:0 auto;">
			            	<div class="ramForm">

			            		<h3 class="text-center p-b15">Grievance Details
			            			<a href="javascript:void(0)" (click)="cancel()" class="site-button-secondry button-sm site-btn-effect" style="margin-left: 10px;float:right;"><span>View All</span></a>
			            		</h3>
				            	<div class="row" >			            		

							        <div *ngFor="let item of gview">
							        	<h3 class="title">{{item.title}}</h3>
							        	<div class="info">{{item.entry_date | date}}</div>
							        	<div class="description">{{item.description}}</div>

							        	<div class="status">
							        		Status: <span *ngIf="item.status==0">Pending</span>
			                        		<span *ngIf="item.status==1">Processing</span>
			                        		<span *ngIf="item.status==2">Resolved</span>
							        	</div>

							        </div>
								    

							        <div *ngFor="let item of gremarks">
							        	<div class="remarks">{{item.remark}}</div>
							        	<div class="rinfo">By {{item.customer}} on {{item.entry_date | date}} | Stauts - <span *ngIf="item.status==0">Pending</span>
			                        		<span *ngIf="item.status==1">Processing</span>
			                        		<span *ngIf="item.status==2">Resolved</span></div>

			                        	

							        </div>

							        <div class="col-md-12 p-t5 p-r5 p-b5 p-l5 text-left" *ngIf="gstatus<2">
								        <div class="form-group">
								            <textarea name="rdescription" type="text" required="" class="form-control"  (input)="rdescription=$event.target.value" placeholder="Type in your response.." style="    min-height: 150px;"></textarea>
								        </div>
								    </div>

								    <p class="error" *ngIf="emsg!=''">{{emsg}}</p>

								    <a href="javascript:void(0)" (click)="reply()" class="site-button-secondry button-sm site-btn-effect"  *ngIf="gstatus<2"><span>Submit Reply</span></a>

					        		<a href="javascript:void(0)" (click)="cancel()" class="site-button-secondry button-sm site-btn-effect" style="margin-left: 10px;"><span>Show All</span></a>
								</div>


						        

						    </div>
			            </div>

		          	</div>
	          	</div>			        
		    </div>

	      </div>
	   	</div>

      	<app-footer1></app-footer1>

  <app-scroll-to-top></app-scroll-to-top>
      
   
  </div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>