<div class="page-wraper">  
    
    <!-- HEADER START -->
    <app-header1></app-header1>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">
    
        <!-- INNER PAGE BANNER -->
        <app-banner1 [data]="banner"></app-banner1>
        <!-- INNER PAGE BANNER END -->
         
        <!-- ACCORDIAN START -->
        <div class="section-full small-device  p-t80 p-b50">
            <!-- LOCATION BLOCK-->
            <div class="container">
              <div class="row">
                    <div class="col-md-6">
                        <!-- TITLE START -->
                        <div class="section-head text-left">
                            <h2 class="text-uppercase  font-weight-900 m-b5">Accordion bg dark</h2>
                            <div class="wt-separator-outer">
                                <div class="wt-separator bg-primary"></div>
                            </div>
                        </div>
                        <!-- TITLE END -->
                        <!-- ACCORDIAN  BG DARK -->
                        <div class="section-content p-b30">
                            <div class="wt-accordion acc-bg-dark" id="accordion9">
                                <div class="panel wt-panel">
                                    <div class="acod-head acc-actives">
                                         <h6 class="acod-title">
                                            <a data-toggle="collapse" href="#collapseOne9" data-parent="#accordion9" ><i class="fa fa-globe"></i>
                                                Awesome Web Design
                                                <span class="indicator"><i class="fa fa-plus"></i></span>
                                            </a>
                                         </h6>
                                    </div>
                                    <div id="collapseOne9" class="acod-body collapse in">
                                        <div class="acod-content p-tb15">Web design lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</div>
                                    </div>
                                </div>
                                <div class="panel wt-panel">
                                    <div class="acod-head">
                                         <h6 class="acod-title">
                                            <a data-toggle="collapse" href="#collapseTwo9" class="collapsed" data-parent="#accordion9" ><i class="fa fa-photo"></i>
                                            Different Graphic Design 
                                            <span class="indicator"><i class="fa fa-plus"></i></span>
                                            </a>
                                         </h6>
                                    </div>
                                    <div id="collapseTwo9" class="acod-body collapse">
                                        <div class="acod-content p-tb15">Graphic design lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took.</div>
                                    </div>
                                </div>
                                <div class="panel wt-panel">
                                    <div class="acod-head">
                                     <h6 class="acod-title">
                                        <a data-toggle="collapse"  href="#collapseThree9" class="collapsed"  data-parent="#accordion9"><i class="fa fa-cog"></i>
                                        Fast Developement
                                        <span class="indicator"><i class="fa fa-plus"></i></span>
                                        </a>
                                     </h6>
                                    </div>
                                    <div id="collapseThree9" class="acod-body collapse">
                                        <div class="acod-content p-tb15">Developement lorem Ipsum is simply dummy text of the printing and type has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make.</div>
                                    </div>
                                </div>
                            </div>                                        
                        </div>                        
                    </div>
                    <div class="col-md-6">
                            <!-- TITLE START -->
                            <div class="section-head text-left">
                                <h2 class="text-uppercase font-weight-900 m-b5">Accordion bg gray</h2>
                                <div class="wt-separator-outer">
                                    <div class="wt-separator bg-primary"></div>
                                </div>
                            </div>
                            <!-- TITLE END -->
                            
                            <!-- ACCORDIAN  BACKGROUND GRAY -->                    
                            <div class="section-content p-b30">
                                <div class="wt-accordion acc-bg-gray" id="accordion5">
                                    <div class="panel wt-panel">
                                        <div class="acod-head acc-actives">
                                             <h6 class="acod-title">
                                                <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5" >
                                                    Awesome Web Design
                                                    <span class="indicator"><i class="fa"></i></span>
                                                </a>
                                             </h6>
                                        </div>
                                        <div id="collapseOne5" class="acod-body collapse in">
                                            <div class="acod-content p-tb15">Web design lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</div>
                                        </div>
                                    </div>
                                    <div class="panel wt-panel">
                                        <div class="acod-head">
                                             <h6 class="acod-title">
                                                <a data-toggle="collapse" href="#collapseTwo5" class="collapsed" data-parent="#accordion5" >
                                                Different Graphic Design 
                                                <span class="indicator"><i class="fa"></i></span>
                                                </a>
                                             </h6>
                                        </div>
                                        <div id="collapseTwo5" class="acod-body collapse">
                                            <div class="acod-content p-tb15">Graphic design lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took.</div>
                                        </div>
                                    </div>
                                    <div class="panel wt-panel">
                                        <div class="acod-head">
                                            <h6 class="acod-title">
                                            <a data-toggle="collapse"  href="#collapseThree5" class="collapsed"  data-parent="#accordion5">
                                            Fast Developement
                                            <span class="indicator"><i class="fa"></i></span>
                                            </a>
                                         </h6>
                                        </div>
                                        <div id="collapseThree5" class="acod-body collapse">
                                            <div class="acod-content p-tb15">Developement lorem Ipsum is simply dummy text of the printing and type has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make.</div>
                                        </div>
                                    </div>
                                </div>                                        
                           </div>
                      
                    </div>
                </div>    
            </div>
       </div>
        <!-- ACCORDIAN END -->
        
        <!-- BUTTON START -->
        <div class="section-full small-device  p-t80 p-b50 bg-gray">
            <!-- LOCATION BLOCK-->
            <div class="container">
                <div class="m-b50">   
                    <!-- TITLE START -->
                    <div class="section-head">
                        <h2 class="text-uppercase font-weight-900 m-b5">Site Button</h2>
                        <div class="wt-separator-outer">
                            <div class="wt-separator bg-primary"></div>
                        </div>
                    </div>
                    <!-- TITLE END -->

                    <div class="section-content">
                        <button class="m-b15 site-button site-btn-effect m-r15 text-uppercase letter-spacing-2" type="button"><span> Pramary</span></button>
                        <button class="m-b15 site-button-secondry site-btn-effect  text-uppercase letter-spacing-2" type="button"><span> secondry</span></button>
                    </div>
                    
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="m-b30">   
                        <!-- TITLE START -->
                        <div class="section-head">
                            <h2 class="text-uppercase font-weight-900 m-b5">Border Button</h2>
                            <div class="wt-separator-outer">
                                <div class="wt-separator bg-primary"></div>
                            </div>
                        </div>
                        <!-- TITLE END -->
                        
                        <div class="section-content">
                            <a href="javascript:void(0);" class="site-button site-btn-effect outline  black button-app m-r15 m-b15"><span>Download on the <strong class="text-center">Google play</strong></span></a>
                            <a href="javascript:void(0);" class="site-button site-btn-effect outline  black button-app m-r15 m-b15"><span>Download on the <strong class="text-center">App Store</strong></span></a>
                        </div>
                        
                    </div> 
                    </div>
                    <div class="col-md-6">
                        <div class="m-b30">   
                        <!-- TITLE START -->
                        <div class="section-head">
                            <h2 class="text-uppercase font-weight-900 m-b5">Site button different size</h2>
                            <div class="wt-separator-outer">
                                <div class="wt-separator bg-primary"></div>
                            </div>
                        </div>
                        <!-- TITLE END -->
                        <button class="m-b15 site-button site-btn-effect button-xl m-r15" type="button"><span> Extra Large</span></button>
                        <button class="m-b15 site-button site-btn-effect button-lg m-r15" type="button"><span>Large</span></button>
                        <button class="m-b15 site-button site-btn-effect m-r15" type="button"><span>Medium</span></button>
                        <button class="m-b15 site-button site-btn-effect button-sm m-r15" type="button"><span>Samll</span></button>
                    </div>                   
                    </div>
                </div>         
            </div>
       </div> 
       
        <!-- LIST GROUP START -->
        <div class="section-full small-device  p-t80 p-b50">
            <!-- LOCATION BLOCK-->
            <div class="container">
                <!-- SECTION CONTENT -->
                <div class="m-b30">
                    <!-- TITLE START -->
                    <div class="section-head">
                        <h2 class="text-uppercase font-weight-900 m-b5"> list Numbering &amp; Alfha</h2>
                        <div class="wt-separator-outer">
                            <div class="wt-separator bg-primary"></div>
                        </div>
                    </div>
                    <!-- TITLE END -->                                      
                    <!-- LISTING WITH NUMBERING & ALPHA --> 
                    <div class="section-content">
                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <ol class="list-num-count upper-alpha list-outline-none">
                                    <li>Dummy text is also used to demonstrate the appearance</li>
                                    <li>Dummy text is also used to demonstrate the appearance</li>
                                </ol>
                                <ol class="list-num-count upper-alpha list-outline">
                                    <li>Dummy text is also used to demonstrate the appearance</li>
                                    <li>Dummy text is also used to demonstrate the appearance</li>
                                </ol>                                                    
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <ol class="list-num-count">
                                    <li>Dummy text is also used to demonstrate the appearance</li>
                                    <li>Dummy text is also used to demonstrate the appearance</li>
                                </ol>
                                <ol class="list-num-count lower-alpha">
                                    <li>Dummy text is also used to demonstrate the appearance</li>
                                    <li>Dummy text is also used to demonstrate the appearance</li>
                                </ol>                                                    
                            </div>
                        </div>
                    </div>
                                                        
                </div>
                
                <!-- SECTION CONTENT END -->
             </div>                                       
        </div> 
                   
        <!-- ICON BOX START -->
        <div class="section-full small-device  p-t80 p-b50 bg-gray">
            <!-- LOCATION BLOCK-->
            <div class="container">
            
                <!-- TITLE START -->
                <div class="section-head">
                <h2 class="text-uppercase font-weight-900 m-b5">Icon Box Style</h2>
                <div class="wt-separator-outer">
                    <div class="wt-separator bg-primary"></div>
                </div>
                </div>
                <!-- TITLE END -->
                                 
                <!-- ICON BOX -->
                <div class="section-content m-b50">
                
                    <div class="row">
                        <div class="col-md-3">
                            <div class="wt-icon-box-wraper center p-lr20  p-tb50 bdr-1 bdr-solid bdr-gray-light hover-border-outer hover-border bg-white">
                                <div class="icon-lg text-primary m-b15">
                                    <span class="icon-cell text-primary"><i class="flaticon-decorating"></i></span>
                                </div>
                                <div class="icon-content">
                                    <h4 class="wt-tilte text-uppercase font-weight-600 m-b20">Interior</h4>
                                    <p>There are many variations of pass ages of Lorem Ipsum  available.</p>
                                </div>
                            </div>
                        </div>                          
                        <div class="col-md-3">
                            <div class="wt-icon-box-wraper center p-lr20  p-tb50 bdr-1 bdr-solid bdr-gray-light hover-border-outer hover-border bg-white">
                                <div class="icon-lg text-primary m-b15">
                                    <span class="icon-cell text-primary"><i class="flaticon-skyline"></i></span>
                                </div>
                                <div class="icon-content">
                                    <h4 class="wt-tilte text-uppercase font-weight-600 m-b20">Architecture</h4>
                                    <p>There are many variations of pass ages of Lorem Ipsum available.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="wt-icon-box-wraper center p-lr20  p-tb50 bdr-1 bdr-solid bdr-gray-light hover-border-outer hover-border bg-white">
                                <div class="icon-lg text-primary m-b15">
                                    <span class="icon-cell text-primary"><i class="flaticon-sketch "></i></span>
                                </div>
                                <div class="icon-content">
                                    <h4 class="wt-tilte text-uppercase font-weight-600 m-b20">Planing</h4>
                                    <p>There are many variations of pass ages of Lorem Ipsum available.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="wt-icon-box-wraper center p-lr20  p-tb50 bdr-1 bdr-solid bdr-gray-light hover-border-outer hover-border bg-white">
                                <div class="icon-lg text-primary m-b15">
                                    <span class="icon-cell text-primary"><i class="flaticon-crane"></i></span>
                                </div>
                                <div class="icon-content">
                                    <h4 class="wt-tilte text-uppercase font-weight-600 m-b20">Construction</h4>
                                    <p>There are many variations of pass ages of Lorem Ipsum available.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                                    
                <!-- ICON BOX -->
                <div class="section-content">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="wt-icon-box-wraper bg-white m-b30 p-a30">
                                <div class="icon-lg inline-icon m-b50 text-left">
                                    <span class="icon-cell text-primary"><i class="fa flaticon-trophy"></i></span>
                                </div>
                                <div class="icon-content bold-title">
                                    <h4 data-title="01" class="wt-tilte text-uppercase font-weight-600 m-b20" >Consecutive Award winning team</h4>
                                    <p>Lorem Ipsum is simply dummy text of the printing and type setting.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="wt-icon-box-wraper bg-white m-b30  p-a30">
                                <div class="icon-lg inline-icon m-b50 text-left">
                                    <span class="icon-cell text-primary"><i class="fa flaticon-sketching"></i></span>
                                </div>
                                <div class="icon-content bold-title">
                                    <h4  data-title="02" class="wt-tilte text-uppercase font-weight-600 m-b20">we are creative designers</h4>
                                    <p>Lorem Ipsum is simply dummy text of the printing and type setting.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="wt-icon-box-wraper bg-white m-b30  p-a30">
                                <div class="icon-lg inline-icon m-b50 text-left">
                                    <span class="icon-cell text-primary"><i class="fa flaticon-diamond"></i></span>
                                </div>
                                <div class="icon-content bold-title">
                                    <h4 data-title="03" class="wt-tilte text-uppercase font-weight-600 m-b20">heigh quality products only used</h4>
                                    <p>Lorem Ipsum is simply dummy text of the printing and type setting.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="wt-icon-box-wraper  bg-white m-b30  p-a30">
                                <div class="icon-lg inline-icon m-b50 text-left">
                                    <span class="icon-cell text-primary"><i class="fa flaticon-support"></i></span>
                                </div>
                                <div class="icon-content bold-title">
                                    <h4 data-title="04" class="wt-tilte text-uppercase font-weight-600 m-b20">24/7 customer support</h4>
                                    <p>Lorem Ipsum is simply dummy text of the printing and type setting.</p>
                                </div>
                            </div>
                        </div>                                                                                                            
                    </div>
                 </div>

            </div>                                       
       </div>
        <!-- ICON BOX END -->
        
       <!-- MODAL POPUP START -->
        <div class="section-full small-device  p-t80 p-b50">
            <div class="container">
              <div class="section-content">
                             <!-- TITLE START -->
                            <div class="section-head">
                                <h2 class="text-uppercase font-weight-900 m-b5"> Modal Popup</h2>
                                <div class="wt-separator-outer">
                                    <div class="wt-separator bg-primary"></div>
                                </div>
                            </div>
                            <!-- TITLE END -->                              
              <div class="button-model">
                                    <!-- TRIGGER THE MODAL WITH A BUTTON -->
                                    <button type="button" class="site-button site-btn-effect outline  black button-app m-b15 etter-spacing-2 font-weight-800" data-toggle="modal" data-target="#Default-Modal"><span> Default</span></button>
                                    <!-- MODAL -->
                                    <div id="Default-Modal" class="modal fade" role="dialog">
                                      <div class="modal-dialog">
                                        <!-- MODAL CONTENT-->
                                        <div class="modal-content">
                                          <div class="modal-header bg-secondry">
                                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                                            <h4 class="modal-title text-black">Modal Header</h4>
                                          </div>
                                          <div class="modal-body">
                                            <p>Some text in the modal.</p>
                                          </div>
                                          <div class="modal-footer">
                                            <button type="button" class="site-button site-btn-effect button-sm text-uppercase letter-spacing-2" data-dismiss="modal"><span> Close</span></button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>

              <div class="button-model">
                                    <!-- TRIGGER THE MODAL WITH A BUTTON -->
                                    <button type="button" class="site-button site-btn-effect outline  black button-app m-b15 etter-spacing-2 font-weight-800" data-toggle="modal" data-target="#Small-Modal"><span> Small</span></button>
                                    <!-- MODAL -->
                                    <div id="Small-Modal" class="modal fade" role="dialog">
                                      <div class="modal-dialog modal-sm">
                                        <!-- MODAL CONTENT -->
                                        <div class="modal-content">
                                          <div class="modal-header bg-secondry">
                                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                                            <h4 class="modal-title text-black">Modal Header</h4>
                                          </div>
                                          <div class="modal-body">
                                            <p>Some text in the modal.</p>
                                          </div>
                                          <div class="modal-footer">
                                            <button type="button" class="site-button site-btn-effect button-sm text-uppercase letter-spacing-2" data-dismiss="modal"><span>Close</span></button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
              
                                <div class="button-model">
                                <!-- TRIGGER THE MODAL WITH A BUTTON -->
                                <button type="button" class="site-button site-btn-effect outline  black button-app m-b15 etter-spacing-2 font-weight-800" data-toggle="modal" data-target="#large-Modal"><span> Large</span></button>
                                <!-- MODAL -->
                                <div id="large-Modal" class="modal fade" role="dialog">
                                  <div class="modal-dialog modal-lg">
                                    <!-- MODAL CONTENT -->
                                    <div class="modal-content">
                                      <div class="modal-header bg-secondry">
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                        <h4 class="modal-title text-black">Modal Header</h4>
                                      </div>
                                      <div class="modal-body">
                                        <p>Some text in the modal.</p>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="site-button site-btn-effect button-sm text-uppercase letter-spacing-2" data-dismiss="modal"><span> Close</span></button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                </div>
              
                                <div class="button-model">
                                <!-- TRIGGER THE MODAL WITH A BUTTON -->
                                 <button type="button" class="site-button site-btn-effect outline  black button-app m-b15 etter-spacing-2 font-weight-800" data-toggle="modal" data-target="#with-form"><span> With form</span></button>
                                <!-- MODAL -->
                                <div id="with-form" class="modal fade" role="dialog">
                                  <div class="modal-dialog">
                                    <!-- Modal content-->
                                    <div class="modal-content">
                                      <div class="modal-header bg-secondry">
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                        <h4 class="modal-title text-black">Modal Header</h4>
                                      </div>
                                      <div class="modal-body">
                                        <form id="demo-form" class="form-horizontal mb-lg" novalidate>
                                            <div class="form-group mt-lg">
                                                <label class="col-sm-3 control-label">Name</label>
                                                <div class="col-sm-9">
                                                    <input name="name" class="form-control" placeholder="Type your name..." required type="text">
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-sm-3 control-label">Email</label>
                                                <div class="col-sm-9">
                                                    <input name="email" class="form-control" placeholder="Type your email..." required type="email">
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-sm-3 control-label">URL</label>
                                                <div class="col-sm-9">
                                                    <input name="url" class="form-control" placeholder="Type an URL..." type="url">
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-sm-3 control-label">Comment</label>
                                                <div class="col-sm-9">
                                                <textarea rows="5" class="form-control" placeholder="Type your comment..." required></textarea>
                                                </div>
                                            </div>
                                        </form>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="site-button site-btn-effect text-uppercase button-sm letter-spacing-2 m-r15" data-dismiss="modal"><span> Close</span></button>
                                        <button type="button" class="site-button site-btn-effect text-uppercase button-sm letter-spacing-2"><span> Save Changes </span></button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                </div>
                           
                        </div>
            </div>
       </div>
        <!-- MODAL POPUP END -->  
       
        <!-- TABS SECTION START -->
        <div class="section-full small-device  p-t80 p-b50 bg-gray">
            <!-- LOCATION BLOCK-->
            <div class="container">
                <div class="section-content">
                    <div class="row">
                    
                        <div class="col-md-6">                            
                            <div class="m-b30"> 
                                <!-- TITLE START -->
                                <div class="section-head">
                                    <h2 class="text-uppercase font-weight-900 m-b5">Tabs Default</h2>
                                    <div class="wt-separator-outer">
                                        <div class="wt-separator bg-primary"></div>
                                    </div>
                                </div>
                                <!-- TITLE END -->
                                
                                <!-- TAB DEFAULT -->
                                <div class="section-content">
                                    <div class="wt-tabs tabs-default">
                                        <ul class="nav nav-tabs">
                                            <li class="active"><a data-toggle="tab" href="#web-design-1">Web design</a></li>
                                            <li><a data-toggle="tab" href="#graphic-design-1">Graphic Design</a></li>
                                            <li><a data-toggle="tab" href="#developement-1">Developement</a></li>
                                        </ul>
                                        <div class="tab-content">
                                        
                                            <div id="web-design-1" class="tab-pane active">
                                                <p class="m-b0"><strong>Web design lorem ipsum dolor sit amet, consectetuer adipiscing elit.</strong><br> Suspendisse et justo. 
                                                Praesent mattis commyolk augue Aliquam ornare hendrerit augue Cras tellus In pulvinar lectus a est Curabitur eget orci Cras laoreet. 
                                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis 
                                                commyolk augue aliquam ornare augue.</p>
                                            </div>
                                            
                                            <div id="graphic-design-1" class="tab-pane">
                                                <p class="m-b0"><strong>Graphic Design lorem ipsum dolor sit amet, consectetuer adipiscing elit.</strong><br> Praesent Suspendisse 
                                                et justo. mattis commyolk augue Aliquam ornare hendrerit augue Cras tellus In pulvinar lectus a est Curabitur eget orci Cras laoreet. 
                                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis 
                                                commyolk augue aliquam ornare augue.</p>

                                            </div>
                                            
                                            <div id="developement-1" class="tab-pane">
                                                <p class="m-b0"><strong>Developement lorem ipsum dolor sit amet, consectetuer adipiscing elit.</strong><br>Commyolk Suspendisse 
                                                et justo. Praesent mattis  augue Aliquam ornare hendrerit augue Cras tellus In pulvinar lectus a est Curabitur eget orci Cras laoreet. 
                                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis 
                                                commyolk augue aliquam ornare augue.</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        
                        <div class="col-md-6">                            
                            <div class="m-b30"> 
                                <!-- TITLE START -->
                                <div class="section-head">
                                    <h2 class="text-uppercase font-weight-900 m-b5">Tabs Default nav left</h2>
                                    <div class="wt-separator-outer">
                                        <div class="wt-separator bg-primary"></div>
                                    </div>
                                </div>
                                <!-- TITLE END -->
                                
                                <!-- TAB DEFAULT -->
                                <div class="wt-tabs vertical tabs-default">
                                
                                    <ul class="nav nav-tabs">
                                        <li class="active"><a data-toggle="tab" href="#web-design-3">Web design</a></li>
                                        <li><a data-toggle="tab" href="#graphic-design-3">Graphic Design</a></li>
                                        <li><a data-toggle="tab" href="#developement-3">Developement</a></li>
                                    </ul>
                                
                                    <div class="tab-content">
                                    
                                        <div id="web-design-3" class="tab-pane active">
                                            <p class="m-b0"><strong>Web design lorem ipsum dolor sit amet, consectetuer adipiscing elit.</strong><br> Suspendisse et justo. 
                                            Praesent mattis commyolk augue Aliquam ornare hendrerit augue Cras tellus In pulvinar lectus a est Curabitur eget orci Cras laoreet. 
                                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis 
                                            commyolk augue aliquam ornare augue.</p>
                                        </div>
                                        
                                        <div id="graphic-design-3" class="tab-pane">
                                            <p class="m-b0"><strong>Graphic Design lorem ipsum dolor sit amet, consectetuer adipiscing elit.</strong><br> Praesent Suspendisse 
                                            et justo. mattis commyolk augue Aliquam ornare hendrerit augue Cras tellus In pulvinar lectus a est Curabitur eget orci Cras laoreet. 
                                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis 
                                            commyolk augue aliquam ornare augue.</p>
                                        </div>
                                        
                                        <div id="developement-3" class="tab-pane">
                                            <p class="m-b0"><strong>Developement lorem ipsum dolor sit amet, consectetuer adipiscing elit.</strong><br>Commyolk Suspendisse 
                                            et justo. Praesent mattis  augue Aliquam ornare hendrerit augue Cras tellus In pulvinar lectus a est Curabitur eget orci Cras laoreet. 
                                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis 
                                            commyolk augue aliquam ornare augue.</p>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            </div> 
                        </div>                        
                    </div>                                                       
                </div>
            </div>
       </div>
        <!-- TABS SECTION END -->           

        <!-- VIDEO CONTENTG START -->
        <div class="section-full small-device  p-t80 p-b50">
            <!-- LOCATION BLOCK-->
            <div class="container">
                <div class="section-content">
                    <div class="wt-box">
                      <div class="row">
                          <div class="col-md-6">
                                <!-- YOUTUBE VIDEO -->
                                <div class="m-b30">
                                    <!-- TITLE START -->
                                    <div class="section-head">
                                        <h2 class="text-uppercase font-weight-900 m-b5">Video Youtube</h2>
                                        <div class="wt-separator-outer">
                                            <div class="wt-separator bg-primary"></div>
                                        </div>
                                    </div>
                                    <!-- TITLE END -->                                            
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/7n6cpvIFdfY"></iframe>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <!-- VIMEO VIDEO -->
                                <div class="m-b30">
                                    <!-- TITLE START -->
                                    <div class="section-head">
                                        <h2 class="text-uppercase font-weight-900 m-b5">Video Vimeo</h2>
                                        <div class="wt-separator-outer">
                                            <div class="wt-separator bg-primary"></div>
                                        </div>
                                    </div>
                                    <!-- TITLE END -->                                        
                                    
                                    <iframe src="https://player.vimeo.com/video/2215313" width="560" height="315" ></iframe>
                                    
                                </div>
                            </div>
                            <div class="col-md-6">
                                <!-- YOUTUBE VIDEO WITH POPUP -->
                                <div class="m-b30">
                                    <!-- TITLE START -->
                                    <div class="section-head">
                                        <h2 class="text-uppercase font-weight-900 m-b5">Video Youtube with popup</h2>
                                        <div class="wt-separator-outer">
                                            <div class="wt-separator bg-primary"></div>
                                        </div>
                                    </div>
                                    <!-- TITLE END -->                                        
                                    <div class="wt-box">
                                        <div class="wt-thum-bx wt-img-overlay1 wt-img-effect zoom yt-thum-box">
                                            <img src="http://img.youtube.com/vi/7n6cpvIFdfY/0.jpg" alt="">
                                            <a class="mfp-video video-play-btn" href="https://www.youtube.com/watch?v=7n6cpvIFdfY">
                                                <i class="fa fa-play"></i>
                                            </a>                                                
                                            <div class="overlay-main bg-black opacity-07"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <!-- VIMEO VIDEO WITH POPUP -->
                                <div class="m-b30">
                                    <!-- TITLE START -->
                                    <div class="section-head">
                                        <h2 class="text-uppercase font-weight-900 m-b5">Video Vimeo with popup</h2>
                                        <div class="wt-separator-outer">
                                            <div class="wt-separator bg-primary"></div>
                                        </div>
                                    </div>
                                    <!-- TITLE END -->                                          
                                    <div class="wt-box">
                                        <div class="wt-thum-bx overlay-wraper">
                                            <img src="https://i.vimeocdn.com/video/239492174.jpg" alt="">
                                            <a class="mfp-video video-play-btn" href="https://player.vimeo.com/video/34741214?color=ffffff&title=0&byline=0&portrait=0">
                                                <i class="fa fa-play"></i>
                                            </a>
                                            <div class="overlay-main bg-black opacity-07"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
               </div>
           </div>    
       </div>
        <!-- VIDEO CONTENTG END -->  
        
        <!-- ICON FOTS START -->
        <div class="section-full small-device  p-t80 p-b50  bg-gray">
            <!-- LOCATION BLOCK-->
            <div class="container">
            
                <!-- TITLE START -->
                <div class="section-head">
                    <h2 class="text-uppercase font-weight-900 m-b5">Icon fonts</h2>
                    <div class="wt-separator-outer">
                        <div class="wt-separator bg-primary"></div>
                    </div>
                </div>
                <!-- TITLE END -->                
                <div class="section-content">
                    <div class="wt-box">
          <div class="icon-font row">
                            <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-time-passing"></div>
                                    <div class="class-name">.flaticon-time-passing</div>
                                </div>
                                </div>        
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-smartphone"></div>
                                    <div class="class-name">.flaticon-smartphone</div>
                                </div>        
                                </div> 
                                  
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-hotel"></div>
                                    <div class="class-name">.flaticon-hotel</div>
                                </div>        
                                </div> 
                                  
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-sketching"></div>
                                    <div class="class-name">.flaticon-sketching</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-trophy"></div>
                                    <div class="class-name">.flaticon-trophy</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-support"></div>
                                    <div class="class-name">.flaticon-support</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-diamond"></div>
                                    <div class="class-name">.flaticon-diamond</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-paint-brushes"></div>
                                    <div class="class-name">.flaticon-paint-brushes</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-decorating"></div>
                                    <div class="class-name">.flaticon-decorating</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-skyline"></div>
                                    <div class="class-name">.flaticon-skyline</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-factory"></div>
                                    <div class="class-name">.flaticon-factory</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-architect-with-helmet"></div>
                                    <div class="class-name">.flaticon-architect-with-helmet</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-tools"></div>
                                    <div class="class-name">.flaticon-tools</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-crane"></div>
                                    <div class="class-name">.flaticon-crane</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-drawing"></div>
                                    <div class="class-name">.flaticon-drawing</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-wall"></div>
                                    <div class="class-name">.flaticon-wall</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-pipe"></div>
                                    <div class="class-name">.flaticon-pipe</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-plumber-working"></div>
                                    <div class="class-name">.flaticon-plumber-working</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-pipes"></div>
                                    <div class="class-name">.flaticon-pipes</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-paint-roller"></div>
                                    <div class="class-name">.flaticon-paint-roller</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-drawing-architecture-project-of-a-house"></div>
                                    <div class="class-name">.flaticon-drawing-architecture-project-of-a-house</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-construction-worker"></div>
                                    <div class="class-name">.flaticon-construction-worker</div>
                                </div>        
                                </div>   
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-welder"></div>
                                    <div class="class-name">.flaticon-welder</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-builder"></div>
                                    <div class="class-name">.flaticon-builder</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-road"></div>
                                    <div class="class-name">.flaticon-road</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-modern-house"></div>
                                    <div class="class-name">.flaticon-modern-house</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-barrier"></div>
                                    <div class="class-name">.flaticon-barrier</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-interior-design"></div>
                                    <div class="class-name">.flaticon-interior-design</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-sketch"></div>
                                    <div class="class-name">.flaticon-sketch</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-helmet"></div>
                                    <div class="class-name">.flaticon-helmet</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-settings"></div>
                                    <div class="class-name">.flaticon-settings</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-driller"></div>
                                    <div class="class-name">.flaticon-driller</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-email"></div>
                                    <div class="class-name">.flaticon-email</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-pin"></div>
                                    <div class="class-name">.flaticon-pin</div>
                                </div>        
                                </div>   
                                
                                <div class="col-md-3 col-sm-6">
                                <div class="icon-font-block"><div class="flat-icon flaticon-fax"></div>
                                    <div class="class-name">.flaticon-fax</div>
                                </div>        
                                </div>   
                                
                        </div>
                    </div>
               </div>
           </div>    
       </div> 
       <!-- ICON FOTS END -->                                                                                    
        
    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-footer1></app-footer1>
    <!-- FOOTER END -->
    
    <!-- BUTTON TOP START -->
<app-scroll-to-top></app-scroll-to-top>
    
</div>


<!-- LOADING AREA START ===== -->
<app-loading></app-loading>