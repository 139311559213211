import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CountToModule } from 'angular-count-to';  // Counter Module
import { GoogleMapsModule } from '@angular/google-maps'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Home1Component } from './home1/home1.component';
import { Home2Component } from './home2/home2.component';
import { Home3Component } from './home3/home3.component';
import { Home4Component } from './home4/home4.component';
import { About1Component } from './pages/about1/about1.component';
import { Contact1Component } from './pages/contact1/contact1.component';
import { NewsGridComponent } from './blog/news-grid/news-grid.component';
import { NewsListingComponent } from './blog/news-listing/news-listing.component';
import { NewsMasonryComponent } from './blog/news-masonry/news-masonry.component';
import { WorkGridComponent } from './pages/work-grid/work-grid.component';
import { WorkMasonryComponent } from './pages/work-masonry/work-masonry.component';
import { ProjectDetailComponent } from './pages/project-detail/project-detail.component';
import { PostGalleryComponent } from './blog/post-gallery/post-gallery.component';
import { PostRightSidebarComponent } from './blog/post-right-sidebar/post-right-sidebar.component';
import { AllElementsComponent } from './pages/all-elements/all-elements.component';
import { LoadingComponent } from './elements/loading/loading.component';
import { ScrollToTopComponent } from './elements/scroll-to-top/scroll-to-top.component';
import { Header1Component } from './elements/header/header1/header1.component';
import { Header2Component } from './elements/header/header2/header2.component';
import { Header3Component } from './elements/header/header3/header3.component';
import { NavLinksComponent } from './elements/nav-links/nav-links.component';
import { Footer1Component } from './elements/footer/footer1/footer1.component';
import { Slider1Component } from './elements/rev-slider/slider1/slider1.component';
import { Slider2Component } from './elements/rev-slider/slider2/slider2.component';
import { Slider3Component } from './elements/rev-slider/slider3/slider3.component';
import { Slider4Component } from './elements/rev-slider/slider4/slider4.component';
import { IconBox1Component } from './elements/icon-box/icon-box1/icon-box1.component';
import { IconBox2Component } from './elements/icon-box/icon-box2/icon-box2.component';
import { OurWork1Component } from './elements/our-work/our-work1/our-work1.component';
import { Specialization1Component } from './elements/specialization/specialization1/specialization1.component';
import { OurTeam1Component } from './elements/our-team/our-team1/our-team1.component';
import { ClientsComponent } from './elements/clients/clients.component';
import { Testimonial1Component } from './elements/testimonial/testimonial1/testimonial1.component';
import { Blog1Component } from './elements/blog/blog1/blog1.component';
import { Gallery1Component } from './elements/gallery/gallery1/gallery1.component';
import { Specialization2Component } from './elements/specialization/specialization2/specialization2.component';
import { OurWork2Component } from './elements/our-work/our-work2/our-work2.component';
import { OurTeam2Component } from './elements/our-team/our-team2/our-team2.component';
import { CallToAction1Component } from './elements/call-to-action/call-to-action1/call-to-action1.component';
import { Counter1Component } from './elements/counter/counter1/counter1.component';
import { Specialization3Component } from './elements/specialization/specialization3/specialization3.component';
import { OurWork3Component } from './elements/our-work/our-work3/our-work3.component';
import { IconBox3Component } from './elements/icon-box/icon-box3/icon-box3.component';
import { Banner1Component } from './elements/banners/banner1/banner1.component';
import { IconBox4Component } from './elements/icon-box/icon-box4/icon-box4.component';
import { Specialization4Component } from './elements/specialization/specialization4/specialization4.component';
import { CommentsComponent } from './elements/widgets/comments/comments.component';
import { CommentFormComponent } from './elements/forms/comment-form/comment-form.component';
import { SearchComponent } from './elements/widgets/search/search.component';
import { RecentPostComponent } from './elements/widgets/recent-post/recent-post.component';
import { CategoriesComponent } from './elements/widgets/categories/categories.component';
import { OurGalleryComponent } from './elements/widgets/our-gallery/our-gallery.component';
import { TagComponent } from './elements/widgets/tag/tag.component';
import { HttpClientModule } from '@angular/common/http';
import { PoojaComponent } from './pages/pooja/pooja.component';
import { CartComponent } from './pages/cart/cart.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ConfirmComponent } from './pages/confirm/confirm.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PasswordComponent } from './pages/password/password.component';
import { BookingComponent } from './pages/booking/booking.component';
import { VieworderComponent } from './pages/vieworder/vieworder.component';
import {NgxPrintModule} from 'ngx-print';
import { RecoverComponent } from './pages/recover/recover.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DevoteeComponent } from './pages/devotee/devotee.component';
import { UpoojasComponent } from './pages/upoojas/upoojas.component';
import { HpoojasComponent } from './pages/hpoojas/hpoojas.component';
import { GrievanceComponent } from './pages/grievance/grievance.component';

@NgModule({
  declarations: [
    AppComponent,
    Home1Component,
    Home2Component,
    Home3Component,
    Home4Component,
    About1Component,
    Contact1Component,
    NewsGridComponent,
    NewsListingComponent,
    NewsMasonryComponent,
    WorkGridComponent,
    WorkMasonryComponent,
    ProjectDetailComponent,
    PostGalleryComponent,
    PostRightSidebarComponent,
    AllElementsComponent,
    LoadingComponent,
    ScrollToTopComponent,
    Header1Component,
    Header2Component,
    Header3Component,
    NavLinksComponent,
    Footer1Component,
    Slider1Component,
    Slider2Component,
    Slider3Component,
    Slider4Component,
    IconBox1Component,
    IconBox2Component,
    OurWork1Component,
    Specialization1Component,
    OurTeam1Component,
    ClientsComponent,
    Testimonial1Component,
    Blog1Component,
    Gallery1Component,
    Specialization2Component,
    OurWork2Component,
    OurTeam2Component,
    CallToAction1Component,
    Counter1Component,
    Specialization3Component,
    OurWork3Component,
    IconBox3Component,
    Banner1Component,
    IconBox4Component,
    Specialization4Component,
    CommentsComponent,
    CommentFormComponent,
    SearchComponent,
    RecentPostComponent,
    CategoriesComponent,
    OurGalleryComponent,
    TagComponent,
    PoojaComponent,
    CartComponent,
    CheckoutComponent,
    LoginComponent,
    RegisterComponent,
    ConfirmComponent,
    DashboardComponent,
    PasswordComponent,
    BookingComponent,
    VieworderComponent,
    RecoverComponent,
    ProfileComponent,
    DevoteeComponent,
    UpoojasComponent,
    HpoojasComponent,
    GrievanceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    CountToModule,
    GoogleMapsModule,
    HttpClientModule,
    NgxPrintModule
  ],
  providers: [
    {provide : LocationStrategy , useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
