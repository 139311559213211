import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { ModalService } from '../../modal.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.css']
})
export class RecoverComponent implements OnInit {

  email:any;
  otp:any;
  password:any;
  cpassword:any;
  emsg:any;
  itemdata:any;
  constructor(private myModal: ModalService, private apiService: ApiService,private router: Router,private route: ActivatedRoute) { 

  }

  ngOnInit(): void {
  }

  resetpassword() {
      this.emsg='';
      if(this.email=='' || this.password=='' || this.cpassword=='' || this.otp=='') {
          this.emsg="Please fill in login details";
      } else {
          if(this.password!=this.cpassword) {
              this.emsg="Password and Confirm Password does not match.";
          } else {
              this.emsg='';
              this.itemdata = {
                "otp":this.otp,
                "email":this.email,
                "password":this.password
              }
              this.apiService.post('wsrpwd', this.itemdata).then((res: any) => {
                if (res.status === 200) {
                    this.emsg=res.data;          
                } else {
                    this.emsg="Something went wrong. Please try again."
                }
              });
          }
      }
  }

}
