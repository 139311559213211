<div class="widget bg-white ">
  <h4 class="widget-title text-uppercase font-weight-600">Search</h4>
  <div class="search-bx">
      <form role="search" method="post">
          <div class="input-group">
              <input name="news-letter" type="text" class="form-control" placeholder="Write your text">
              <span class="input-group-btn">
                  <button type="submit" class="site-button site-btn-effect"><i class="fa fa-search"></i></button>
              </span>
          </div>
      </form>
  </div>
</div>