import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface ICustomWindow extends Window {
  __custom_global_stuff: string;
}

function getWindow(): any {
  return window;
}

function _window(): any {
  return window;
}


@Injectable({
  providedIn: 'root'
})
export class ApiService {

	readonly API_URL;
  	responsedata: any;

  	get nativeWindow(): any {
	    return _window();
	}

	get nativeWindows(): ICustomWindow {
	    return getWindow();
	}

  	constructor(public http: HttpClient) { 
  		//this.API_URL = 'https://perutions.com/2021/templeapi/index.php/';
  		this.API_URL = 'https://www.booking.asthikasamajmatunga.com/api/index.php/';
  		//this.API_URL = 'https://demo.perutions.com/sriram/api/index.php/';
  	}

  	get(url: string) {
	    return new Promise((resolve) => {
	      this.http.get(this.API_URL + url).subscribe(
	        (data: any) => {
	          resolve(data);
	        },
	        (err) => {
	          console.log(err);
	        }
	      );
	    });
	}

	post(url: string, payload: object) {
	    return new Promise((resolve) => {
	      this.http.post(this.API_URL + url, payload).subscribe(
	        (data: any) => {
	          resolve(data);
	        },
	        (err) => {
	          resolve(err);
	        }
	      );
	    });
	}

	post_old(url: string, payload: object) {
	    return new Promise((resolve) => {
	      this.http.post(this.API_URL + url, payload).subscribe(
	        (data: any) => {
	          resolve(data);
	        },
	        (err) => {
	          resolve(err);
	        }
	      );
	    });
	}

	patch(url: string, payload: object) {
	    return new Promise((resolve) => {
	      this.http.patch(this.API_URL + url, payload).subscribe(
	        (data: any) => {
	          resolve(data);
	        },
	        (err) => {
	          console.log('error' + err);
	        }
	      );
	    });
	}

	delete(url: string) {
	    return new Promise((resolve) => {
	      this.http.delete(this.API_URL + url).subscribe(
	        (data: any) => {
	          resolve(data);
	        },
	        (err) => {
	          console.log('error' + err);
	        }
	      );
	    });
	}
}
