<div class="page-wraper"> 
      
      	<app-header2></app-header2>
      
      	<div class="container">
	      <!-- CONTENT START -->
	      <div class="page-content">        

            <div class="section-head text-center">
                <h2 class="text-uppercase  font-weight-900 m-b5 text-center">
                	Register your account
                </h2>
                <div class="wt-separator-outer text-center">
                    <div class="wt-separator bg-primary"></div>
                </div>
            </div>

   			<div class="section-content clearfix">
		        <div class="col-md-12">
		            <div class="row  no-col-gap"> 
			            <div style="padding:20px 0;text-align:center;background:#fff;width:90%;margin:0 auto;max-width:600px;">
			            	<div class="ramForm">

			            		<p class="formInfo">Fill in these details to register</p>

			            		<div class="form-group">
						            <input name="name" type="text" required="" class="form-control" placeholder="Your Name" (input)="name=$event.target.value">
						        </div>

						        <div class="form-group">
						            <input name="phone" type="text" required="" class="form-control" placeholder="Your Contact Number" (input)="phone=$event.target.value">
						        </div>

						        <div class="form-group">
						            <input name="email" type="email" required="" class="form-control" placeholder="Email Id" (input)="email=$event.target.value">
						        </div>

						        <div class="form-group">
						            <input name="password" type="password" required="" class="form-control" placeholder="Password"  (input)="password=$event.target.value">
						        </div>

						        <div class="form-group">
						            <input name="cpassword" type="password" required="" class="form-control" placeholder="Confirm Password"  (input)="cpassword=$event.target.value">
						        </div>

						        <p class="error" *ngIf="emsg!=''">{{emsg}}</p>

						        <a href="javascript:void(0)" (click)="register()" class="site-button-secondry button-lg site-btn-effect" ><span>Register</span></a>

						        <div class="" style="margin-top: 25px;display: block;">
                                    <a routerLink="/login" >Already have an account? <span class="text-primary">Login Now</span></a>
                                </div>

						    </div>
			            </div>                     		                                                                
		          	</div>
		        </div>
		    </div>

	      </div>
	   	</div>

      	<app-footer1></app-footer1>

  <app-scroll-to-top></app-scroll-to-top>
      
   
  </div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>