import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact1',
  templateUrl: './contact1.component.html',
  styleUrls: ['./contact1.component.css']
})
export class Contact1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
  banner : any = {
		pagetitle: "Contact Us",
		bg_image: "4.jpg",
		title: "Contact Us",
	}

}
