<div class="section-full small-device  p-t80  p-b50  bg-white">
  <ng-template ngFor let-sectionData [ngForOf]="data">
    <div class="container">
            
                <!-- IMAGE CAROUSEL START -->
        <div class="section-content">
            <div class="row">
                    <div class="col-md-3 col-sm-6 col-xs-6 col-xs-100pc m-b30" *ngFor = "let item of sectionData.items">
                        <div class="wt-icon-box-wraper  p-lr30  p-b50 p-t50 bdr-1 bdr-solid bdr-gray-light hover-border-outer hover-border">
                            <div class="icon-md text-primary m-b15">
                                <span class="icon-cell text-primary"><i class="{{item.icon_class}}"></i></span>
                            </div>
                            <div class="icon-content">
                                <h4 class="wt-tilte text-uppercase font-weight-600 m-b20">{{item.title}}</h4>
                                <p>{{item.description}}</p>
                                <a routerLink="/{{item.read_more_link}}" class="site-button-link" *ngIf="item.read_more_text!=''">{{item.read_more_text}}</a>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
  </ng-template>
</div>  