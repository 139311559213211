<ul class=" nav navbar-nav">
  <li [ngClass]="{'active': curl=='/'}">
      <a routerLink="/">Home</a>                                                
  </li>
  <li>
      <a href="http://www.asthikasamajmatunga.com/" target="_blank">Temple</a>
      <ul class="sub-menu">
          <li>
              <a href="http://www.asthikasamajmatunga.com/" target="_blank">History</a>
          </li>
          <li>
              <a href="http://www.asthikasamajmatunga.com/endowment.php" target="_blank">Endowment Schemes</a>
          </li>
      </ul>
  </li>   
  <li>
      <a href="javascript:void(0);" routerLink="/grievance" *ngIf="userid!=0"  [ngClass]="{'active': curl=='/grievance'}">Grievance</a>
  </li>
  <li *ngIf="userid==0"  [ngClass]="{'active': curl=='/register'}">
      <a href="javascript:void(0);" routerLink="/register">Register</a>
  </li>    
  <li *ngIf="userid==0"  [ngClass]="{'active': curl=='/login'}">
      <a href="javascript:void(0);" routerLink="/login">Log In</a>
  </li>     
  <li *ngIf="userid!=0"  [ngClass]="{'active': curl=='/dashboard'}">
      <a href="javascript:void(0);"  routerLink="/dashboard">Dashboard</a>
  </li>      
  <li *ngIf="userid!=0">
      <a href="javascript:void(0);" (click)="logout()">Log Out</a>
  </li>                          
</ul>