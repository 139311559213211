import { Component } from '@angular/core';
import { ApiService } from './api.service';
import { ModalService } from './modal.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Asthika Samaj';
  token:any;
  userid:any;

  constructor(private myModal: ModalService, private apiService: ApiService) { 


    this.token = localStorage.getItem('token');
    if(this.token=='' || this.token==undefined || this.token=='undefined') {
      this.getToken();
    }

    this.myModal.myUserid$.subscribe((data) => {
        this.userid = data;
        localStorage.setItem('userid',this.userid);
    });

  	this.myModal.myToken$.subscribe((data) => {
        this.token = data;
    });

    



  }

  ngOnInit() {}

  getToken() {
      this.apiService.post('myToken', {}).then((res: any) => {
        if (res.status === 200) {
          	this.token = res.token;
    		this.myModal.myToken(this.token);
    		localStorage.setItem('token', this.token);
        } else {
          const message = res.message;
        }
      });
  }

}
