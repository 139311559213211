<div class="section-full small-device  p-tb80 bg-white  bg-no-repeat bg-bottom-right" style="background-image:url(assets/images/background/bg-4.jpg);">
<ng-template ngFor let-sectionData [ngForOf]="data">
  <div class="container">
  
      <div class="section-content">

        <div class="row">
            <div class="col-md-5 col-sm-12">
                <div class="why-choose-half-section">
                    <div class="wt-media">
                    <img src="assets/images/{{sectionData.section_image}}" alt="">
                      </div>
                      
                 </div>
              </div>                        
          <div class="col-md-7 col-sm-12">
                  <!-- TITLE START -->
                  <div class="section-head text-left">
                      <h4 class="text-primary text-uppercase wt-title-small">{{sectionData.section_title}}</h4>
                      <h2 class="text-uppercase font-45 font-weight-500 m-b5">{{sectionData.section_heading}}</h2>
                  </div>
                  <!-- TITLE END -->                              
  <div class="row">
                    <div class="col-md-6 col-sm-6" *ngFor="let item of sectionData.items">
                          <div class="wt-icon-box-wraper bg-white m-b30">
                              <div class="icon-lg inline-icon	 m-b50 text-left">
                                  <span class="icon-cell text-primary"><i class="fa {{item.icon_class}}"></i></span>
                              </div>
                              <div class="icon-content bold-title">
                                  <h4 data-title="01" class="wt-tilte text-uppercase font-weight-600 m-b20" >{{item.title}}</h4>
                                  <p>{{item.description}}</p>
                              </div>
                          </div>
                      </div>                                                                                                 
                  </div>
              </div>
          </div>

      </div>
  </div>
  </ng-template>
</div>   