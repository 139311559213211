<div class="page-wraper"> 
      
      	<app-header2></app-header2>
      
      	<div class="container">
	      <!-- CONTENT START -->
	      <div class="page-content">        

            <div class="section-head text-center">
                <h2 class="text-uppercase  font-weight-900 m-b5" *ngIf="urltype=='book'">{{url}}</h2>
                <h2 class="text-uppercase  font-weight-900 m-b5" *ngIf="urltype=='deity'">{{dietyname}}</h2>
                
                
                <p class="blackp p-t15">
                	<input type="text" name="search" value="{{search}}" class="form-control search" placeholder="Type to search from list" (input)="search=$event.target.value" (keyup)="searchfn()">
                	<select name="categories" class="form-control" (input)="categories=$event.target.value" (change)="searchfn()">
                		<option value="0" ng-selected="categories=='0'">-- All Pooja Types --</option>
	                	<option *ngFor="let item of poojaCategory" [attr.value]='item.url' [attr.selected]="item.url == categories ? true : null">{{item.typename}}</option>
                	</select>
                	<a href="javascript:void(0);" (click)="searchfn()" class="site-button-secondry button-sm site-btn-effect searchBtn"><span>Search</span></a>
                </p>
            </div>

	      	<!-- IMAGE CAROUSEL START -->
		    <div class="section-content clearfix">
		        <div class="col-md-12">
		            <div class="row  no-col-gap bg-primary">
			            <div class="col-md-12 col-sm-12 col-xs-12 col-xs-100pc bg-white"  *ngFor="let pooja of poojaList">
			                <div class="wt-icon-box-wraper center p-lr30  p-b10 p-t30 bdr-1 bdr-solid bdr-gray-light hover-border-outer hover-border">
			                    <div class="row poojas">
			                    	<div class="col-md-3">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b20 p-l10 text-left" style="padding-right:10px;">
				                        	{{pooja.name}}
				                        	<div class="subinfo" *ngIf="urltype=='book'">{{pooja.deity}}</div>
				                        	<div class="subinfo" *ngIf="urltype=='deity'">{{pooja.typename}}</div>
				                        </h4>
			                        </div>
			                        <div class="col-md-3">
			                        	<p class="info"><span class="desc">Info</span>{{pooja.description}}</p>
			                        </div>
			                        <div class="col-md-2">
			                        	<p class="info"><span class="desc">Timing</span>{{pooja.timing}}</p>
			                        </div>
			                        <div class="col-md-2">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b20">₹ {{pooja.rate}}</h4>
			                        </div>
			                        <div class="col-md-2">
			                        	<a href="javascript:void(0)" (click)="addOptions(pooja.id,pooja.rate,pooja.bookable_maxqty,pooja.bookbefore_days,pooja.bookable_maxdate)"  *ngIf="cartlist.indexOf(pooja.id)=='-1'" class="site-button-secondry button-sm site-btn-effect"><span>Add to cart</span></a>

			                        	<a *ngIf="cartlist.indexOf(pooja.id)!='-1'" href="javascript:void(0);" (click)="addOptions(pooja.id,pooja.rate,pooja.bookable_maxqty,pooja.bookbefore_days,pooja.bookable_maxdate)" class="site-button button-sm site-btn-effect "><span>Add Devotee</span></a>

			                        	<a *ngIf="cartlist.indexOf(pooja.id)!='-1'" href="javascript:void(0);" routerLink="/cart" class="site-button button-sm site-btn-effect " style="    background-color: #1f3334;"><span>Confirm Booking</span></a>
			                        </div>
			                    </div>
			                </div>
			            </div>                        		                                                                
		          	</div>
		        </div>
		    </div>

	      </div>
	   	</div>
      
	   	<app-clients [data]="hideme"></app-clients>

      	<app-footer1></app-footer1>

  <app-scroll-to-top></app-scroll-to-top>
      
   
  </div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>