<div class="page-wraper"> 
      
      	<app-header2></app-header2>
      
      	<div class="container">
	      <!-- CONTENT START -->
	      <div class="page-content">        

            <div class="section-head text-center">
                <h2 class="text-uppercase  font-weight-900 m-b5 text-left">
                	My Devotees
                </h2>
                <a *ngIf="list==0" (click)="add()" routerLink="/devotees" class="site-button-secondry button-sm site-btn-effect" style="float: right;"><span>Add Devotee</span></a>
                <a *ngIf="list==1" (click)="showlist()" routerLink="/devotees" class="site-button-secondry button-sm site-btn-effect" style="float: right;"><span>Show Devotee</span></a>
                <div class="wt-separator-outer text-left">
                    <div class="wt-separator bg-primary"></div>
                </div>
            </div>

   			<div class="section-content clearfix">
		        <div class="row  no-col-gap bg-primary" *ngIf="list==0">
	            	<ng-container *ngIf="devotees.length>0">
	            		<div class="col-md-12 col-sm-12 col-xs-12 col-xs-100pc bg-white headrow">
			                <div class="wt-icon-box-wraper center p-lr30  p-b0 p-t10 bdr-1 bdr-solid bdr-gray-light ">
			                    <div class="row poojas">
			                    	<div class="col-md-4">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10 p-l10 text-left">Name</h4>
			                        </div>
			                        <div class="col-md-3">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">Star</h4>
			                        </div>
			                        <div class="col-md-3">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">Gothram</h4>
			                        </div>
			                        <div class="col-md-2">
			                        	
			                        </div>
			                    </div>
			                </div>
			            </div> 
			            <div class="col-md-12 col-sm-12 col-xs-12 col-xs-100pc bg-white"  *ngFor="let item of devotees">
			                <div class="wt-icon-box-wraper center p-lr30  p-b0 p-t10 bdr-1 bdr-solid bdr-gray-light ">
			                    <div class="row poojas" [ngClass]="{'failed': item.status==0, '': item.status==1}">
			                    	<div class="col-md-4">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10 p-l10 text-left">
				                        	{{item.name}}
				                        </h4>
			                        </div>
			                        <div class="col-md-3">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">{{ item.starn}}</h4>
			                        </div>
			                        <div class="col-md-3">
			                        	<h4 class="wt-tilte text-uppercase font-weight-600 m-b10">{{item.gothramn}}</h4>
			                        </div>
			                        <div class="col-md-2">
			                        	<button (click)="editDevotee(item.id)" class="site-button-secondry button-sm site-btn-effect" type="button"  style="font-size: 10px;"><span>Edit</span></button>
			                        	<button (click)="deleteDevotee(item.id)" class="site-button-secondry button-sm site-btn-effect" type="button"  style="font-size: 10px;margin-left:10px;"><span>Delete</span></button>
			                        </div>
			                    </div>
			                </div>
			            </div> 
		            </ng-container>  
		            <div *ngIf="devotees.length==0" style="padding:50px 0;text-align:center;background:#fff;">
		            	<h4 class="text-center">-- No Devotees added yet --</h4>
		            </div>                     		                                                                
	          	</div>

	          	<div class="row  no-col-gap" *ngIf="list==1">
	          		<div class="col-md-12 col-sm-12 col-xs-12 col-xs-100pc bg-white">
		          		
	          			<div style="padding:20px 0;text-align:center;background:#fff;width:90%;margin:0 auto;">
			            	<div class="ramForm">

			            		<h3 class="text-center p-b15">Add new Devotee</h3>
				            	<div class="row">			            		

							        <div class="col-md-4 p-t5 p-r5 p-b5 p-l5">
								        <div class="form-group">
								            <input name="name" type="text" required="" class="form-control"  (input)="name=$event.target.value" placeholder="Name">
								        </div>
								    </div>

								    <div class="col-md-4 p-t5 p-r5 p-b5 p-l5">
								        <div class="form-group">
								            <select class="form-control" name="lgothram" (change)="lgothram=$event.target.value">
								              <option value=""  ng-selected="lgothram==''">-- Select Gothram --</option>
								              <option *ngFor="let item of gothras" value="{{item.id}}" ng-selected="lgothram==item.name">{{item.name}}</option>
								            </select>
								        </div>
								    </div>

							        <div class="col-md-4 p-t5 p-r5 p-b5 p-l5">
								        <div class="form-group">
								            <select class="form-control" name="lstar" (change)="lstar=$event.target.value">
								              <option value="" ng-selected="lstar==''">-- Select Star --</option>
								              <option *ngFor="let item of stars" value="{{item.id}}"  ng-selected="lstar==item.name">{{item.name}}</option>
								            </select>
								        </div>
								    </div>
								    

								</div>
						        <p class="error" *ngIf="message!=''">{{message}}</p>

						        <a href="javascript:void(0)" (click)="change()" class="site-button-secondry button-sm site-btn-effect" ><span>Add Devotee</span></a>

						    </div>
			            </div>

		          	</div>
	          	</div>	

	          	<div class="row  no-col-gap" *ngIf="list==2">
	          		<div class="col-md-12 col-sm-12 col-xs-12 col-xs-100pc bg-white">
		          		
	          			<div style="padding:20px 0;text-align:center;background:#fff;width:90%;margin:0 auto;">
			            	<div class="ramForm">

			            		<h3 class="text-center p-b15">Edit Devotee details</h3>
				            	<div class="row">			            		

							        <div class="col-md-4 p-t5 p-r5 p-b5 p-l5">
								        <div class="form-group">
								            <input name="dname" type="text" required="" class="form-control"  (input)="dname=$event.target.value" placeholder="Name" value="{{dname}}">
								        </div>
								    </div>

								    <div class="col-md-4 p-t5 p-r5 p-b5 p-l5">
								        <div class="form-group">
								            <select class="form-control" name="dgothram" (change)="dgothram=$event.target.value" value="{{dgothram}}">
								              <option *ngFor="let item of gothras" value="{{item.id}}" ng-selected="dgothram==item.id">{{item.name}}</option>
								            </select>
								        </div>
								    </div>

							        <div class="col-md-4 p-t5 p-r5 p-b5 p-l5">
								        <div class="form-group">
								            <select class="form-control" name="dstar" (change)="dstar=$event.target.value" value="{{dstar}}">
								              <option *ngFor="let item of stars" value="{{item.id}}"  ng-selected="dstar==item.id">{{item.name}}</option>
								            </select>
								        </div>
								    </div>
								    

								</div>
						        <p class="error" *ngIf="message!=''">{{message}}</p>

						        <a href="javascript:void(0)" (click)="update()" class="site-button-secondry button-sm site-btn-effect" ><span>Update Details</span></a>

						        <a href="javascript:void(0)" (click)="canceledit()" class="site-button-secondry button-sm site-btn-effect" style="margin-left: 10px;"><span>Cancel</span></a>

						    </div>
			            </div>

		          	</div>
	          	</div>		        
		    </div>

	      </div>
	   	</div>

      	<app-footer1></app-footer1>

  <app-scroll-to-top></app-scroll-to-top>
      
   
  </div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>