import { Component, OnInit, NgZone } from '@angular/core';
import { ApiService, ICustomWindow } from '../../api.service';
import { ModalService } from '../../modal.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

	totalamount:any;
	cartlist:any;
	cartid:any;
	token:any;
	cprocess:any = 1;
	grandtotal:any = 0;
	donation:any;
	dakshina:any;
	emsg:any = '';

	name:any;
	email:any;
	phone:any;
	address:any;
	oremark:any;
	dremark:any;
	doremark:any;

	itemData:any;
	userid:any;
	plmsg:any = '';

	private _window: ICustomWindow;
  	public rzp: any;
  	orderId: any;
  	paymentId: any;
  	options: any 
  	error:any;
  	status:any;
  	razid:any;
  	razsignature:any;

  	constructor(private zone: NgZone, private router: Router,private route: ActivatedRoute, private myModal: ModalService, private apiService: ApiService) { 
  		this._window = this.apiService.nativeWindow;
	 		this.token = localStorage.getItem('token');
  		this.userid = localStorage.getItem('userid');
  		this.cartid = localStorage.getItem('cartid');
  		this.name = localStorage.getItem('name');
  		this.email = localStorage.getItem('email');
	 		if(this.userid=='' || this.userid==undefined || this.userid==null || this.userid==0 || this.userid=='0') {
	 			this.router.navigate(['/login']);
	 		} else {
	 			this.getAddress();
	 		}

 			if(this.cartid=='' || this.cartid==undefined || this.cartid==null || this.cartid==0) {
  			this.router.navigate(['/cart']);
  		} else {
  			this.getCart();
  			
  		}

	    this.myModal.myUserid$.subscribe((data) => {
	        this.userid = data;
	    });

	    this.dakshina=0;
	    this.donation=0;

  	}

  	ngOnInit(): void {
  	}

  	getAddress() {

	    this.apiService.post('r_ga', { id: this.userid }).then((res: any) => {
		        this.name = res.name;
		        this.address = res.address;
		        this.email = res.email;
		        this.phone = res.phone;
		  });
	  }

  	getCart() {
	    this.apiService.post('viewCart', { cart: this.cartid }).then((res: any) => {
	      if (res.status === 200) {
	        this.cartlist = res.data;
	        this.totalamount = res.total;
	        this.myModal.cartItems(res.count);
	      } else {
	        const message = res.message;
	      }
	  });
	}

	next() {
		if(this.name=='' || this.email=='' || this.phone=='' || this.address=='' || this.name==undefined || this.email==undefined || this.phone==undefined || this.address==undefined) {
			this.emsg='Please fill out Name, Email, Phone and Address';
		} else {
			this.emsg='';
			this.cprocess=2;
			if(this.donation=='' || this.donation==undefined) {
				this.donation=0;
			}
			if(this.dakshina=='' || this.dakshina==undefined) {
				this.dakshina=0;
			}
			this.grandtotal = +this.totalamount + +this.donation + +this.dakshina;
		}		
	}

	previous() {
		this.cprocess=1;
	}

	placeorder() {
		this.itemData = {
			"userid":this.userid,
			"token":this.token,
			"cartid":this.cartid,
			"name":this.name,
			"email":this.email,
			"phone":this.phone,
			"address":this.address,
			"oremark":this.oremark,
			"dakshina":this.dakshina,
			"dremark":this.dremark,
			"donation":this.donation,
			"doremark":this.doremark,
		}
		this.apiService.post('placeorder', this.itemData).then((res: any) => {
    		if (res.status === 200) {
    			if(res.ostat>0) {
    				this.orderId = res.orderid;
    				this.razid = res.razId;
    				//this.razorkey= res.rkey;
    				if(res.pay==0) {
    					localStorage.setItem('orderId',this.orderId);
	      			localStorage.setItem('orderStatus','1');
							localStorage.setItem('cartid','0');
    					this.myModal.cartItems(0);
			      	this.error='';
			      	this.status="1";
			        this.confirmBooking();
	        		//this.router.navigate(['/confirm']);
    				} else {
    					this.initPay(res.rkey);
    				}
        		
        	} else {
        		this.plmsg=res.data;
        		//this.router.navigate(['/login']);
        	}
    			//this.router.navigate(['/confirm']);
    		} else {
	          const message = res.message;
	        }
	    });
	}

	checkDakshina(val) {
		if(isNaN(val)) {
			this.dakshina='0';
		}
	}

	checkDonation(val) {
		if(isNaN(val)) {
			this.donation='0';
		}
	}

	initPay(key): void {
		this.options = {
		    key: key, // add razorpay key here
		    name: 'Asthika Samajam',
		    description: 'Pooja Booking',
		    order_id : this.razid,
		    amount: this.grandtotal + '00', // razorpay takes amount in paisa
		    prefill: {
		      name: this.name,
		      email: '', // add your email id
		    },
		    notes: {},
		    handler: this.paymentHandler.bind(this),
		    modal: {
		      ondismiss: () => {
		        this.zone.run(() => {
		          // add current page routing if payment fails
		        });
		      },
		    },
	  	};
	    this.rzp = new this.apiService.nativeWindow['Razorpay'](this.options);
	    this.rzp.open();

	    this.rzp.on('payment.failed', function (response){    
            this.paymentId=response.error.metadata.payment_id;
            this.status="0";
            this.error = response.error.reason;
            console.log("in");
            const sendData = {
		      orderid: this.orderId,
		      transactionid: this.paymentId,
		      error:this.error,
		      status:this.status
		    };  
		    this.apiService.post('orderstatus', sendData).then((res: any) => {
		      if (res.status === 200) {
		        this.router.navigate(['/confirm']);
		      } else {
		        const message = res.message;
		      }
		    });
        });
	}

	  paymentHandler(res: any) {
	    this.zone.run(() => {
	      console.log(res);
	      this.paymentId = res.razorpay_payment_id;
	      this.razsignature = res.razorpay_signature;
	      localStorage.setItem('orderId',this.orderId);
	      if (this.paymentId) {
	      	localStorage.setItem('orderStatus','1');
	      	this.error='';
	      	this.status="1";
	        this.captureBooking(res.razorpay_signature);
	        this.confirmBooking();
	      } else {
	      	localStorage.setItem('orderStatus','0');
	      	this.router.navigate(['/confirm']);
	      }
	    });
	  }

	  razorpayResponse(response: any) {}

	  captureBooking(sig) {
	    const sendData = {
	      razid: this.razid,
	      razpayid: this.paymentId,
	      razorpay_signature:sig
	    };  
	    this.apiService.post('authRazor', sendData).then((res: any) => {

	    });
	  }

	  confirmBooking() {
	    const sendData = {
	      orderid: this.orderId,
	      transactionid: this.paymentId,
	      error:this.error,
	      status:this.status
	    };  
	    this.apiService.post('orderstatus', sendData).then((res: any) => {
	      if (res.status === 200) {
	      	localStorage.setItem('cartid','0');
    		this.myModal.cartItems(0);
	        this.router.navigate(['/confirm']);
	      } else {
	        const message = res.message;
	      }
	    });
	  }

  	

}
