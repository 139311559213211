<div class="page-wraper"> 
    
    <!-- HEADER START -->
    <app-header1></app-header1>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">
        <!-- INNER PAGE BANNER -->
        <app-banner1 [data]="banner"></app-banner1>
        <!-- INNER PAGE BANNER END -->
        
  <!-- SECTION CONTENT START -->
        <div class="section-full small-device  p-tb80 bg-gray">
            <!-- GALLERY CONTENT START -->
            <div class="container clearfix">
                <div class="portfolio-wrap mfp-gallery news-masonry row">
           
                        <!-- COLUMNS 1 -->
                        <div class="masonry-item col-lg-4 col-md-6 col-sm-6" *ngFor = "let item of news_grid_section">
                            <div class="blog-post blog-grid blog-grid-1 date-style-1">
                                <div class="wt-post-media wt-img-effect zoom-slow">
                                    <a routerLink="/{{item.read_more_link}}"><img src="{{item.blog_image}}" alt=""></a>
                                </div>
                              
                                <div class="wt-post-info  bg-white">
                                    <div class="wt-post-meta ">
                                        <ul>
                                            <li class="post-date"[innerHTML]="item.post_date"></li>
                                            <li class="post-author"><a href="javascript:void(0);">By <span>{{item.post_author}}</span></a> </li>
                                        </ul>
                                    </div>                                        
                                    <div class="wt-post-title ">
                                        <h3 class="post-title"><a routerLink="/{{item.read_more_link}}" class=" font-weight-600 m-t0">{{item.post_title}}</a></h3>
                                    </div>
                                    <div class="wt-post-text">
                                        <p>{{item.description}}</p> 
                                    </div>
                                    <a routerLink="/{{item.read_more_link}}" class="btn-half site-button site-btn-effect button-md"><span>{{item.read_more_text}}</span></a>
                                </div>
                            </div>
                        </div>
                       <!--
                        <div class="masonry-item col-lg-4 col-md-6 col-sm-6">
                            <div class="blog-post blog-grid blog-grid-1 date-style-1">
                                <div class="wt-post-media wt-img-effect zoom-slow">
                                    <a href="javascript:void(0);"><img src="assets/images/gallery/pic2.jpg" alt=""></a>
                                </div>
                                <div class="wt-post-info  bg-white">
                                    <div class="wt-post-meta ">
                                        <ul>
                                            <li class="post-date"><strong>20 Feb</strong> <span> 2018</span> </li>
                                            <li class="post-author"><i class="fa fa-user"></i><a href="javascript:void(0);">By <span>Admin</span></a> </li>
                                        </ul>
                                    </div>                                        
                                    <div class="wt-post-title ">
                                        <h3 class="post-title"><a href="javascript:void(0);" class=" font-weight-600 m-t0">Design is where science and art break even.</a></h3>
                                    </div>
                                    <div class="wt-post-text">
                                        <p>Asperiores, tenetur, blanditiis, quaerat odit exerci tationem pariatur qui busdam veritatis quis quam labor iosam esse</p> 
                                    </div>
                                    <a href="javascript:void(0);" class="btn-half site-button site-btn-effect button-md"><span>Read More</span></a>
                                </div>
                            </div>
                        </div>
                        <div class="masonry-item col-lg-4 col-md-6 col-sm-6">
                            <div class="blog-post blog-grid  blog-grid-1 date-style-1">
                                <div class="wt-post-media wt-img-effect zoom-slow">
                                    <a href="javascript:void(0);"><img src="assets/images/gallery/portrait/pic3.jpg" alt=""></a>
                                </div>

                                <div class="wt-post-info  bg-white">
                                
                                    <div class="wt-post-meta ">
                                        <ul>
                                            <li class="post-date"><strong>20 Feb</strong> <span> 2018</span> </li>
                                            <li class="post-author"><i class="fa fa-user"></i><a href="javascript:void(0);">By <span>Admin</span></a> </li>
                                        </ul>
                                    </div>                                        
                                    <div class="wt-post-title ">
                                        <h3 class="post-title"><a href="javascript:void(0);" class=" font-weight-600 m-t0">Architecture is the art of how to waste space.</a></h3>
                                    </div>
                                    
                                    <div class="wt-post-text">
                                        <p>Asperiores, tenetur, blanditiis, quaerat odit exerci tationem pariatur qui busdam veritatis quis quam labor iosam esse</p> 
                                    </div>
                                    <a href="javascript:void(0);" class="btn-half site-button site-btn-effect button-md"><span>Read More</span></a>
                                </div>

                            </div>
                        </div>
                        <div class="masonry-item col-lg-4 col-md-6 col-sm-6">
                            <div class="blog-post blog-grid blog-grid-1 date-style-1">
                                <div class="wt-post-media wt-img-effect zoom-slow">
                                    <a href="javascript:void(0);"><img src="assets/images/gallery/portrait/pic1.jpg" alt=""></a>
                                </div>
                           
                                <div class="wt-post-info  bg-white">
                                
                                    <div class="wt-post-meta ">
                                        <ul>
                                            <li class="post-date"><strong>20 Feb</strong> <span> 2018</span> </li>
                                            <li class="post-author"><i class="fa fa-user"></i><a href="javascript:void(0);">By <span>Admin</span></a> </li>
                                        </ul>
                                    </div>  
                                                                         
                                    <div class="wt-post-title ">
                                        <h3 class="post-title"><a href="javascript:void(0);" class=" font-weight-600 m-t0">A designer is a planner with an aesthetic sense.</a></h3>
                                    </div>

                                    
                                    <div class="wt-post-text">
                                        <p>Asperiores, tenetur, blanditiis, quaerat odit exerci tationem pariatur qui busdam veritatis quis quam labor iosam esse</p> 
                                    </div>
                                    <a href="javascript:void(0);" class="btn-half site-button site-btn-effect button-md"><span>Read More</span></a>
                                </div>
                           
                            </div>
                        </div>
                        <div class="masonry-item col-lg-4 col-md-6 col-sm-6">
                            <div class="blog-post blog-grid blog-grid-1 date-style-1">
                                <div class="wt-post-media wt-img-effect zoom-slow">
                                    <a href="javascript:void(0);"><img src="assets/images/gallery/portrait/pic5.jpg" alt=""></a>
                                </div>
                           
                                <div class="wt-post-info  bg-white">
                                    <div class="wt-post-meta ">
                                        <ul>
                                            <li class="post-date"><strong>20 Feb</strong> <span> 2018</span> </li>
                                            <li class="post-author"><i class="fa fa-user"></i><a href="javascript:void(0);">By <span>Admin</span></a> </li>
                                        </ul>
                                    </div>                                        
                                    <div class="wt-post-title ">
                                        <h3 class="post-title"><a href="javascript:void(0);" class=" font-weight-600 m-t0">Being a famous designer is like being a famous dentist.</a></h3>
                                    </div>
                                    <div class="wt-post-text">
                                        <p>Asperiores, tenetur, blanditiis, quaerat odit exerci tationem pariatur qui busdam veritatis quis quam labor iosam esse</p> 
                                    </div>
                                    <a href="javascript:void(0);" class="btn-half site-button site-btn-effect button-md"><span>Read More</span></a>
                                </div>
                          
                            </div>
                        </div>
                        
                        <div class="masonry-item col-lg-4 col-md-6 col-sm-6">
                            <div class="blog-post blog-grid blog-grid-1 date-style-1">
                                <div class="wt-post-media wt-img-effect zoom-slow">
                                    <a href="javascript:void(0);"><img src="assets/images/gallery/pic6.jpg" alt=""></a>
                                </div>
                                <div class="wt-post-info  bg-white">
                                    <div class="wt-post-meta ">
                                        <ul>
                                            <li class="post-date"><strong>20 Feb</strong> <span> 2018</span> </li>
                                            <li class="post-author"><i class="fa fa-user"></i><a href="javascript:void(0);">By <span>Admin</span></a> </li>
                                        </ul>
                                    </div>                                        
                                    <div class="wt-post-title ">
                                        <h3 class="post-title"><a href="javascript:void(0);" class=" font-weight-600 m-t0">Being a famous designer is like being a famous dentist.</a></h3>
                                    </div>
                                    <div class="wt-post-text">
                                        <p>Asperiores, tenetur, blanditiis, quaerat odit exerci tationem pariatur qui busdam veritatis quis quam labor iosam esse</p> 
                                    </div>
                                    <a href="javascript:void(0);" class="btn-half site-button site-btn-effect button-md"><span>Read More</span></a>
                                </div>
                            </div>                                    
                        </div>
                        <div class="masonry-item col-lg-4 col-md-6 col-sm-6">
                            <div class="blog-post blog-grid blog-grid-1 date-style-1">
                                <div class="wt-post-media wt-img-effect zoom-slow">
                                    <a href="javascript:void(0);"><img src="assets/images/gallery/portrait/pic4.jpg" alt=""></a>
                                </div>
                            
                                <div class="wt-post-info  bg-white">
                                    <div class="wt-post-meta ">
                                        <ul>
                                            <li class="post-date"><strong>20 Feb</strong> <span> 2018</span> </li>
                                            <li class="post-author"><i class="fa fa-user"></i><a href="javascript:void(0);">By <span>Admin</span></a> </li>
                                        </ul>
                                    </div>                                        
                                    <div class="wt-post-title ">
                                        <h3 class="post-title"><a href="javascript:void(0);" class=" font-weight-600 m-t0">Being a famous designer is like being a famous dentist.</a></h3>
                                    </div>
                                    <div class="wt-post-text">
                                        <p>Asperiores, tenetur, blanditiis, quaerat odit exerci tationem pariatur qui busdam veritatis quis quam labor iosam esse</p> 
                                    </div>
                                    <a href="javascript:void(0);" class="btn-half site-button site-btn-effect button-md"><span>Read More</span></a>
                                </div>

                            </div>
                        </div>
                        <div class="masonry-item col-lg-4 col-md-6 col-sm-6">
                            <div class="blog-post blog-grid blog-grid-1 date-style-1">
                                <div class="wt-post-media wt-img-effect zoom-slow">
                                    <a href="javascript:void(0);"><img src="assets/images/gallery/pic7.jpg" alt=""></a>
                                </div>

                                <div class="wt-post-info  bg-white">
                                
                                    <div class="wt-post-meta ">
                                        <ul>
                                            <li class="post-date"><strong>20 Feb</strong> <span> 2018</span> </li>
                                            <li class="post-author"><i class="fa fa-user"></i><a href="javascript:void(0);">By <span>Admin</span></a> </li>
                                        </ul>
                                    </div>                                        
                                    <div class="wt-post-title ">
                                        <h3 class="post-title"><a href="javascript:void(0);" class=" font-weight-600 m-t0">Architecture is the art of how to waste space.</a></h3>
                                    </div>
                                    
                                    <div class="wt-post-text">
                                        <p>Asperiores, tenetur, blanditiis, quaerat odit exerci tationem pariatur qui busdam veritatis quis quam labor iosam esse</p> 
                                    </div>
                                    <a href="javascript:void(0);" class="btn-half site-button site-btn-effect button-md"><span>Read More</span></a>
                                </div>

                            </div>
                        </div>
                        <div class="masonry-item col-lg-4 col-md-6 col-sm-6">
                            <div class="blog-post blog-grid blog-grid-1 date-style-1">
                                <div class="wt-post-media wt-img-effect zoom-slow">
                                    <a href="javascript:void(0);"><img src="assets/images/gallery/pic8.jpg" alt=""></a>
                                </div>
                                <div class="wt-post-info  bg-white">
                                
                                    <div class="wt-post-meta ">
                                        <ul>
                                            <li class="post-date"><strong>20 Feb</strong> <span> 2018</span> </li>
                                            <li class="post-author"><i class="fa fa-user"></i><a href="javascript:void(0);">By <span>Admin</span></a> </li>
                                        </ul>
                                    </div>                                        
                                    <div class="wt-post-title ">
                                        <h3 class="post-title"><a href="javascript:void(0);" class=" font-weight-600 m-t0">Architecture is the art of how to waste space.</a></h3>
                                    </div>
                                    
                                    <div class="wt-post-text">
                                        <p>Asperiores, tenetur, blanditiis, quaerat odit exerci tationem pariatur qui busdam veritatis quis quam labor iosam esse</p> 
                                    </div>
                                    <a href="javascript:void(0);" class="btn-half site-button site-btn-effect button-md"><span>Read More</span></a>
                                </div>
                            </div>
                        </div>
                        <div class="masonry-item col-lg-4 col-md-6 col-sm-6">
                            <div class="blog-post blog-grid blog-grid-1 date-style-1">
                                <div class="wt-post-media wt-img-effect zoom-slow">
                                    <a href="javascript:void(0);"><img src="assets/images/gallery/portrait/pic2.jpg" alt=""></a>
                                </div>
                                <div class="wt-post-info  bg-white">
                                
                                    <div class="wt-post-meta ">
                                        <ul>
                                            <li class="post-date"><strong>20 Feb</strong> <span> 2018</span> </li>
                                            <li class="post-author"><i class="fa fa-user"></i><a href="javascript:void(0);">By <span>Admin</span></a> </li>
                                        </ul>
                                    </div>                                        
                                    <div class="wt-post-title ">
                                        <h3 class="post-title"><a href="javascript:void(0);" class=" font-weight-600 m-t0">Architecture is the art of how to waste space.</a></h3>
                                    </div>
                                    
                                    <div class="wt-post-text">
                                        <p>Asperiores, tenetur, blanditiis, quaerat odit exerci tationem pariatur qui busdam veritatis quis quam labor iosam esse</p> 
                                    </div>
                                    <a href="javascript:void(0);" class="btn-half site-button site-btn-effect button-md"><span>Read More</span></a>
                                </div>
                            </div>
                        </div>
                        <div class="masonry-item col-lg-4 col-md-6 col-sm-6">
                            <div class="blog-post blog-grid blog-grid-1 date-style-1">
                                <div class="wt-post-media wt-img-effect zoom-slow">
                                    <a href="javascript:void(0);"><img src="assets/images/gallery/pic9.jpg" alt=""></a>
                                </div>
                                <div class="wt-post-info  bg-white">
                                    <div class="wt-post-meta ">
                                        <ul>
                                            <li class="post-date"><strong>20 Feb</strong> <span> 2018</span> </li>
                                            <li class="post-author"><i class="fa fa-user"></i><a href="javascript:void(0);">By <span>Admin</span></a> </li>
                                        </ul>
                                    </div>                                        
                                    <div class="wt-post-title ">
                                        <h3 class="post-title"><a href="javascript:void(0);" class=" font-weight-600 m-t0">Design is where science and art break even.</a></h3>
                                    </div>
                                    <div class="wt-post-text">
                                        <p>Asperiores, tenetur, blanditiis, quaerat odit exerci tationem pariatur qui busdam veritatis quis quam labor iosam esse</p> 
                                    </div>
                                    <a href="javascript:void(0);" class="btn-half site-button site-btn-effect button-md"><span>Read More</span></a>
                                </div>
                            </div>
                        </div>  
                        
                        <div class="masonry-item col-lg-4 col-md-6 col-sm-6">
                            <div class="blog-post blog-grid blog-grid-1 date-style-1">
                                <div class="wt-post-media wt-img-effect zoom-slow">
                                    <a href="javascript:void(0);"><img src="assets/images/gallery/pic1.jpg" alt=""></a>
                                </div>
                                <div class="wt-post-info  bg-white">
                                    <div class="wt-post-meta ">
                                        <ul>
                                            <li class="post-date"><strong>20 Feb</strong> <span> 2018</span> </li>
                                            <li class="post-author"><i class="fa fa-user"></i><a href="javascript:void(0);">By <span>Admin</span></a> </li>
                                        </ul>
                                    </div>                                        
                                    <div class="wt-post-title ">
                                        <h3 class="post-title"><a href="javascript:void(0);" class=" font-weight-600 m-t0">Design is where science and art break even.</a></h3>
                                    </div>
                                    <div class="wt-post-text">
                                        <p>Asperiores, tenetur, blanditiis, quaerat odit exerci tationem pariatur qui busdam veritatis quis quam labor iosam esse</p> 
                                    </div>
                                    <a href="javascript:void(0);" class="btn-half site-button site-btn-effect button-md"><span>Read More</span></a>
                                </div>
                            </div>
                        </div>   -->                                                      
        
                </div>
             </div>
            <!-- GALLERY CONTENT END -->
        
        </div>
        <!-- SECTION CONTENT END  -->

    </div>
    <!-- CONTENT END -->
    
    <!-- FOOTER START -->
    <app-footer1></app-footer1>
    <!-- FOOTER END -->

    <!-- BUTTON TOP START -->
<app-scroll-to-top></app-scroll-to-top>
    
</div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>