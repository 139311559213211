<div class="page-wraper"> 
    
    <!-- HEADER START -->
    <app-header1></app-header1>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">
    
        <!-- INNER PAGE BANNER -->
        <app-banner1 [data]="banner"></app-banner1>
        <!-- INNER PAGE BANNER END -->
         
        <!-- ABOUT COMPANY -->
        <app-icon-box4 [data]="icon_section"></app-icon-box4>
        
        <!-- ABOUT COMPANY END -->
        
        <!-- OUR TEAM START -->
        <app-our-team1 [data]="our_team"></app-our-team1>
        <!-- OUR TEAM END -->
        
        <!-- WELCOME SECTION START -->
        <app-specialization4></app-specialization4> 
        <!-- WELCOME  SECTION END -->  
                    
        <!-- TESTIMONIALS SECTION START -->
        <app-testimonial1 [data]="testimonials"></app-testimonial1>
        <!-- TESTIMONIALS SECTION END --> 
                    
                    
        
    </div>
    <!-- CONTENT END -->
    
    <!-- FOOTER START -->
    <app-footer1></app-footer1>
    <!-- FOOTER END -->
    
    <!-- BUTTON TOP START -->
<app-scroll-to-top></app-scroll-to-top>
    
</div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>