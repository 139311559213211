import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { ModalService } from '../../modal.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-hpoojas',
  templateUrl: './hpoojas.component.html',
  styleUrls: ['./hpoojas.component.css']
})
export class HpoojasComponent implements OnInit {

  itemData:any;
  userid:any;
  orderlist:any;
  name:any;
  email:any;
    constructor(private router: Router,private route: ActivatedRoute, private myModal: ModalService, private apiService: ApiService) { 
      window.scrollTo(0, 0);
      this.userid = localStorage.getItem('userid');
      this.name = localStorage.getItem('name');
      this.email = localStorage.getItem('email');
    if(this.userid=='' || this.userid==undefined || this.userid==null || this.userid==0 || this.userid=='0') {
      this.router.navigate(['/login']);
    }

    this.getOrders();
    }

    ngOnInit(): void {
    }

    getOrders() {
      this.itemData = {
        "id":this.userid
      }
      this.apiService.post('m_hc', this.itemData).then((res: any) => {
        if (res.status === 200) {
          this.orderlist = res.data;
        } else {
          const message = res.message;
        }
      });
    }

    orderdetail(id) {
      localStorage.setItem('viewid',id);
      this.router.navigate(['/order-details']);
    }

}
