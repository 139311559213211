<div class="page-wraper"> 
      
      	<app-header2></app-header2>
      
      	<div class="container">
	      <!-- CONTENT START -->
	      <div class="page-content">        

            

   			<!-- ABOUT COMPANY -->
	        <app-icon-box1></app-icon-box1>   
	        <!-- ABOUT COMPANY END -->

	      </div>
	   	</div>

      	<app-footer1></app-footer1>

  <app-scroll-to-top></app-scroll-to-top>
      
   
  </div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>