import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { ModalService } from '../../modal.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {

	currentpassword:any;
	password:any;
	cpassword:any;
	userid:any;
	message:any;
	emsg:any;
	itemdata:any;

  	constructor(private myModal: ModalService, private apiService: ApiService,private router: Router,private route: ActivatedRoute) { 
  		this.userid = localStorage.getItem('userid');
  		if(this.userid=='' || this.userid==undefined || this.userid==null || this.userid==0) {
 			this.router.navigate(['/login']);
 		}
  	}

  	ngOnInit(): void {
  	}

  	change() {
  		this.emsg='';
  		if(this.currentpassword=='' || this.password=='') {
  			this.emsg="Please fill in password details";
  		} else {
  			if(this.password!=this.cpassword) {
  				this.emsg="Password and confirm password does not match";
  			} else {
	  			this.emsg='';
	  			this.itemdata = {
	  				"userid":this.userid,
	  				"currentpassword":this.currentpassword,
	  				"password":this.password
	  			}
	  			this.apiService.post('change_password', this.itemdata).then((res: any) => {
		    		if (res.status === 200) {
		    			this.message= res.msg;
		    		} else {
			          	this.message = res.msg;
			        }
			    });
			}
  		}
  	}

}
