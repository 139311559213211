import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Home1Component } from './home1/home1.component';
import { PoojaComponent } from './pages/pooja/pooja.component';
import { CartComponent } from './pages/cart/cart.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ConfirmComponent } from './pages/confirm/confirm.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PasswordComponent } from './pages/password/password.component';
import { BookingComponent } from './pages/booking/booking.component';
import { VieworderComponent } from './pages/vieworder/vieworder.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DevoteeComponent } from './pages/devotee/devotee.component';
import { UpoojasComponent } from './pages/upoojas/upoojas.component';
import { HpoojasComponent } from './pages/hpoojas/hpoojas.component';
import { GrievanceComponent } from './pages/grievance/grievance.component';

const routes: Routes = [
                {path: '', component: Home1Component},
                {path: 'index', component: Home1Component},
                {path: 'home', component: Home1Component},
                {path: 'pooja/:type/:id', component: PoojaComponent},
                {path: 'cart', component: CartComponent},
                {path: 'checkout', component: CheckoutComponent},
                {path: 'login', component: LoginComponent},
                {path: 'register', component: RegisterComponent},
                {path: 'confirm', component: ConfirmComponent},
                {path: 'dashboard', component: DashboardComponent},
                {path: 'change-password', component: PasswordComponent},
                {path: 'start-booking', component: BookingComponent},
                {path: 'order-details', component: VieworderComponent},
                {path: 'recover', component: RecoverComponent},
                {path: 'profile', component: ProfileComponent},
                {path: 'devotees', component: DevoteeComponent},
                {path: 'upcoming-poojas', component: UpoojasComponent},
                {path: 'pooja-history', component: HpoojasComponent},
                {path: 'grievance', component: GrievanceComponent},
              ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
