<div class="page-wraper"> 
      
      	<app-header2></app-header2>
      
      	<div class="container">
	      <!-- CONTENT START -->
	      <div class="page-content">        

            <div class="section-head text-center">
                <h2 class="text-uppercase  font-weight-900 m-b5 text-center">
                	Order Placed
                </h2>
                <div class="wt-separator-outer text-center">
                    <div class="wt-separator bg-primary"></div>
                </div>
            </div>

   			<div class="section-content clearfix">
		        <div class="col-md-12" *ngFor="let item of orderlist">
		            <div class="row  no-col-gap"> 
			            <div style="padding:20px 0;text-align:center;background:#fff;width:90%;margin:0 auto;max-width:600px;">
			            	<div class="ramForm">

			            		<h2 *ngIf="item.status==1">Thank you for placing the order</h2>
			            		<h2 *ngIf="item.status==0">Thank you for placing the order</h2>

			            		<p *ngIf="item.status==1">We have received your payment and order has been confirmed. You can get the further details about your pooja by contacting us. Your payment reference id is <span class="payid">{{item.transactionid}}</span></p>

			            		<p *ngIf="item.status==0">Something went wrong with the payment. We have received your order. Please contact Temple for further order processing and clarifications if payment was deducted from your account.</p>

			            		<p style="color: red;font-size: 12px;">* Order summary has been sent to your email. Please check Spam folder if not found.</p>

						        <a routerLink="/dashboard" class="site-button-secondry button-sm site-btn-effect" ><span>View all bookings</span></a>

						        <button class="site-button-secondry button-sm site-btn-effect" style="float: right;" printSectionId="printme" ngxPrint styleSheetFile="assets/css/print.css, assets/css/style.css, assets/css/bootstrap.min.css, assets/css/custom.css, assets/css/flaticon.min.css" printTitle="Asthika Samajam - Pooja Details" ><span>Print</span></button>

						    </div>
			            </div>                     		                                                                
		          	</div>
		        </div>
		    </div>

		    <div class="section-head text-center white-bg" id="printme" style="display:none;"> 

   				<div class="row">
   					<div class="col-md-12 col-sm-12 col-xs-12 text-left">
   						<div class="wt-post-title ">
                            

				          	<ng-container *ngFor="let items of orderdet">

				          		<img src="assets/images/logo.png" width="auto" style="height:60px;width:auto;float:right;position:absoluteright: 20px;top:20px" alt="" />

				          		<h5 class="text-uppercase  font-weight-900 m-b0 m-t20 text-left" *ngIf="items.orderno!=''" style="font-size: 14px;text-transform: capitalize;">
				                	Order No: {{items.orderno}} , Booked on {{items.bookdate | date}}
				                </h5>

				          		<h5 class="text-uppercase  font-weight-900 m-b0 m-t10 text-left" style="font-size: 14px;text-transform: capitalize;">
				                	Billing Address
				                </h5>
				                <ul class="ba">
				                	<il>{{items.name}}</il>
				                	<il>{{items.phone}}</il>
				                	<il>{{items.email}}</il>
				                	<il>{{items.address}}</il>
				                </ul>


				                <h5 class="text-uppercase  font-weight-900 m-b0 m-t10 text-left" *ngIf="items.order_remark!=''" style="font-size: 14px;text-transform: capitalize;">
				                	Order Remarks
				                </h5>
				                <p class="info p-t10" *ngIf="items.order_remark!=''" style="padding-top: 10px;color: #444444;margin-bottom:20px;">{{items.order_remark}}</p>

				                <h5 class="text-uppercase  font-weight-900 m-b0 m-t10 text-left" *ngIf="items.donation_remark!=''" style="font-size: 14px;text-transform: capitalize;">
				                	Donation Remarks
				                </h5>
				                <p class="info p-t10" *ngIf="items.donation_remark!=''" style="padding-top: 10px;color: #444444;margin-bottom:20px;">{{items.donation_remark}}</p>

				                <!--<h5 class="text-uppercase  font-weight-900 m-b0 m-t10 text-left" *ngIf="items.donation_remark!=''" style="font-size: 14px;text-transform: capitalize;">
				                	Dakshina Remarks
				                </h5>
				                <p class="info p-t10" *ngIf="items.dakshina_remark!=''" style="padding-top: 10px;color: #444444;margin-bottom:20px;">{{items.dakshina_remark}}</p>-->
				            </ng-container>

				            <h5 class="text-uppercase  font-weight-900 m-b10 m-t10 text-left" style="font-size: 14px;text-transform: capitalize;padding-left:0 !important;">Pooja List</h5>

				            <table class="table">
				                <thead>
				                    <tr>
				                    	<th>Devotee</th>
				                        <th>Deity</th>
				                        <th>Offering</th>
				                        <th>Date</th>
				                        <th>Qty</th>
				                        <th>Amount</th>
				                        <th>Total</th>
				                    </tr>
				                </thead>
				                <tbody>
					            	<tr *ngFor="let item of ordeitems">
						                <td width="25%">{{item.name}} <div class="subinfo">Gothram: {{item.gothram}}</div><div class="subinfo" style="color: #fd8a14;">Star: {{item.star}}</div></td>
				                        <td width="15%">{{item.deity}}</td>
				                       	<td width="15%">{{item.pooja}}</td>
				                        <td width="15%">{{item.bookdate | date}}</td>
				                        <td width="10%">{{item.qty}}</td>
				                        <td width="10%">{{item.amount}}</td>
				                        <td width="10%">{{item.total}}</td>
					                </tr>
					            </tbody>                  		 
					        </table>                                                 

                        </div>
   					</div>
   					<div class="col-md-6 col-xs-6 col-sm-6 text-left"></div>
   					<div class="col-md-6 col-xs-6 col-sm-6 text-left">
   						<div class="widget bg-white  widget_services">
   							<ng-container *ngFor="let items of orderdet">
	                            <ul style="padding-left:30px;">
	                                <li><a href="javascript:void(0);">Subtotal</a><span class="badge">₹ {{items.amount}}</span></li>
	                                <!--<li><a href="javascript:void(0);">Donation</a><span class="badge">₹ {{items.donation}}</span></li>
	                                <li><a href="javascript:void(0);">Dakshina</a><span class="badge">₹ {{items.dakshina}}</span></li>
	                                <li><a href="javascript:void(0);">Processing Charges</a><span class="badge">₹ {{items.processingcharge}}</span></li>
	                                <li><a href="javascript:void(0);">GST</a><span class="badge">₹ {{items.gst}}</span></li>-->
	                                <li><a href="javascript:void(0);">Grand Total</a><span class="badge">₹ {{+items.total - +items.donation}} </span></li>
	                            </ul>
	                            <div *ngIf="items.status==0" style="text-align: right;margin: 25px 15px;color: #0fa1a9;text-transform: uppercase;"><strong>Payment Failed</strong></div>
	                            <div *ngIf="items.status==1" style="text-align: right;margin: 25px 15px;color: #0fa1a9;text-transform: uppercase;"><strong>Payment Done</strong></div>
	                        </ng-container>
                        </div>
   					</div>
   				</div>

   				<div class="row">
   					<div class="col-md-12">
   						<p class="notice" style="text-align:center;margin-top:40px;font-size:11px;">* Please bring this receipt as a soft copy or a print while you come to get Prasadam</p>
   					</div>
   				</div>

            </div>

	      </div>
	   	</div>

      	<app-footer1></app-footer1>

  <app-scroll-to-top></app-scroll-to-top>
      
   
  </div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>