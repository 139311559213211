<div [ngClass]="hideme == '1' ? 'ramModal hideme' : 'ramModal'">

    <div class="titleBar">Please fill out these details to add Offering</div>
    <div class="ramForm">

        <div class="form-group" style="position:relative;">
            <input name="username" type="text" required="" class="form-control" placeholder="Devotee Name" name="devoteename" (input)="devoteename=$event.target.value" value="{{devoteename}}" (keyup)="getDevotees();" autocomplete="off">
            <div class="suggest" *ngIf="devotees">
                <div class="devname" *ngFor="let item of devotees" (click)="addName(item.name,item.star,item.gothram)">{{item.name}} 
                    <a href="javascript:void(0);">Click to Add</a>
                </div>
            </div>
        </div>

        <div class="form-group">
            <select class="form-control" name="star" (change)="star=$event.target.value" value="{{star}}">
                <option value="" [selected]="star=='' || star==undefined">-- Select Star --</option>
                <option *ngFor="let item of stars" value="{{item.id}}#{{item.name}}"  [selected]="star==item.id">{{item.name}}</option>
            </select>
        </div>

        <div class="form-group">
            <select class="form-control" name="gothram" (change)="gothram=$event.target.value" value="{{gothram}}" ng-model="gothram">
                <option value="" [selected]="gothram=='' || gothram==undefined">-- Select Gothram --</option>
                <option *ngFor="let item of gothras" value="{{item.id}}#{{item.name}}" [selected]="gothram==item.id">{{item.name}}</option>
            </select>
        </div>

        <div class="form-group">
            <label for="qty">Quantity</label>
            <input type="number" name="qty" (input)="qty=$event.target.value" required="" class="form-control inline-control" placeholder="Quantity" value="{{qty}}" min="1">
        </div>

        <div class="form-group">
            <label for="qty">Pooja Date</label>
            <input type="date" name="bookdate" (input)="bookdate=$event.target.value" required="" class="form-control inline-control" placeholder="Select Date" value="{{bookdate}}"  min="{{date | date:'yyyy-MM-dd'}}">
        </div>

        <p class="error">{{emsg}}</p>

        <div class="text-center">
            <a rhref="javascript:void(0);" class="site-button-secondry button-sm site-btn-effect m-r10" (click)="addcart()"><span>Add to cart</span></a>
            <a rhref="javascript:void(0);" class="site-button button-sm site-btn-effect " (click)="closeOptions()"><span>Cancel</span></a>
        </div>
    </div>

</div>

<div [ngClass]="hideme == '1' ? 'overlay hideme' : 'overlay'">
  <div class="inner">
    
  </div>
</div>