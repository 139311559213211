import { Component, OnInit } from '@angular/core';
declare  var jQuery:  any;
import { ModalService } from '../../../modal.service';
import { ApiService } from '../../../api.service';

@Component({
  selector: 'app-header2',
  templateUrl: './header2.component.html',
  styleUrls: ['./header2.component.css']
})
export class Header2Component implements OnInit {

  cartitems:any = 0;
  userid:any = 0;
  cartid:any;
  constructor(private myModal: ModalService, private apiService: ApiService) { 
    this.getCartCount();
    this.myModal.cartItems$.subscribe((data) => {
        this.cartitems = data;
    });

    this.userid = localStorage.getItem('userid');

    this.myModal.myUserid$.subscribe((data) => {
        this.userid = data;
    });

    console.log("H"+this.userid);
  }
  
  getCartCount() {
    this.cartid = localStorage.getItem('cartid');
    this.apiService.post('getCartCount', { cart: this.cartid }).then((res: any) => {
      if (res.status === 200) {
        this.cartitems = res.data;
      } else {
        const message = res.message;
      }
    });
  }

  ngOnInit(): void {
	  (function ($) {
      setTimeout(function(){
          jQuery(window).on('scroll', function () {
                    var scroll = jQuery(window).scrollTop();
                    
                    if(scroll >= 100) {
                      jQuery(".sticky-header").addClass("color-fill");
                      jQuery(".sticky-header").addClass("is-fixed");
                    } else {
                        jQuery(".sticky-header").removeClass("color-fill");
                        jQuery(".sticky-header").removeClass("is-fixed");
                    }
                  });  
          }, 500);
    })(jQuery);
  }

}
