import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { ModalService } from '../../modal.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  name:any;
  email:any;
  phone:any;
  address:any;
  city:any;
  state:any;
  country:any;
  userid:any;
  message:any;
  emsg:any;
  itemdata:any;
  data:any;

    constructor(private myModal: ModalService, private apiService: ApiService,private router: Router,private route: ActivatedRoute) { 
      this.userid = localStorage.getItem('userid');
      if(this.userid=='' || this.userid==undefined || this.userid==null || this.userid==0) {
        this.router.navigate(['/login']);
      }
      this.getAddress();
    }

    ngOnInit(): void {
    }

    getAddress() {
      this.apiService.post('r_a', { id: this.userid }).then((res: any) => {
            this.data = res.data;
            this.name=res.data[0].name;
            this.email=res.data[0].email;
            this.phone=res.data[0].phone;
            this.address=res.data[0].address;
            this.city=res.data[0].city;
            this.state=res.data[0].state;
            this.country=res.data[0].country;
      });
    }

    change() {
      this.emsg='';
      if(this.name=='' || this.phone=='' || this.address=='') {
        this.emsg="Please fill in your name, phone no and address";
      } else {
          this.emsg='';
          this.itemdata = {
            "id":this.userid,
            "name":this.name,
            "phone":this.phone,
            "address":this.address,
            "city":this.city,
            "state":this.state,
            "country":this.country
          }
          this.apiService.post('r_ua', this.itemdata).then((res: any) => {
              if (res.status === 200) {
                this.message= res.msg;                
                localStorage.setItem('name',this.name);
              } else {
                this.message = res.msg;
              }
          });
      }
    }

}
