<div class="page-wraper"> 
    
    <!-- HEADER START -->
    <app-header1></app-header1>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">  

        <!-- SLIDER START -->
        <app-slider4></app-slider4>
        <!-- SLIDER END -->
        
        <!-- ABOUT COMPANY -->
        <app-icon-box3 [data]="icon_section"></app-icon-box3>
        <!-- ABOUT COMPANY END -->            
                                
        <!-- WELCOME SECTION START -->
        <app-specialization1 [data]="our_specialization_section"></app-specialization1>
        <!-- WELCOME  SECTION END -->              
        
        <!-- LATEST PRJECTS SLIDER START -->
        <app-our-work1 [data]="our_work_section"></app-our-work1>
        <!-- LATEST PRJECTS SLIDER END -->
        
        <!-- OUR VIDEO START -->
        <app-call-to-action1></app-call-to-action1>
        <!-- OUR VIDEO END -->              

        <!-- OUR TEAM START -->
        <app-our-team1 [data]="our_team"></app-our-team1>
        <!-- OUR TEAM END -->
        
        <!-- CLIENT LOGO SECTION START -->
       <app-clients [data]="clients"></app-clients>
        <!-- CLIENT LOGO  SECTION End -->
         
        <!-- TESTIMONIALS SECTION START -->
        <app-testimonial1 [data]="testimonials"></app-testimonial1>
        <!-- TESTIMONIALS SECTION END --> 
                                             
        <!-- OUR BLOG START -->
        <app-blog1 [data]="blogs"></app-blog1>
        <!-- OUR BLOG END -->   
    </div>
    
    <!-- CONTENT END -->
    
    <!-- FOOTER START -->
    <app-footer1></app-footer1>
    <!-- FOOTER END -->

    <!-- BUTTON TOP START -->
<app-scroll-to-top></app-scroll-to-top>
    
 
</div>

<!-- LOADING AREA START ===== -->
<app-loading></app-loading>