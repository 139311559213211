import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../api.service';

@Component({
  selector: 'app-icon-box1',
  templateUrl: './icon-box1.component.html',
  styleUrls: ['./icon-box1.component.css']
})
export class IconBox1Component implements OnInit {

	poojaCategory: any = [];
	dietys:any;
  	constructor(private apiService: ApiService) { 
  		this.getCategories();
  		this.getDeity();
  	}

  	ngOnInit(): void {
 	}

  	getCategories() {
	    this.apiService.post('listpooja_type', {}).then((res: any) => {
	      if (res.status === 200) {
	        this.poojaCategory = res.data;
	        console.log(this.poojaCategory, 'Category-List');
	      } else {
	        const message = res.message;
	      }
	    });
	}

  	getDeity() {
	    this.apiService.post('listdeity', {}).then((res: any) => {
	      if (res.status === 200) {
	        this.dietys = res.data;
	      } else {
	        const message = res.message;
	      }
	    });
	}

}
